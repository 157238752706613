import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from "@angular/core"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { DOCUMENT } from "@angular/common"

interface Link {
  href: string
  urlAndArgsArray: string[]
  url: string
  argsAndAnchorArray: string[]
  args: string
  anchor: string
  argsArray: string[]
  argsKeyValueArray: string[][]
  addUrlArgs: boolean
}

@Component({
  selector: "ava-multiblock-custom-element",
  template: `
    <ava-multiblock></ava-multiblock>
  `,
})
export class MultiblockCustomElementComponent implements OnInit {
  renderer: Renderer2

  constructor(
    private domWindow: Window,
    @Inject(DOCUMENT)
    private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  ngOnInit(): void {
    this.appendUrlArgsToLink()
    this.dynamicDiscountsLinkForCO()
    this.liveChatForSJ()
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  get urlArgs(): string[] {
    return this.window.location.search.replace("?", "")?.split("&")
  }

  private dynamicDiscountsLinkForCO(): void {
    const anonymousLinkURL = "https://www.aaa.com/partnerRedirect"
    const anonymousLinkArg0 = "PID=1255"
    const memberLinkURL = "https://www.aaa.com/partnerRedirectWithLogin"

    if (this.window.metaData?.clubId === "006" && parseInt(this.window.metaData.user?.id) > 0) {
      const linkElements: HTMLElement[] = [].slice.call(document.querySelectorAll("a"))
      for (const linkElement of linkElements) {
        const link: Link = this.parseLinkElement(linkElement)
        if (link.url === anonymousLinkURL && link.argsArray[0] === anonymousLinkArg0) {
          const memberLinkHref = link.href.replace(anonymousLinkURL, memberLinkURL)
          linkElement.setAttribute("href", memberLinkHref)
        }
      }
    }
  }

  private liveChatForSJ(): void {
    if (this.window.metaData?.clubId === "071") {
      const scriptSrc = "https://aaasj-stage.quiq-api.com/app/webchat/index.js"
      this.addJsToHead(scriptSrc).onload = () => {
        window["Quiq"]({ contactPoint: "membership" })
      }
    }
  }

  addJsToHead(src: string): HTMLScriptElement {
    const script = this.renderer.createElement("script")
    script.type = "text/javascript"
    script.src = src
    const head = this.document.querySelector("head")
    this.renderer.appendChild(head, script)
    return script
  }

  private appendUrlArgsToLink(): void {
    const linkElements: HTMLElement[] = [].slice.call(document.querySelectorAll("a"))
    for (const linkElement of linkElements) {
      const link: Link = this.parseLinkElement(linkElement)
      for (const arg of link.argsKeyValueArray) {
        if (arg[0] === "URLARGS") {
          link.addUrlArgs = true
          link.argsArray.splice(link.argsKeyValueArray.indexOf(arg), 1)
        }
      }

      if (link.addUrlArgs) {
        for (const arg of this.urlArgs) {
          link.argsArray.push(arg)
        }
        const args = link.argsArray.length ? "?" + link.argsArray.join("&") : ""
        const anchor = link.anchor ? "#" + link.anchor : ""
        linkElement.setAttribute("href", link.url + args + anchor)
      }
    }
  }

  private parseLinkElement(linkElement: HTMLElement): Link {
    const link: Link = {
      href: "",
      urlAndArgsArray: [],
      url: "",
      argsAndAnchorArray: [],
      args: "",
      anchor: "",
      argsArray: [],
      argsKeyValueArray: [],
      addUrlArgs: false,
    }
    link.href = linkElement.getAttribute("href")
    link.urlAndArgsArray = link.href?.split("?")
    if (link.urlAndArgsArray?.length) {
      link.url = link.urlAndArgsArray[0]
      if (link.urlAndArgsArray[1]) {
        link.argsAndAnchorArray = link.urlAndArgsArray[1].split("#")
        if (link.argsAndAnchorArray?.length) {
          link.args = link.argsAndAnchorArray[0]
          if (link.argsAndAnchorArray[1]) {
            link.anchor = link.argsAndAnchorArray[1]
          }
          link.argsArray = link.args.split("&")
          for (const arg of link.argsArray) {
            const keyValueArray = arg.split("=")
            link.argsKeyValueArray.push([keyValueArray[0], keyValueArray[1]])
          }
        }
      }
    }
    return link
  }
}
