<div style='padding: 20px; background-color: rgb(37, 37, 37); color: white'>

  <p>join-renew</p>
  <br />

  <!-- <p>
    emulator: {{ emu }}
  </p>

  <p>
    sessionId: {{ sessionId }}
  </p>

  <br />

  <button style="color: black" (click)="startOperation()">[1] start operation (join) 🤖</button>
  <br />
  <button style="color: black" (click)="memberLookup()">[x] member looup</button>
  <br />
  <button (click)="paymentSubmitted()">[2] payment submitted 😎</button>

  <br />
  <button style="color: black" (click)="testEvent()">send test event</button>
  <br />
  <br /> -->

  <button style="position: fixed; bottom: 100px; right: 100px; color: black" (click)="startTest()">start test</button> <br />
  <button style="position: fixed; bottom: 50px; right: 100px; color: black" (click)="cronTick()">cron tick</button> <br />

  [sessionData]
  <pre [innerHTML]="sessionData | prettyjson: [true, 1]"></pre>

  [testingDoc]
  <pre [innerHTML]="testingDoc | prettyjson: [true, 1]"></pre>

  [activeRunId]
  <pre [innerHTML]="activeTestingData | prettyjson: [true, 1, true]"></pre>


  <!-- <ava-cybersource></ava-cybersource> -->

</div>
