import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { Message, SimpleLoggerGroupDoc, SimpleLoggerService } from "../simple-logger.service"
import { map, tap } from "rxjs/operators"
import { HttpClient } from "@angular/common/http"
import { DocumentChangeAction } from "@angular/fire/firestore"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import firebase from "firebase"

@Component({
  selector: "ava-member-number",
  templateUrl: "./member-number.component.html"
})
export class MemberNumberComponent implements OnInit, OnDestroy {
  docs$: Observable<SimpleLoggerGroupDoc[]>
  docsSubscription: Subscription
  messages: Message[]
  messageCounts: {
    id: string
    count: number
  }[]
  messagesDocs: SimpleLoggerGroupDoc[]
  // messageRecords: {
  //   id: string
  //   records: string[]
  // }[]
  showRecords: boolean
  getRecordsTimestamp = 0
  removeDuplicatesTriggerTimestamp: number
  window: MetaWindow

  constructor(
    private simpleLoggerService: SimpleLoggerService,
    private http: HttpClient,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    // console.log('MemberNumberComponent')
    this.docs$ = this.simpleLoggerService.simpleLoggerRef
      .collection("member-number", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        query = query
          .limit(100)
        return query
      })
      .snapshotChanges()
      .pipe(
        map((messagesDocs: DocumentChangeAction<SimpleLoggerGroupDoc>[]) => {
          return messagesDocs.map(result => {
            if (result) {
              const data = result.payload.doc.data()
              const id = result.payload.doc.id
              return { id, ...data }
            }
          })
        })
      )

    this.docsSubscription = this.docs$
      .subscribe(messagesDocs => {
        this.garbageCollection(messagesDocs)
        this.messagesDocs = messagesDocs
        /**
         * Combine the matching messages from multiple docs in the collection
         * into a single map of message objects.
         */
        // return
        this.messages = {} as Message[]
        messagesDocs.forEach((messagesDoc) => {
          if (messagesDoc.messages) {
            Object.keys(messagesDoc.messages).forEach(messageId => {
              this.messages[messageId] = this.messages[messageId] || {} as Message
              Object.keys(messagesDoc.messages[messageId]).forEach(userId => {
                if (messagesDoc.messages[messageId][userId]) {
                  this.messages[messageId][userId] = messagesDoc.messages[messageId][userId]
                }
              })
            })
          }
        })

        const messageCounts = []
        Object.keys(this.messages).forEach(messageId => {
          /**
           * Create an array of messageIds with a count of the records in each message.
           */
          messageCounts.push({
            id: messageId,
            count: Object.keys(this.messages[messageId]).length
          })
        })
        this.messageCounts = messageCounts.sort((a, b) => b.id - a.id)
        this.removeDuplicates().catch(() => {
          //
        })
      })
  }

  ngOnDestroy(): void {
    this.docsSubscription?.unsubscribe()
  }

  getRecords(): void {
    /**
     * Trigger the memberNumber endpoint in V3 which then populates the memberNumber logs with data.
     * Throttle this action for a period of time, in milliseconds.
     */
    const delay = 10000
    if (this.getRecordsTimestamp + delay < new Date().getTime()) {
      const url = this.window.location.origin + "/data/users/all"
      this.http.get(url).subscribe()
      this.getRecordsTimestamp = new Date().getTime()
    } else {
      /**
       * notify user that their action has been throttled.
       */
    }
  }

  async removeDuplicates(): Promise<void> {
    const removeDuplicatesTriggerTimestamp = this.removeDuplicatesTriggerTimestamp = new Date().getTime()
    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms))
    await delay(10000)
    if (removeDuplicatesTriggerTimestamp !== this.removeDuplicatesTriggerTimestamp) return
    /**
     * Loop through each message, comparing with all following messages. j = i + N where N > 0
     */
    const messageIds = Object.keys(this.messages).sort((a: never, b: never) => a - b)
    for (let i = 0; i < messageIds.length; i++) {
      const arr1 = Object.keys(this.messages[messageIds[i]])
      for (let j = i + 1; j < messageIds.length; j++) {
        const arr2 = Object.keys(this.messages[messageIds[j]])
        if (removeDuplicatesTriggerTimestamp !== this.removeDuplicatesTriggerTimestamp) return
        /**
         * Records from arr1 that are missing from arr2.
         * These would be user accounts that have been deleted.
         */
        // const difference = this.difference(arr1, arr2)
        /**
         * Records from arr1 that exist and are true from arr2.
         * These would be user accounts with missing member numbers that previously had a member number (recorded in arr1).
         */
        const intersection = this.intersection(arr1, arr2)
        // const intersection = this.intersection(arr1, arr2.filter(key => this.messages[messageIds[j]][key]))
        /**
         * Delete them from the db, leaving only the arr1 records that are either missing or don't have a member number in arr2.
         */
        // this.deleteRecords(messageIds[i], difference)
        this.deleteRecords(messageIds[i], intersection)
      }
    }
  }

  /**
   * Three comparison functions: intersection(), difference(), and symmetricDifference()
   * Inspired by the "Plain Javascript" post in this stackoverflow
   * https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
   * a1 and a2 are arrays
   *
   * records that are in both a1 and a2
   */
  intersection(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2)
    return a1.filter(x => a2Set.has(x))
  }

  /**
   * records that are in a1 but not in a2
   */
  difference(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2)
    return a1.filter(x => !a2Set.has(x))
  }

  /**
   * records that are not in both a1 and a2
   * aka
   * records that are in a1 but not in a2 AND ALSO records that are in a2 but not in a1
   */

  symmetricDifference(a1: string[], a2: string[]): string[] {
    return this.difference(a1, a2).concat(this.difference(a2, a1))
  }

  deleteDocs(): void {
    this.messagesDocs?.forEach(messagesDoc => {
      const docRef = this.simpleLoggerService.memberNumberCollectionRef.doc(messagesDoc.id)
      docRef.delete()
        .catch(error => {
          console.log(error)
        })
    })
  }

  deleteRecords(messageId: string, records: string[]): void {
    this.messagesDocs.forEach(messagesDoc => {
      if (messagesDoc.messages) {
        if (Object.keys(messagesDoc.messages).find(messagesKey => messagesKey === messageId)) {
          const docRef = this.simpleLoggerService.memberNumberCollectionRef.doc(messagesDoc.id)
          /**
           * Delete the message from each doc that matches the messageId
           */
          records.forEach(record => {
            if (messagesDoc.messages[messageId]) {
              delete messagesDoc.messages[messageId][record]
            }
          })
          /**
           * Write the doc with deleted message record(s).
           */
          docRef.set(messagesDoc, { merge: false })
            .catch(error => {
              console.log(error)
            })
        }
      }
    })
  }

  garbageCollection(messagesDocs: SimpleLoggerGroupDoc[]): void {
    messagesDocs.forEach(messagesDoc => {
      const docRef = this.simpleLoggerService.memberNumberCollectionRef.doc(messagesDoc.id)
      if (!messagesDoc.messages) {
        docRef.delete().catch(() => {
          //
        })
      } else {
        Object.keys(messagesDoc.messages).forEach(messageId => {
          /**
           * Age of each message, in days.
           */
          /*
                    const days = Math.floor((new Date().getTime() - parseInt(messageId, 10)) / 86400 / 1000 )
                    if (!Object.keys(messagesDoc.messages[messageId]).length || days > 7) {
                      delete messagesDoc.messages[messageId]
                    }
          */
          Object.keys(messagesDoc.messages[messageId]).forEach(userId => {
            if (!messagesDoc.messages[messageId][userId]) {
              delete messagesDoc.messages[messageId][userId]
            }
          })
          if (!Object.keys(messagesDoc.messages[messageId]).length) {
            delete messagesDoc.messages[messageId]
          }
        })
        if (!Object.keys(messagesDoc.messages).length && !messagesDoc.writeEnabled) {
          docRef.delete().catch(() => {
            //
          })
        }
      }
    })
  }

  /*
    deleteMessages(messageId) {
      this.messagesDocs.forEach(messagesDoc => {
        if (messagesDoc.messages) {
          if (Object.keys(messagesDoc.messages).find(messagesKey => messagesKey === messageId)) {
            const docRef = this.simpleLoggerService.memberNumberCollectionRef.doc(messagesDoc.id)
            /!**
             * Delete the message from each doc that matches the messageId
             *!/
            delete messagesDoc.messages[messageId]
            /!**
             * Delete the doc if messages.length === 0 and writeEnabled === false.
             * Write the doc with deleted messageId message if messages.length !== 0 || writeEnable === true
             *!/
            if (Object.keys(messagesDoc.messages).length || messagesDoc.writeEnabled) {
              docRef.set(messagesDoc, { merge: false }).catch(() => {
              })
            } else {
              docRef.delete().catch(() => {
              })
            }
          }
        }
      })
    }
  */
}
