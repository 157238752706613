<div [style]="'text-align: center; margin: 10px; font-size: 30px; font-weight: 400'">
  How can we help you?
</div>
<div [style]="'display: grid; justify-content: center'">
  <nz-form-label
    nzFor="option"
    [style]="'visibility: hidden'"
  >
    Select Option
  </nz-form-label>
  <nz-select
    nzId="option"
    [style]="'min-width: 250px'"
    [(ngModel)]="formValue.selectedRegionId"
  >
    <nz-option
      *ngFor="let region of regions"
      [nzValue]="region.id"
      [nzLabel]="region.text"
    ></nz-option>
  </nz-select>
</div>
<div [style]="'display: flex; justify-content: center; margin: 10px'">
  <button
    nz-button [nzType]="'primary'"
    (click)="sms.sendEvent('NEXT'); $event.preventDefault()"
  >
    Next
  </button>
</div>
