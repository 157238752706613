import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import {
  Javascript,
  JavascriptOptions,
  JavascriptService
} from "@aaa-web/app/modules/multiblock/fields/javascript/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"

export interface Block extends BlockBase {
  fields: {
    javascripts: Javascript[]
  }
}

export interface Options {
  block: BlockOptions
  javascript: JavascriptOptions
  published: PublishedOptions
}

@Injectable({
  providedIn: "root"
})
export class LogiformService {
  blockType: BlockOption = "logiform"

  constructor(
    private dataService: DataService,
    private javascriptService: JavascriptService,
    private statusService: StatusService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      javascript: this.javascriptOptions,
      published: this.publishedOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Logiforms",
        singular: "Logiform"
      },
    }
  }

  private get javascriptOptions(): JavascriptOptions {
    const options: JavascriptOptions = {
      newForm: this.newJavascriptForm,
      newItem: this.newJavascript,
      options: [],
      label: {
        placeholder: "javascript code",
        plural: "Javascripts",
        singular: "Javascript"
      },
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        background: {
          color: false,
          opacity: false
        },
        value: true
      })
    }
    return options
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect"]
    }
  }

  get newJavascript(): Javascript {
    return this.javascriptService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        javascripts: [this.newJavascript]
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newJavascriptForm = (javascript: Javascript): AbstractControl => {
    return new FormGroup({
      value: new FormControl(javascript.value),
    })
  }

}
