import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { environment } from "./environments/environment"
import { AppModule } from "./app/app.module"
import { CustomElementModule } from "@aaa-web/app/custom-element.module"

if (environment.production) {
  enableProdMode()
}

/*
try {
  const head = document.head;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.setAttribute('async', '');
  script.setAttribute('defer', '');
  // script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBPL0PQHj155YbOJfWSQb_NOXLRJ8lZis8');
  script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCyuxSHgL1KJ5eLfBzJJ6ledX2fift-_y8');
  head.append(script);
} catch (e) {
  console.log(e);
}
*/

// console.log(environment)

if (environment.ngServe) {
  window["metaData"] = {
    appMode: true,
    clubId: "071",
    origin: "https://test.southjersey.aaa.com",
    user: {
      firstName: "Lowell",
      lastName: "Evergreen",
      email: "lowell.evergreen@avagate.com",
      memberNumber: "4290061907642014",
      id: "1",
      status: "active",
      token: ""
    },
    webEnv: "local"
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))

} else if (/[.a-z]*millisite/.test(window.location.hostname)) {
  const clubName = window.location.hostname.split(".")[1]
  const clubIdMap = {
    colorado: "006",
    hoosier: "023",
    minneapolis: "057",
    northway: "104",
    southjersey: "071",
    southpa: "237",
  }

  window["metaData"] = {
    appMode: true,
    clubId: clubIdMap[clubName],
    origin: window.location.origin.replace("millisite", "aaa"),
    user: {
      firstName: "Lowell",
      lastName: "Evergreen",
      email: "lowell.evergreen@avagate.com",
      memberNumber: "4290061907642014",
      id: "1",
      status: "notFound",
      token: ""
    },
    webEnv: "appTest"
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))

} else {
  platformBrowserDynamic()
    .bootstrapModule(CustomElementModule)
    .catch((err) => console.error(err))
}
