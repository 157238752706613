import { Injectable } from "@angular/core"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { BlockBase, BlockOption } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BlockStateBase as BlockState } from "@aaa-web/app/modules/multiblock/services/state.service"

export interface Block extends BlockBase {
  breadcrumbTitle: string
  childBlockIds: string[]
  containerType: Option
  pageType: string
  pageTitle: string
}

interface Type {
  file: string
  label: {
    plural: string,
    singular: string
  }
}

export type Option = "page"
export type Types = Record<Option, Type>

@Injectable({
  providedIn: "root"
})
export class PageService {
  blockType: BlockOption = "container"
  containerType: Option = "page"

  constructor(
    private dataService: DataService,
    private formService: FormService,
  ) {
  }

  get types(): Types {
    return {
      page: {
        file: "",
        label: {
          plural: "Pages",
          singular: "Page"
        },
      }
    }
  }

  get blockTypes(): Types {
    return {
      page: {
        file: "",
        label: {
          plural: "pages",
          singular: "page"
        },
      }
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      childBlockIds: [],
      containerType: this.containerType,
      breadcrumbTitle: "",
      pageTitle: "",
      pageType: "",
      pathName: this.dataService.pathName,
      status: {
        created: Timestamp.now(),
        deleting: false,
        personalize: null,
        personalized: false,
        preSaved: true,
        protected: false,
        revised: Timestamp.now(),
        session: "",
        unpublished: false,
      }
    }
  }

  newForm = (block: Block): BlockState => {
    return { form: this.formService.newForm(block, this.newBlock) }
  }

}
