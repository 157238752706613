import { Component, Input } from "@angular/core"

@Component({
  selector: "ava-v3-batch-tooltip-dialog",
  templateUrl: "./v3-batch-tooltip-dialog.component.html",
  styleUrls: ["./v3-batch-tooltip-dialog.component.scss"]
})
export class V3BatchTooltipDialogComponent {
  @Input() data: any
  @Input() index: number

}
