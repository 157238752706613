import { ElementRef, Injectable, TemplateRef } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { DataService } from "./data.service"
import { Multiblock } from "./block.service"
import { Observable, BehaviorSubject } from "rxjs"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { Block as PageBlock } from "@aaa-web/app/modules/multiblock/containers/page/service"
import { Personalize, PersonalizeService } from "@aaa-web/app/modules/multiblock/options/personalize/service"

export interface BlockStateBase {
  deleting?: boolean
  draggable?: boolean
  draggedOverBlockId?: string
  editingBlock?: Multiblock
  element?: { [key: string]: { width: number } }
  elementRef?: ElementRef
  form?: AbstractControl
  modalActive?: boolean
  modalRef?: TemplateRef<never>
  modified?: boolean
  newItem?: Multiblock
  passwordAuth?: boolean
  personalize?: Personalize
  personalized?: boolean
  preSaved?: boolean
  protected?: boolean
  selected?: boolean
  selectedField?: string
  showBlock?: () => boolean
  unpublished?: boolean
  width?: number
}

export type NewForm = (block: Multiblock) => BlockStateBase

export interface State {
  [key: string]: BlockStateBase | Record<string, never>
}

export interface PreviewSizes {
  mobile: number
  tablet: number
  desktop: number
}

@Injectable({
  providedIn: "root"
})
export class StateService {
  state: State = {}
  private stateBehaviorSubject: BehaviorSubject<State>
  state$: Observable<State>

  private adminModeBehaviorSubject: BehaviorSubject<boolean>
  adminMode$: Observable<boolean>
  adminMode: boolean
  private previewModeBehaviorSubject: BehaviorSubject<boolean>
  previewMode$: Observable<boolean>
  previewMode: boolean

  private previewSizeBehaviorSubject: BehaviorSubject<number>
  previewSize$: Observable<number>
  previewSize: number

  nzBordered = true
  nzShowArrow = true

  constructor(
    private dataService: DataService,
    private personalizeService: PersonalizeService,
    private roleService: RoleService,
  ) {
    this.stateBehaviorSubject = new BehaviorSubject<State>({})
    this.state$ = this.stateBehaviorSubject.asObservable()

    this.state$.subscribe(state => {
      this.state = state
    })

    this.adminModeBehaviorSubject = new BehaviorSubject<boolean>(false)
    this.adminMode$ = this.adminModeBehaviorSubject.asObservable()
    this.adminMode$.subscribe(adminMode => {
      this.adminMode = adminMode
      this.stateBehaviorSubject.next(this.state)
    })

    this.previewModeBehaviorSubject = new BehaviorSubject<boolean>(false)
    this.previewMode$ = this.previewModeBehaviorSubject.asObservable()
    this.previewMode$.subscribe(previewMode => this.previewMode = previewMode)

    /**
     * kick the user out of adminMode when all of these roles are removed from their credentials.
     */
    this.roleService.roles$
      .subscribe(roles => {
        if (!Object.keys(roles).find(roleKey => roleKey === "administrator" || roleKey === "user-admin")) {
          this.adminModeBehaviorSubject.next(false)
        }
      })

    /**
     * set up previewMode observable
     */
    this.previewSizeBehaviorSubject = new BehaviorSubject<number>(0)
    this.previewSize$ = this.previewSizeBehaviorSubject.asObservable()
    this.previewSize$
      .subscribe(size => this.previewSize = size)
  }

  get session(): string {
    return this.dataService.session
  }

  setPreviewSize(size: number): void {
    this.previewSizeBehaviorSubject.next(size)
  }

  get previewSizes(): PreviewSizes {
    return {
      mobile: 320,
      tablet: 700,
      desktop: 1200
    }
  }

  initializeState(blocks: Multiblock[]): void {
    blocks?.forEach(block => {
      this.newState(block.id)
      if (block.blockType === "container") {
        (block as PageBlock).childBlockIds.forEach(childBlockId => this.newState(childBlockId))
      }
    })
  }

  /**
   * Add a Block to the State Object, if it does not already exist.
   */
  newState(blockId: string): void {
    this.state[blockId] = this.state[blockId] || {}
    this.stateBehaviorSubject.next(this.state)
  }

  /**
   * Sets a state[blockId][key:value]
   */
  updateState(blockId: string, state: BlockStateBase): void {
    Object.keys(state).forEach(key => {
      this.state[blockId] = this.state[blockId] || {}
      this.state[blockId][key] = state[key]
    })
    this.stateBehaviorSubject.next(this.state)
  }

  resetState(blockId: string, stateKey?: string): void {
    /**
     * Remove a block from the State object.
     */
    if (!stateKey && this.state[blockId]) {
      delete this.state[blockId]
    }
    /**
     * Remove a key from a State Block object.
     */
    if (stateKey && this.state[blockId][stateKey]) {
      delete this.state[blockId][stateKey]
    }
    this.stateBehaviorSubject.next(this.state)
  }

  /**
   * Acts on all State Block objects.
   * Sets or Removes a key from all State Block Objects.
   */
  resetStateKey(stateKey: string, stateKeyValue?: number | string | boolean): void {
    /**
     * Set a key to a value for all State Block objects.
     */
    if (stateKey && stateKeyValue !== undefined) {
      Object.keys(this.state).forEach(stateBlockId => this.updateState(stateBlockId, { [stateKey]: stateKeyValue }))
    }
    /**
     * Remove a key from all State Block objects.
     */
    if (stateKey && stateKeyValue === undefined) {
      Object.keys(this.state).forEach(stateBlockId => this.resetState(stateBlockId, stateKey))
    }
  }

  compareAllBlockStates(): void {
    for (const blockId in this.dataService.blocks) {
      this.compareBlockState(blockId)
    }
  }

  compareBlockState(blockId: string): void {
    const block = this.dataService.blocks[blockId]
    if (block) {
      // console.log(block.status.published)
      this.state[block.id] = this.state[block.id] || {}
      if (this.state[block.id].form) {
        /**
         * TODO: add a delay to batch these, maybe 1500ms.
         */
        const blockCopy = JSON.parse(JSON.stringify(block))
        /**
         * synchronize "id" and "status.session" for the comparison
         * we expect them to be different
         * we don't want to flag if either of them are different
         */
        delete blockCopy.id
        delete this.state[block.id].form.value.id
        blockCopy.status.session = this.state[block.id].form.value.status.session
        // if (blockCopy.type === "container") {
        //   console.log(blockCopy.childBlockIds)
        //   console.log(this.state[block.id].form.value.childBlockIds)
        // }
        // console.log(blockCopy.status.session)
        // console.log(this.state[block.id].form.value.status.session)
        // console.log(blockCopy)
        // console.log(this.state[block.id].form.value)
        // console.log(this.deepEqual(blockCopy, this.state[block.id].form.value))
        this.updateState(block.id, { modified: !this.deepEqual(blockCopy, this.state[block.id].form.value) })
      }
    }
  }

  public deepEqual(object1: unknown, object2: unknown): boolean {
    if (Object.keys(object1).length !== Object.keys(object2).length) {
      // console.log("lengths")
      // console.log(object1)
      // console.log(object2)
      return false
    }
    for (const key of Object.keys(object1)) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = (val1 != null) && (typeof val1 === "object") && (val2 != null) && (typeof val2 === "object")
      if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && (val1 !== val2))) {
        // console.log("val1 !== val2")
        // console.log(val1)
        // console.log(val2)
        return false
      }
    }
    return true
  }

  enableAdminMode(): void {
    this.adminModeBehaviorSubject.next(true)
  }

  disableAdminMode(): void {
    this.adminModeBehaviorSubject.next(false)
  }

  toggleAdminMode(): void {
    this.adminModeBehaviorSubject.next(!this.adminMode)
  }

  togglePreviewMode(): void {
    this.previewModeBehaviorSubject.next(!this.previewMode)
  }

  enablePreviewMode(): void {
    this.previewModeBehaviorSubject.next(true)
  }

  disablePreviewMode(): void {
    this.previewModeBehaviorSubject.next(false)
  }

  /**
   * helpers
   */

  showBlock(blockState: BlockStateBase): boolean {
    /**
     * Check for all conditions that hide the block,
     * if none "return false" then pass-through to the "return true" at the end.
     */
    if (!this.adminMode) {
      if (blockState.unpublished) {
        return false
      }
      if (blockState.protected && !blockState.passwordAuth) {
        return false
      }
      if (blockState.personalized) {
        if (this.statusMatches(blockState.personalize) && this.roleMatches(blockState.personalize)) {
          return true
        }
        /**
         * hide block
         * personalized is selected, and none of the above conditions match
         */
        return false
      }
    }
    if (this.adminMode) {
      if (!blockState.form) {
        return false
      }
      if (blockState.deleting) {
        // return false
      }
      if (this.previewMode) {
        if (blockState.form.value.status.unpublished) {
          return false
        }
        if (blockState.form.value.status.deleting) {
          return false
        }
        if (blockState.form.value.status.personalized) {
          if (this.statusMatches(blockState.form.value.status.personalize) && this.roleMatches(blockState.form.value.status.personalize)) {
            return true
          }
          /**
           * hide block
           * personalized is selected, and none of the above conditions match
           */
          return false
        }
      }
      if (blockState.form.value.status.protected && !blockState.passwordAuth) {
        // return false
      }
    }
    return true
  }

  roleMatches(personalize: { [key: string]: boolean }): boolean {
    /**
     * show block if user has any of the allowed roles
     */
    if (this.dataService.window.metaData.user.roles) {
      for (const userRole of this.dataService.window.metaData.user.roles) {
        for (const [key, role] of Object.entries(this.personalizeService.membershipLevels)) {
          if (userRole === role.key && personalize[key]) {
            return true
          }
        }
      }
    }
  }

  statusMatches(personalize: { [key: string]: boolean }): boolean {
    /**
     * show block if user status matches any of the allowed statuses
     */
    for (const [key, role] of Object.entries(this.personalizeService.membershipStatuses)) {
      if (role.key === this.dataService.window.metaData.user.status && personalize[key]) {
        return true
      }
    }
  }

}

/**
 * Start with this.block, then narrow down to the value of the "path" part of the block.
 */
/*
let blockValue = block
path.forEach(pathPart => blockValue = blockValue?.[pathPart])
*/
