import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core"
import { BatteryQuoterService } from "../../battery-quoter.service"
import { AnalyticsEvent, AnalyticsService } from "@aaa-web/app/core/services/analytics.service"
import { environment } from "@aaa-web/environments/environment"

@Component({
  selector: "ava-results",
  templateUrl: "./results.component.html"
})
export class ResultsComponent implements OnInit, AfterViewInit {
  included: string[]
  @ViewChild("container") container: ElementRef
  containerWidth: number
  gridTemplateColumns: { [key: string]: string } = {}

  constructor(
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.included = [
      "Mobile Battery Test",
      "Mobile Installation",
      "Battery Warranty",
      "Battery Recycling"
    ]

    if (environment.ngServe) {
      const testData = ["Acura", "CL", "2002", "V6 3.2L"]
      this.bs.selections = testData
      this.bs.getBatteries(testData)
    }


    const event: AnalyticsEvent = {
      eventCategory: "battery_quote",
      eventAction: "quote",
      eventLabel: "battery",
      eventValue: 1
    }
    this.analyticsService.sendEvent(event)
  }

  /**
   * use ngAfterViewInit() for these, if needed:
   * this.bs.scrollIntoView()
   * this.bs.setFocus(this.requestOnlineButtonElement)
   */

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth

    this.gridTemplateColumns = { "grid-template-columns": "auto" }
    if (this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "50% 50%" }
    }
    if (this.containerWidth >= 1080) {
      // this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }

  }

}
