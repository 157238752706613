<div [style.height.px]="29 + 28"></div>
<!--
<div>
  <a
    nz-button nzType="primary"
    [routerLink]="'/'"
  >
    Header
  </a>
</div>
-->
<div>
  <router-outlet></router-outlet>
</div>
<!--
<div>
  <a
    nz-button nzType="primary"
    [routerLink]="'/financial'"
  >
    footer
  </a>
</div>
-->
