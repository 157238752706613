import { Injectable } from "@angular/core"
import { TransferItem } from "ng-zorro-antd/transfer"
import { FormControl, FormGroup } from "@angular/forms"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { Personalize } from "@aaa-web/app/modules/multiblock/options/personalize/service"

export interface Status {
  created: Timestamp
  deleting: boolean
  personalize: Personalize
  personalized: boolean
  preSaved: boolean
  protected: boolean
  revised: Timestamp
  session: string
  unpublished: boolean
}

export interface PublishedOptions {
  options: PublishedOption[]
  label: {
    singular: string
  }
}

export interface PublishedType {
  label: "Unpublish" | "Protect" | "PreSave" | "Delete" | "Personalize"
  value: boolean
}

export type PublishedOption = "unpublish" | "protect" | "preSave" | "delete" | "personalize"
export type PublishedTypes = Record<PublishedOption, PublishedType>

@Injectable({
  providedIn: "root"
})
export class StatusService {

  constructor(
    private dataService: DataService,
  ) {
  }

  get options(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect", "personalize"],
    }
  }

  get types(): PublishedTypes {
    return {
      delete: {
        label: "Delete",
        value: true
      },
      preSave: {
        label: "PreSave",
        value: true
      },
      protect: {
        label: "Protect",
        value: true
      },
      unpublish: {
        label: "Unpublish",
        value: false
      },
      personalize: {
        label: "Personalize",
        value: true
      }
    }
  }

  get personalizeTypes(): TransferItem[] {
    return [
      {
        key: "active",
        title: "Active"
      },
      {
        key: "notFound",
        title: "Not Found"
      },
      {
        key: "expired",
        title: "Expired"
      },
    ]
  }

  get newItem(): Status {
    return {
      created: Timestamp.now(),
      deleting: false,
      personalize: {},
      personalized: false,
      preSaved: true,
      protected: false,
      revised: Timestamp.now(),
      session: this.dataService.session,
      unpublished: false,
    }
  }

  newForm = (item: Status): FormGroup => {
    const formGroup = {}
    for (const key in this.newItem) {
      formGroup[key] = new FormControl(item[key])
    }
    return new FormGroup(formGroup)
/*
    return new FormGroup({
      created: new FormControl(item.created),
      deleting: new FormControl(item.deleting),
      personalize: new FormControl(item.personalize),
      personalized: new FormControl(item.personalized),
      preSaved: new FormControl(item.preSaved),
      protected: new FormControl(item.protected),
      revised: new FormControl(item.revised),
      session: new FormControl(item.session),
      unpublished: new FormControl(item.unpublished),
    })
*/
  }

}
