import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { SwStateMachineService } from "../../services/sw-state-machine.service"
import { Subscription } from "rxjs"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "ava-smart-weather-list",
  templateUrl: "./list.component.html"
})
export class ListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("container") container: ElementRef
  containerWidth: number
  buttonWrapperStyles: { [key: string]: string } = {}
  locationRowStyles: { [key: string]: string } = {}
  addAlertRowStyles: { [key: string]: string } = {}
  bp = { sm: false, md: false }
  private stateSubscription: Subscription
  state: any
  locations: any[]
  isVisible: boolean
  formTitle: string
  window: MetaWindow

  constructor(
    public swSms: SwStateMachineService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.stateSubscription = this.swSms.state$
      .subscribe(state => {
        if (state) {
          this.state = state
          this.isVisible = state.value === "location" && state.event && state.event.type !== "DELETE"
          this.formTitle = "Edit Weather Alert"
          if (state.event.index === state.context.locations?.length) {
            this.formTitle = "Create New Weather Alert"
          }
          if (state.context.locations) {
            this.locations = state.context.locations
            this.locations = this.locations.map((location, index) => {
              location["index"] = index
              return location
            })
            this.locations = this.locations?.sort((a, b) => (a.name > b.name) ? 1 : -1)
          }
        }
      })
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  ngOnDestroy(): void {
        if (this.stateSubscription) {
      this.stateSubscription.unsubscribe()
    }
  }

  handleOk(): void {
    this.isVisible = false
  }

  handleCancel(): void {
    this.swSms.sendEvent('CANCEL')
    this.isVisible = false
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth

    this.locationRowStyles = { "grid-template-columns": "1fr" }
    this.addAlertRowStyles = { "grid-template-columns": "1fr" }
    if (this.containerWidth >= 700) {
      this.locationRowStyles = { "grid-template-columns": "1fr 1fr" }
      this.addAlertRowStyles = { "grid-template-columns": "1fr 1fr" }
    }
    if (this.containerWidth >= 1080) {
      // this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }


    // this.bp = { sm: true, md: false }
    // if (this.containerWidth >= 700) {
    //   this.bp = { sm: false, md: true }
    // }
  }

}
