import { Component, OnInit } from "@angular/core"
import { BatteryQuoterService } from "../../battery-quoter.service"
import { AnalyticsEvent, AnalyticsService } from "@aaa-web/app/core/services/analytics.service"

@Component({
  selector: "ava-no-results",
  templateUrl: "./no-results.component.html"
})
export class NoResultsComponent implements OnInit {

  constructor(
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    const event: AnalyticsEvent = {
      eventCategory: "battery_quote",
      eventAction: "no-results",
      eventLabel: "battery",
      eventValue: 1
    }
    this.analyticsService.sendEvent(event)
  }

}
