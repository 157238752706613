<div #container [style]="'padding: 20px 0'">
  <div
    *ngIf="!window.metaData.user.memberNumber"
    [style]="'display: grid; column-gap: 20px; padding: 20px; grid-template-columns: 1fr 1fr; max-width: 50%; margin: auto'"
    [ngStyle]="buttonWrapperStyles"
  >
    <button nz-button nzType="primary">
      <a [href]="'/smart-weather-login'">
        Sign Up
      </a>
    </button>
    <button nz-button nzType="primary">
      <a [href]="'/smart-weather-login'">
        Manage Account
      </a>
    </button>
  </div>

  <ng-template #bannerTitle>
    Manage Your Weather Alerts
  </ng-template>
  <nz-card
    [nzTitle]="bannerTitle"
    *ngIf="window.metaData.user.memberNumber && auth.tokenVerified"
    nzBorderless
    class="blue-banner"
    [style]="'width: 100%'"
    [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'padding': '10px 30px'}"
  >
    <div [style]="'text-align: left'">
      You can manage up to 5 locations
    </div>
    <ava-smart-weather-list *ngIf=" !state.cloudSyncError && !state.cloudSync && (state.list || state.location)"></ava-smart-weather-list>
    <ava-smart-weather-loading *ngIf="state.cloudSync"></ava-smart-weather-loading>
    <ava-smart-weather-error *ngIf="state.cloudSyncError"></ava-smart-weather-error>
  </nz-card>
</div>
