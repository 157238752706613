import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { QuillModule } from "ngx-quill"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzDrawerModule } from "ng-zorro-antd/drawer"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzTabsModule } from "ng-zorro-antd/tabs"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"

import { ColumnViewComponent } from "./containers/column/view"
import { PageViewComponent } from "./containers/page/view"

import { ButtonViewComponent } from "./fields/button/view"
import { FlagViewComponent } from "./fields/flag/view"
import { InfoViewComponent } from "./fields/info/view"
import { ImageViewComponent } from "./fields/image/view"
import { JavascriptViewComponent } from "./fields/javascript/view"
import { QuillViewComponent } from "./fields/quill/view"
import { QuillFormQuillInlineComponent } from "./fields/quill/form-quill-inline"
import { TextViewComponent } from "./elements/text/view"
import { TextFormTextInlineComponent } from "./elements/text/form-text-inline"
import { VideoViewComponent } from "./fields/video/view"

import { BannerViewComponent } from "./blocks/banner/view"
import { BannerLargeViewComponent } from "./blocks/banner-large/view"
import { CallToActionViewComponent } from "./blocks/call-to-action/view"
import { CardViewComponent } from "./blocks/card/view"
import { ComparisonChartViewComponent } from "./blocks/comparison-chart/view"
import { FeatureViewComponent } from "./blocks/feature/view"
import { JoinViewComponent } from "./blocks/join/view"
import { LogiformViewComponent } from "./blocks/logiform/view"
import { MembershipViewComponent } from "./blocks/join/membership/view"
import { OfficeAgentsViewComponent } from "./blocks/office-agents/view"
import { OfficeViewComponent } from "./blocks/office/view"
import { OneColTextViewComponent } from "./blocks/one-col-text/view"
import { PasswordViewComponent } from "./blocks/password/view"
import { TitleViewComponent } from "./blocks/title/view"
import { UnrestrictedViewComponent } from "./blocks/unrestricted/view"

import { GoogleMapsModule } from "@angular/google-maps"
import { NzPipesModule } from "ng-zorro-antd/pipes"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NzCollapseModule } from "ng-zorro-antd/collapse"

@NgModule({
  declarations: [
    BannerViewComponent,
    BannerLargeViewComponent,
    ButtonViewComponent,
    CallToActionViewComponent,
    CardViewComponent,
    ComparisonChartViewComponent,
    ColumnViewComponent,
    PageViewComponent,
    InfoViewComponent,
    FeatureViewComponent,
    FlagViewComponent,
    ImageViewComponent,
    JoinViewComponent,
    JavascriptViewComponent,
    LogiformViewComponent,
    MembershipViewComponent,
    OneColTextViewComponent,
    OfficeAgentsViewComponent,
    OfficeViewComponent,
    PasswordViewComponent,
    QuillViewComponent,
    QuillFormQuillInlineComponent,
    TextFormTextInlineComponent,
    TextViewComponent,
    TitleViewComponent,
    VideoViewComponent,
    UnrestrictedViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    NzButtonModule,
    NzDrawerModule,
    NzFormModule,
    NzInputModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzTabsModule,
    NzToolTipModule,
    GoogleMapsModule,
    NzPipesModule,
    NzIconModule,
    NzCollapseModule,
  ],
  exports: [
    ButtonViewComponent,
    CardViewComponent,
    ColumnViewComponent,
    PageViewComponent,
    FeatureViewComponent,
    ImageViewComponent,
    JavascriptViewComponent,
    OneColTextViewComponent,
    QuillFormQuillInlineComponent,
    TextViewComponent,
    TitleViewComponent,
    TextFormTextInlineComponent,
  ],
  providers: []
})
export class MultiblockViewModule {
}
