import { Injectable } from "@angular/core"
import { ImageOptions } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BannerService } from "@aaa-web/app/modules/multiblock/blocks/banner/service"

@Injectable({
  providedIn: "root"
})
export class BannerLargeService extends BannerService {
  blockType: BlockOption = "bannerLarge"

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Large Banners",
        singular: "Large Banner"
      }
    }
  }

  get imagesOptions(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "",
        plural: "Background Images",
        singular: "Background Image"
      },
      formArray: {
        alt: false,
        cropper: true,
        min: 3,
        max: 3,
      },
      newForm: this.newImageForm,
      newItem: this.newImage,
    }
    options.options = [
      {
        description: "Desktop 1440x769",
        dimensions: "1440x769",
        minWidth: {
          px: 1440
        },
        maxWidth: {
          px: 1440
        },
        minHeight: {
          px: 769
        },
        maxHeight: {
          px: 769
        },
        target: ["self", "modal", "new"]
      },
      {
        description: "Tablet 768x411",
        dimensions: "768x411",
        minWidth: {
          px: 768
        },
        maxWidth: {
          px: 768
        },
        minHeight: {
          px: 411
        },
        maxHeight: {
          px: 411
        },
        target: []
      },
      {
        description: "Mobile 544x350",
        dimensions: "544x350",
        minWidth: {
          px: 544
        },
        maxWidth: {
          px: 544
        },
        minHeight: {
          px: 350
        },
        maxHeight: {
          px: 350
        },
        target: []
      }
    ]
    return options
  }

}
