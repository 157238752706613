import { JwtAuthService } from "@aaa-web/app/core/services/jwt-auth.service"
import { ContactRecord } from "@aaa/interface/smart-weather"
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { GcpConnectorService } from "./services/gcp-connector.service"
import { SwStateMachineService } from "./services/sw-state-machine.service"
import { Subscription } from "rxjs"
import { StateSyncService } from "./services/state-sync.service"
import { AnalyticsService } from "@aaa-web/app/core/services/analytics.service"
import { DOCUMENT } from "@angular/common"
import { MetaData, MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "ava-smart-weather",
  templateUrl: "./smart-weather.component.html"
})
export class SmartWeatherComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("container") container: ElementRef
  containerWidth: number
  buttonWrapperStyles: { [key: string]: string } = {}
  stateSubscription: Subscription
  userSubscription: Subscription
  testGet: ContactRecord
  metaData: MetaData
  state: {
    cloudSync: boolean
    cloudSyncError: boolean
    list: boolean
    location: boolean
  }
  locations: any[]
  window: MetaWindow

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public auth: JwtAuthService,
    public gcpConnector: GcpConnectorService,
    public swSms: SwStateMachineService,
    public stateSyncService: StateSyncService,
    private analyticsService: AnalyticsService,
    public domWindow: Window,
  ) {
    // console.log("SmartWeatherComponent")
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    // this.metaData = this.window["metaData"]
    // console.log(JSON.stringify(params, null, 2))

    if (this.window.metaData.user.memberNumber) {
      // this.auth.setupService(this.metaData.user.token)
      this.userSubscription = this.auth.user$
        .subscribe(async user => {
          if (user) {
            // console.log(user)
            // console.log(this.window.metaData)
            await this.gcpConnector.setupService(this.window.metaData.clubId)
            await this.stateSyncService.setupService(this.window.metaData.clubId, this.window.metaData.user.memberNumber)
            this.swSms.setupService(this.window.metaData.user)
          }
        })
      this.stateSubscription = this.swSms.state$
        .subscribe(state => {
          if (state) {
            this.state = {
              cloudSync: state.value === "cloudSync",
              cloudSyncError: state.value === "cloudSyncError",
              list: state.value === "list",
              location: state.value === "location"
            }
            if (state.context.locations) {
              this.locations = state.context.locations
            }
          }
        })
    }

    // this.testWrite = await this.gcpConnector.writeContact(testRecord)
    // console.log(JSON.stringify(this.testWrite, null, 2))

    // this.testGet = await this.gcpConnector.getContact()
    // console.log(JSON.stringify(this.testGet, null, 2))

    if (this.metaData?.user.id && this.metaData?.user.id !== "1") {
      this.analyticsService.sendPage(window.location.pathname + "/smart-weather-form")
    }

  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription?.unsubscribe()
    }
    if (this.stateSubscription) {
          if (this.stateSubscription) {
      this.stateSubscription.unsubscribe()
    }
    }
    if (this.stateSyncService) {
      this.stateSyncService?.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth

    this.buttonWrapperStyles = {}
    if (this.containerWidth >= 760) {
      this.buttonWrapperStyles = { "max-width": "50%" }
    }
    if (this.containerWidth >= 1080) {
      // this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }

  }

}
