import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { ThemeOption, ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { Button, ButtonOptions, ButtonService, } from "@aaa-web/app/modules/multiblock/fields/button/service"
import { BoxModel, BoxModelService, BoxModelOptions } from "@aaa-web/app/modules/multiblock/options/box-model/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    titles: QuillEditor[]
    buttons: Button[]
  }
  options: {
    boxModel: BoxModel
    theme: ThemeOption
  }
}

export interface Options {
  block: BlockOptions
  titles: QuillEditorOptions
  buttons: ButtonOptions
  boxModel: BoxModelOptions
  published: PublishedOptions
  theme: ThemeOptions
}

@Injectable({
  providedIn: "root"
})
export class CallToActionService {
  blockType: BlockOption = "callToAction"

  constructor(
    private boxModelService: BoxModelService,
    private buttonService: ButtonService,
    private statusService: StatusService,
    private quillService: QuillService,
    private dataService: DataService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      titles: this.titlesOptions,
      buttons: this.buttonsOptions,
      boxModel: this.boxModelOptions,
      published: this.publishedOptions,
      theme: this.themeOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Call to Actions",
        singular: "Call to Action"
      },
    }
  }

  private get titlesOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      toolbarOptions: {
        buttons: ["bold", "italic", "underline"]
      },
      label: {
        placeholder: "copy",
        plural: "Copy",
        singular: "Copy"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get buttonsOptions(): ButtonOptions {
    const options: ButtonOptions = {
      newItem: this.newButton,
      newForm: this.buttonService.newForm,
      options: [],
      label: {
        placeholder: "text",
        plural: "Buttons",
        singular: "Button"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        color: ["20"],
        targetOption: ["self", "new", "modal", "phone"],
      })
    }
    return options
  }

  get themeOptions(): ThemeOptions {
    return {
      label: {
        singular: "Theme Color"
      },
      options: ["cta1", "cta2", "cta3"]
    }
  }

  get boxModelOptions(): BoxModelOptions {
    return this.boxModelService.options
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect", "personalize"]
    }
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newButton(): Button {
    const item = this.buttonService.newItem
    item.color = "20"
    return item
  }

  get newBoxModel(): BoxModel {
    return this.boxModelService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        titles: [this.newQuillItem],
        buttons: [],
      },
      options: {
        boxModel: this.newBoxModel,
        theme: "cta1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newQuillForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

}
