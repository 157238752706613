import { Injectable } from "@angular/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { ColorService } from "@aaa-web/app/modules/multiblock/services/color.service"
import { ThemeOption, ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { AlignmentOption, AlignmentOptions } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { Button, ButtonOptions, ButtonService } from "@aaa-web/app/modules/multiblock/fields/button/service"
import { BoxModel, BoxModelService, BoxModelOptions } from "@aaa-web/app/modules/multiblock/options/box-model/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    bodies: QuillEditor[]
    buttons: Button[]
  }
  options: {
    alignment: AlignmentOption
    boxModel: BoxModel
    theme: ThemeOption
  }
}

export interface Options {
  block: BlockOptions
  body: QuillEditorOptions
  button: ButtonOptions
  alignment: AlignmentOptions
  boxModel: BoxModelOptions
  published: PublishedOptions
  theme: ThemeOptions
}

@Injectable({
  providedIn: "root"
})
export class OneColTextService {
  blockType: BlockOption = "oneColText"

  constructor(
    private boxModelService: BoxModelService,
    private buttonService: ButtonService,
    private colorService: ColorService,
    private dataService: DataService,
    private statusService: StatusService,
    private quillService: QuillService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      body: this.bodyOptions,
      button: this.buttonOptions,
      alignment: this.alignmentOptions,
      boxModel: this.boxModelOptions,
      published: this.publishedOptions,
      theme: this.themeOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "One Column Text",
        singular: "One Column Text"
      },
    }
  }

  get bodyOptions(): QuillEditorOptions {
    return this.quillService.options
  }

  private get buttonOptions(): ButtonOptions {
    const options: ButtonOptions = {
      newItem: this.newButton,
      newForm: this.buttonService.newForm,
      options: [],
      label: {
        placeholder: "button text",
        plural: "Buttons",
        singular: "Button"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        color: ["41"],
        targetOption: ["self", "new", "modal"],
      })
    }
    return options
  }

  get themeOptions(): ThemeOptions {
    return {
      label: {
        singular: "Theme"
      },
      options: ["oct1", "oct2", "oct3"]
    }
  }

  get alignmentOptions(): AlignmentOptions {
    return {
      options: ["left", "center"],
      label: {
        singular: "Alignment"
      }
    }
  }

  get boxModelOptions(): BoxModelOptions {
    return this.boxModelService.options
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish"]
    }
  }

  get newButton(): Button {
    return this.buttonService.newItem
  }

  get newBoxModel(): BoxModel {
    return this.boxModelService.newItem
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        bodies: [this.newQuillItem],
        buttons: [],
      },
      options: {
        alignment: "center",
        boxModel: this.newBoxModel,
        theme: "oct1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

}
