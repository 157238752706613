import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from "@angular/core"
import { Multiblock } from "./block.service"
import { BlockService } from "./block.service"
// import { StateService } from "./state.service"
// import { StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import {DataService} from "@aaa-web/app/modules/multiblock/services/data.service";

@Injectable({
  providedIn: "root"
})
export class BuildService {

  constructor(
    private blockService: BlockService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    // private statusService: StatusService,
    // private stateService: StateService,
  ) {
  }

  createBlockViews(blockContainerRefs: ViewContainerRef[], childBlocks: Multiblock[]): void {
    blockContainerRefs.forEach((blockContainerRef: ViewContainerRef, index: number) => {
      if (childBlocks[index]) {
        this.createBlockView(blockContainerRef, childBlocks[index])
      }
    })
  }

  createBlockView(blockContainerRef: ViewContainerRef, block: Multiblock, options?: { mode: string }): void {
    // if (block && this.blocksCreated.admin && this.blocksCreated.admin[block.id]) {
    // return
    // }
    // if (block && !this.blocksCreated.admin && this.blocksCreated.public[block.id]) {
    // return
    // }

    const blockType = this.blockService.blockTypes[block?.blockType]
    if (blockType) {
      const component = blockType.component
      /**
       * The following "import" argument must have
       * the beginning "../../" and the ending "/view"
       * as strings so that "import" will compile.
       */
      // console.log(blockContainerRef)
      if (component.path !== "multiblock/container-blocks/block") {
        import("../../" + component.path + "/view").then(component => {
          blockContainerRef.clear()
          const componentRef = blockContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
          componentRef.instance["blockId"] = block.id
          if (options) {
            Object.keys(options).forEach(option => {
              componentRef.instance[option] = options[option]
            })
          }
          // this.blocksCreated.admin[block.id] = this.stateService.adminMode ? true : this.blocksCreated.admin[block.id]
          // this.blocksCreated.public[block.id] = !this.stateService.adminMode ? true : this.blocksCreated.public[block.id]
        })
      }
    }
  }

  loadAdminComponent(blockContainerRef: ViewContainerRef): void {
    if (blockContainerRef) {
      blockContainerRef.clear()
      import("../admin/admin.component").then(component => {
        const componentRef = blockContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
        componentRef.instance["block"] = this.dataService.pageBlock
      })
    }
  }

}
