import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { ColorOption } from "@aaa-web/app/modules/multiblock/services/color.service"
import { TargetOption } from "@aaa-web/app/modules/multiblock/services/style.service"
import { FormArrayOptions, FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { FieldType } from "@aaa-web/app/modules/multiblock/services/field.service"

export interface Button {
  blockRef: string
  color: ColorOption
  targetOption: TargetOption
  targetPath: string
  type: FieldType
  value: string
}

export interface ButtonOption {
  color: ColorOption[]
  targetOption: TargetOption[]
}

export interface ButtonOptions {
  options: ButtonOption[]
  formArray?: FormArrayOptions
  newItem: Button
  newForm: (item: Button) => AbstractControl
  label: {
    placeholder: string
    plural: string
    singular: string
  }
}

export interface ButtonStyles {
  backgroundColorClass?: string
  borderBottom?: string
  padding?: { px: number }
  borderRadius?: { px: number }
  fontWeight?: { value: number }
  fontSize?: { px: number }
  fontColor?: { hex: string, rgba: string }
  size: "small" | "default" | "large"
  type: "primary" | "default" | "link" | "dashed" | "text"
}

@Injectable({
  providedIn: "root"
})
export class ButtonService {

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Button {
    return {
      blockRef: null,
      color: null,
      targetPath: "",
      targetOption: "self",
      type: "button",
      value: "",
    }
  }

  newForm = (item: Button): AbstractControl => {
    return this.formService.newForm(item, this.newItem)
  }

  get options(): ButtonOptions {
    const options: ButtonOptions = {
      newItem: this.newItem,
      newForm: this.newForm,
      options: [],
      label: {
        placeholder: "text",
        plural: "Buttons",
        singular: "Button"
      },
      formArray: {
        max: 3,
        min: 0,
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        color: ["41", "30"],
        targetOption: ["self", "new", "modal"],
      })
    }
    return options
  }

}
