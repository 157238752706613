import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { ThemeOption, ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { Image, ImageOptions, ImageService } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { Video, VideoOptions, VideoService } from "@aaa-web/app/modules/multiblock/fields/video/service"
import {
  Javascript,
  JavascriptOptions,
  JavascriptService
} from "@aaa-web/app/modules/multiblock/fields/javascript/service"
import { AlignmentOption, AlignmentOptions } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { Button, ButtonOptions, ButtonService, } from "@aaa-web/app/modules/multiblock/fields/button/service"
import { BoxModel, BoxModelService, BoxModelOptions } from "@aaa-web/app/modules/multiblock/options/box-model/service"
import {
  ColumnWidth, ColumnWidthOptions, ColumnWidthService
} from "@aaa-web/app/modules/multiblock/options/column-width/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    bodies: QuillEditor[]
    disclaimers: QuillEditor[]
    buttons: Button[]
    images: Image[]
    javascripts: Javascript[]
    videos: Video[]
  }
  options: {
    alignment: AlignmentOption
    boxModel: BoxModel
    columnWidths: ColumnWidth[]
    theme: ThemeOption
  }
}

export interface Options {
  block: BlockOptions
  bodies: QuillEditorOptions
  disclaimers: QuillEditorOptions
  buttons: ButtonOptions
  images: ImageOptions
  javascripts: JavascriptOptions
  videos: VideoOptions
  alignment: AlignmentOptions
  boxModel: BoxModelOptions
  columnWidths: ColumnWidthOptions
  published: PublishedOptions
  theme: ThemeOptions
}

@Injectable({
  providedIn: "root"
})
export class BannerService {
  blockType: BlockOption = "banner"

  constructor(
    protected boxModelService: BoxModelService,
    protected buttonService: ButtonService,
    protected columnWidthService: ColumnWidthService,
    protected imageService: ImageService,
    protected javascriptService: JavascriptService,
    protected statusService: StatusService,
    protected quillService: QuillService,
    protected videoService: VideoService,
    protected dataService: DataService,
    protected formService: FormService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      bodies: this.bodiesOptions,
      disclaimers: this.disclaimersOptions,
      buttons: this.buttonsOptions,
      images: this.imagesOptions,
      javascripts: this.javascriptsOptions,
      videos: this.videosOptions,
      alignment: this.alignmentOptions,
      boxModel: this.boxModelOptions,
      columnWidths: this.columnWidthOptions,
      published: this.publishedOptions,
      theme: this.themeOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Banners",
        singular: "Banner"
      }
    }
  }

  get bodiesOptions(): QuillEditorOptions {
    const options = this.quillService.options
    options.newItem = this.newQuillItem
    options.toolbarOptions.buttons = [
      "undo",
      "redo",
      "size",
      "header",
      "bold",
      "italic",
      "underline",
      // "align",
      "ordered-list",
      "bullet-list",
      "blockquote",
      "code-block",
      "subscript",
      "superscript",
      "indent",
      "link",
    ]
    return options
  }

  get disclaimersOptions(): QuillEditorOptions {
    const options = this.quillService.options
    options.formArray.max = 0 // disable disclaimer for banner
    options.label = {
      placeholder: "disclaimer text",
      plural: "Disclaimers",
      singular: "Disclaimer"
    }
    options.toolbarOptions.buttons = ["bold", "italic", "underline", "align", "subscript", "superscript", "indent"]
    return options
  }

  get buttonsOptions(): ButtonOptions {
    const options: ButtonOptions = {
      newItem: this.newButton,
      newForm: this.buttonService.newForm,
      options: [],
      label: {
        placeholder: "text",
        plural: "Buttons",
        singular: "Button"
      },
      formArray: {
        min: 0,
        max: 3
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        color: ["41", "30"],
        targetOption: ["self", "new", "modal"],
      })
    }
    return options
  }

  get imagesOptions(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "",
        plural: "Background Images",
        singular: "Background Image"
      },
      formArray: {
        alt: false,
        cropper: true,
        min: 3,
        max: 3,
      },
      newForm: this.newImageForm,
      newItem: this.newImage,
    }
    options.options = [
      {
        description: "Desktop 1440x480",
        dimensions: "1440x480",
        minWidth: {
          px: 1440
        },
        maxWidth: {
          px: 1440
        },
        minHeight: {
          px: 480
        },
        maxHeight: {
          px: 480
        },
        target: ["self", "modal", "new"]
      },
      {
        description: "Tablet 768x320",
        dimensions: "768x320",
        minWidth: {
          px: 768
        },
        maxWidth: {
          px: 768
        },
        minHeight: {
          px: 320
        },
        maxHeight: {
          px: 320
        },
        target: []
      },
      {
        description: "Mobile 544x350",
        dimensions: "544x350",
        minWidth: {
          px: 544
        },
        maxWidth: {
          px: 544
        },
        minHeight: {
          px: 350
        },
        maxHeight: {
          px: 350
        },
        target: []
      }
    ]
    return options
  }

  get javascriptsOptions(): JavascriptOptions {
    const options: JavascriptOptions = {
      options: [],
      newForm: this.newJavascriptForm,
      newItem: this.newJavascript,
      label: {
        placeholder: "javascript code",
        plural: "Logiforms",
        singular: "Logiform"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        background: {
          color: false, // changing to true will also need a formControl
          opacity: true
        },
        value: true
      })
    }
    return options
  }

  get videosOptions(): VideoOptions {
    const options: VideoOptions = {
      formArray: {
        max: 0,
        min: 0,
      },
      label: {
        placeholder: "",
        plural: "Videos",
        singular: "Video"
      },
      newForm: this.newVideoForm,
      newItem: this.newVideoItem,
      options: []
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        }
      })
    }
    return options

  }

  get themeOptions(): ThemeOptions {
    return {
      label: {
        singular: "Theme Color"
      },
      options: ["banner1", "banner2"]
    }
  }

  get alignmentOptions(): AlignmentOptions {
    return {
      options: ["left", "center"],
      label: {
        singular: "Alignment"
      }
    }
  }

  get boxModelOptions(): BoxModelOptions {
    return this.boxModelService.options
  }

  get columnWidthOptions(): ColumnWidthOptions {
    return {
      formArray: {
        max: 2,
        min: 1
      },
      label: {
        plural: "Columns",
        singular: "Column",
        title: "Column Widths"
      },
      newForm: this.newColumnWidthForm,
      newItem: this.newColumnWidthItem,
      triggers: [
        {
          action: "addItem()",
          field: ["fields", "javascripts"],
          label: "javascript-addColumn",
          quantity: 1,
        },
      ]

    }
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect", "personalize"]
    }
  }

  get newImage(): Image {
    return this.imageService.newItem
  }

  get newQuillItem(): QuillEditor {
    const item = this.quillService.newItem
    item.value = "<h2><b style=\"font-size: 60px;\">Title</b></h2><h3><span style=\"font-size: 32px;\">Subtitle</span></h3>"
    return item
  }

  get newButton(): Button {
    return this.buttonService.newItem
  }

  get newJavascript(): Javascript {
    const item = this.javascriptService.newItem
    item.background = {
      color: "20",
      opacity: 80
    }
    return item
  }

  get newVideoItem(): Video {
    return this.videoService.newItem
  }

  get newBoxModelItem(): BoxModel {
    return this.boxModelService.newItem
  }

  get newColumnWidthItem(): ColumnWidth {
    return this.columnWidthService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        bodies: [this.newQuillItem],
        disclaimers: [],
        buttons: [],
        images: [this.newImage, this.newImage, this.newImage],
        javascripts: [],
        videos: []
      },
      options: {
        alignment: "left",
        boxModel: this.newBoxModelItem,
        columnWidths: [this.newColumnWidthItem],
        theme: "banner1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newTextForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

  newImageForm = (item: Image): AbstractControl => {
    return this.imageService.newForm(item)
  }

  newJavascriptForm = (item: Javascript): AbstractControl => {
    return this.javascriptService.newForm(item)
  }

  newVideoForm = (item: Video): AbstractControl => {
    return this.videoService.newForm(item)
  }

  newColumnWidthForm = (item: ColumnWidth): AbstractControl => {
    return this.columnWidthService.newForm(item)
  }

}
