import { Injectable } from "@angular/core"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { AngularFireAnalytics } from "@angular/fire/analytics"
import firebase from "firebase/app"
import EventParams = firebase.analytics.EventParams

export interface AnalyticsEvent {
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue: number
}

export interface AnalyticsCustomEvent {
  eventName: string
  eventParams?: {
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsPageViewEvent {
  eventName: "page_view"
  eventParams?: {
    page_title?: string
    page_location?: string
    page_path?: string
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsScreenViewEvent {
  eventName: "screen_view"
  eventParams?: {
    app_name: string
    screen_name: EventParams["screen_name"]
    app_id?: string
    app_version?: string
    app_installer_id?: string
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  window: MetaWindow

  constructor(
    private angularFireAnalytics: AngularFireAnalytics,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  sendPage(page: string): void {
    // // @ts-ignore
    // this.window.gtag('event', 'page_view', {
    //   page_path: page,
    //   'send_to': this.window['metaData'].gaId
    // })

    if (this.window.ga) {
      this.window.ga("wssAaaTracker.set", "page", page)
      this.window.ga("wssAaaTracker.send", "pageview")
    }
  }

  sendEvent(event: AnalyticsEvent): void {
    if (this.window.ga) {
      this.window.ga("wssAaaTracker.send", "event", event)
    }

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-1',
    //   'event_label': eventLabel,
    //   'value': eventValue,
    //   'send_to': this.window['metaData'].gaId
    // })

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-2',
    //   'event_label': eventLabel,
    //   'value': eventValue
    // })


  }

  sendFirebaseCustomEvent(analyticsEvent: AnalyticsCustomEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  sendFirebasePageViewEvent(analyticsEvent: AnalyticsPageViewEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  sendFirebaseScreenViewEvent(analyticsEvent: AnalyticsScreenViewEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  /**
   * For more info on preset log event types, see node_modules/firebase/index.d.ts
   * line 4571: declare namespace firebase.analytics
   */

}
