import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { environment } from "@aaa-web/environments/environment"
import { FormValues, Region } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "ava-region",
  templateUrl: "./region.component.html"
})
export class RegionComponent implements OnInit {
  @Input() formId: string
  formValue: FormValues
  regions: Region[] = []

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValue = this.formService.formValues[this.formId]
    this.formValue.selectedRegionId = ""

    if (environment.ngServe) {
      // this.sms.sendEvent('NEXT')
    }

    const formAgentValue = this.formService.form[this.formId].get(["agent"]).value

    if (formAgentValue) {
      /**
       * filter regions options
       * show only regions options that the selected agent supports.
       */
      this.regions = this.formValue.regions
        .filter(region => formAgentValue.regionIds.some(regionId => regionId === region.id))

      /**
       * If selectedRegionId is not populated then select the first region.id with region.default:true
       * this is kinda hacky....but
       *    look for default region in the filtered list of regions,
       *    if not found, then use the first one from the filtered list.
       */
      if (!this.formValue.selectedRegionId) {
        this.formValue.selectedRegionId = this.regions.find(region => !!region.default)?.id
      }
      if (!this.formValue.selectedRegionId) {
        this.formValue.selectedRegionId = this.regions[0].id
      }

    }
    if (!formAgentValue) {
      /**
       * load all regions options
       */
      this.regions = this.formValue.regions
      /**
       * If selectedRegionId is not populated then select the first region.id with region.default:true
       */
      if (!this.formValue.selectedRegionId) {
        this.formValue.selectedRegionId = this.formValue.regions.find(region => !!region.default)?.id
      }
    }

  }

}
