<div
  #containerElement
  class="cdkDragContainer"
  style="position: relative"
  (mouseenter)="showToolbar()"
  (mouseleave)="hideToolbar()"
  (pointerup)="pointerUp($event)"
  (pointerdown)="pointerDown()"
  [style.margin]="blockStyles.margin"
  [style.width.px]="blockStyles.width.px"
>
  <ng-container #blockContainer></ng-container>
  <ng-container #toolbarContainer></ng-container>
</div>
