import { Injectable } from "@angular/core"

export interface ColorType {
  label: string
  description: string
  hex: string
  rgba: string
}

export type ColorOption =
  | "00"
  | "10"
  | "20"
  | "30"
  | "40"
  | "41"
  | "darkgray"
  | "gray"
  | "gray1"
  | "gray2"
  | "gray3"
  | "gray4"
  | "gray5"
  | "gray6"
  | "gray7"
  | "gray8"
  | "gray9"
  | "grayA"
  | "grayB"
  | "grayC"
  | "grayD"
  | "grayE"
  | "grayF1"
  | "grayF4"


export type ColorTypes = Record<ColorOption, ColorType>

@Injectable({
  providedIn: "root"
})
export class ColorService {

  get types(): ColorTypes {
    return {
      "00": {
        description: "used for plain text button",
        hex: null,
        label: "Text",
        rgba: null
      },
      "10": {
        description: "",
        hex: "",
        label: "Black",
        rgba: "rgba(35, 35, 35, 0.85)"
      },
      "20": {
        description: "",
        hex: "#fff",
        label: "White",
        rgba: "rgba(255, 255, 255, 1)"
      },
      "30": {
        description: "",
        hex: "#d43f3a",
        label: "Red",
        rgba: "rgba(212, 63, 58, 1)"
      },
      "40": {
        description: "",
        hex: "#0275d8",
        label: "Blue",
        rgba: "rgba(2, 117, 216, 1)"
      },
      "41": {
        description: "",
        hex: "#00529b",
        label: "Dark Blue",
        rgba: "rgba(0, 82, 155, 1)"
      },
      darkgray: {
        description: "",
        hex: "",
        label: "Dark Gray",
        rgba: "rgba(230, 230, 230, 1)"
      },
      gray: {
        description: "",
        hex: "#f6f6f6",
        label: "Gray",
        rgba: "rgba(246, 246, 246, 1)"
      },
      gray1: {
        description: "",
        hex: "#111111",
        label: "Gray 1",
        rgba: ""
      },
      gray2: {
        description: "",
        hex: "#222222",
        label: "Gray 2",
        rgba: ""
      },
      gray3: {
        description: "",
        hex: "#333333",
        label: "Gray 3",
        rgba: ""
      },
      gray4: {
        description: "",
        hex: "#444444",
        label: "Gray 4",
        rgba: "rgba(68, 68, 68, 1)"
      },
      gray5: {
        description: "",
        hex: "#555555",
        label: "Gray 5",
        rgba: ""
      },
      gray6: {
        description: "",
        hex: "#666666",
        label: "Gray 6",
        rgba: ""
      },
      gray7: {
        description: "",
        hex: "#777777",
        label: "Gray 7",
        rgba: "rgba(119, 119, 119, 1)"
      },
      gray8: {
        description: "",
        hex: "#888888",
        label: "Gray 8",
        rgba: ""
      },
      gray9: {
        description: "",
        hex: "#999999",
        label: "Gray 9",
        rgba: ""
      },
      grayA: {
        description: "",
        hex: "#aaaaaa",
        label: "Gray A",
        rgba: ""
      },
      grayB: {
        description: "",
        hex: "#bbbbbb",
        label: "Gray B",
        rgba: ""
      },
      grayC: {
        description: "",
        hex: "#cccccc",
        label: "Gray C",
        rgba: ""
      },
      grayD: {
        description: "",
        hex: "#dddddd",
        label: "Gray D",
        rgba: ""
      },
      grayE: {
        description: "",
        hex: "#eeeeee",
        label: "Gray E",
        rgba: ""
      },
      grayF1: {
        description: "",
        hex: "#f1f1f1",
        label: "Gray F1",
        rgba: "rgba(119, 119, 119, 1)"
      },
      grayF4: {
        description: "",
        hex: "#f4f4f4",
        label: "Gray F4",
        rgba: ""
      },
    }
  }

}
