import { firestore } from 'firebase-admin';
import { MembershipRequest } from './connectsuite';

/*
    Storage (realtime database)
*/

export enum Operation {
    JOIN = 'JOIN',
    RENEW = 'RENER',
    UPDATE = 'UPDATE',
    CANCEL = 'CANCEL',
    QUERY = 'QUERY'
}

export interface Event {
    sessionId: string;
    clubId: string;
    created: firestore.Timestamp;
    eventName: EventName;
    payload: EventPayload;
}

export enum EventName {
    //payload interface managed by Event Coordinator
    OPERATION_START = 'OPERATION_START',
    OPERATION_COMPLETE = 'OPERATION_COMPLETE',
    OPERATION_DATA_UPDATE = 'OPERATION_DATA_UPDATE',
    PAYMENT_COMPLETE = 'PAYMENT_COMPLETE',
    MEMBERSHIP_COMPLETE = 'MEMBERSHIP_COMPLETE',

    //payload interface managed by selected payment subsystem
    PAYMENT_QUERY = 'PAYMENT_QUERY',

    //payload interface managed by selected membership subsystem
    MEMBERSHIP_QUERY = 'MEMBERSHIP_QUERY',

    //things like statistic reporting or garbage collector
    SERVICES_QUERY = 'SERVICES_QUERY'
}

export interface EventPayload {
    // if this event has a response, it will be placed in [sessionDoc].responses.<subsystemType>.<subsystemName>.<responseKey>
    responseKey?: string
}

/*
    Event Coordinator Events
*/
export interface OperationStartPayload extends EventPayload {
    operation: Operation;
}
export interface OperationCompletePayload extends EventPayload {
    success: boolean;
}

export interface OperationDataUdatePayload extends EventPayload {
    data: unknown;
}

/*
    Payment Subsystem Events
*/
export interface PaymentTesterEventPayload extends EventPayload {
    testProperty: string
}

export interface PaymentCyberSourceEventPayload extends EventPayload {
    method: 'captureContext' | 'submitPayment'
    billingDetails?: Record<string, string>
}

export interface PaymentShift4EventPayload extends EventPayload {
    billingDetails?: Record<string, string>
}


/*
    Membership Subsystem Events
*/
export interface MembershipTesterEventPayload extends EventPayload {
    testProperty: string
}

export interface MembershipConnectSuiteEventPayload extends EventPayload {
    method: 'getMemberInfo' | 'getMembershipOfferings' | 'isAlive' | 'outgoingIP' | 'validateJoin' | 'submitJoin'
    memberNumber?: string
    format?: 'json' | 'xml'
    membershipRequest?: MembershipRequest
}

export interface MembershipMEventPayload extends EventPayload {
    x?: string
}

/*
    Membership Subsystem Events (MZP)
*/

export enum MembershipMzpMethod {
    isAlive = "isAlive",
    memberLookup = "memberLookup"
}

export interface MembershipMzpEventPayload extends EventPayload {
    method: MembershipMzpMethod
}

export interface MembershipMzpMemberLookupEventPayload extends MembershipMzpEventPayload {
    method: MembershipMzpMethod.memberLookup,
    memberNumber: string // 7 digit format
}


/*
    Global Services Events
*/

export interface ServicesEventPayload extends EventPayload {
    method: 'garbageCollection' | '...'
}



