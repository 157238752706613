import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"

export interface OfficeRegion {
  tid: string
  value: string
}

export interface OfficeRegionOption {
  tid: true
  value: true
}

export interface OfficeRegionOptions {
  options: OfficeRegionOption[]
  formArray: FormArrayOptions
  newForm: (item: OfficeRegion) => AbstractControl
  newItem: OfficeRegion
  label: {
    placeholder: string
    plural: string
    singular: string
  }
}

export interface OfficeRegionStyles {
}

@Injectable({
  providedIn: "root"
})
export class OfficeRegionService {

  newItem = (): OfficeRegion => {
    return {
      tid: "",
      value: "",
    }
  }

  newForm = (item: OfficeRegion): FormGroup => {
    return new FormGroup({
      tid: new FormControl(item.tid),
      value: new FormControl(item.value),
    })
  }

}
