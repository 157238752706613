import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AgentSchedulerCustomElementComponent } from "@aaa-web/app/custom-elements/agent-scheduler/component"
import { AgentSchedulerModule } from "@aaa-web/app/modules/agent-scheduler/agent-scheduler.module"

@NgModule({
  exports: [],
  declarations: [
    AgentSchedulerCustomElementComponent,
  ],
  imports: [
    CommonModule,
    AgentSchedulerModule,
  ]
})
export class AgentSchedulerCustomElementModule {
}
