import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"

export interface OfficeServiceField {
  tid: string
  value: string
}

export interface OfficeServiceOption {
  tid: true
  value: true
}

export interface OfficeServiceOptions {
  options: OfficeServiceOption[]
  formArray: FormArrayOptions
  newForm: (item: OfficeServiceField) => AbstractControl
  newItem: OfficeServiceField
  label: {
    placeholder: string
    plural: string
    singular: string
  }
}

export interface OfficeServiceStyles {
}

@Injectable({
  providedIn: "root"
})
export class OfficeServiceService {

  newItem = (): OfficeServiceField => {
    return {
      tid: "",
      value: "",
    }
  }

  newForm = (item: OfficeServiceField): AbstractControl => {
    return new FormGroup({
      tid: new FormControl(item.tid),
      value: new FormControl(item.value),
    })
  }

}
