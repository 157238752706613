import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { JoinRenewCustomElementComponent } from "./component"
import { JoinRenewModule } from "@aaa-web/app/modules/join-renew/join-renew.module"

@NgModule({
  exports: [],
  declarations: [
    JoinRenewCustomElementComponent
  ],
  imports: [
    CommonModule,
    JoinRenewModule
  ]
})
export class JoinRenewCustomElementModule {
}
