import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from "@angular/fire/firestore"
import firebase from "firebase"
import { map } from "rxjs/operators"

export interface Agent {
  _type: "agent"
  id: string
  title: string
  pathName: string
  status: "0" | "1"
  firstNames: string[]
  lastNames: string[]
  phones: string[]
  photos: {
    fid: string
    original: string
    alt: string
    title: string
    derivatives: {
      searchResult: string
    }
  }[]
  services: {
    tid: string
  }[]
  types: {
    tid: string
  }[]
  offices: {
    nid: string
  }[]
  travelLinks: {
    label: string
    url: string
  }[]
  insuranceLinks: {
    label: string
    url: string
  }[]
  appointmentLinks: {
    label: string
    url: string
  }[]
}

export interface Agents {
  [key: string]: Agent
}

@Injectable({
  providedIn: "root"
})
export class AgentInterface {
  window: MetaWindow
  private agentsRef: AngularFirestoreCollection<Agent>
  public agents$: Observable<Agent[]>

  constructor(
    private afs: AngularFirestore,
    private httpClient: HttpClient,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
    this.agentsRef = this.afs
      .collection("wss-aaa-web")
      .doc(this.window.metaData.clubId)
      .collection("data")
      .doc("drupal")
      .collection("nodes", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        query = query.where("_type", "==", "agent")
        return query
      })
    this.agents$ = this.agentsRef
      .snapshotChanges()
      .pipe(
        map((agents: DocumentChangeAction<Agent>[]) => {
          return agents.map(result => {
            if (result) {
              const data = result.payload.doc.data()
              const id = result.payload.doc.id
              return { id, ...data }
            }
          })
        })
      )
  }

  // getOffices(): Observable<Offices> {
    // const officesUrl = this.window.metaData.origin || "" + "/data/office/all"
    // return this.httpClient.get<Offices>(officesUrl)
    // return this.officesRef.snapshotChanges().subscribe()
  // }

/*
  handleError(error: HttpErrorResponse): ObservableInput<never> {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message)
    } else {
      console.log("An different error occurred:")
      console
        .error("Backend returned code " + error.status, "body was: " + error.error)
    }
    return throwError("Something bad happened; please try again later.")
  }
*/

}
