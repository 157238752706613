import { Injectable } from "@angular/core"
import { BlockBase, BlockOption, BlockOptions } from "../../services/block.service"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"

export interface Block extends BlockBase {
  fields: {
    password: {
      value: string
    }
  }
}

export interface Options {
  block: BlockOptions
  published: PublishedOptions
}

@Injectable({
  providedIn: "root"
})
export class PasswordService {
  blockType: BlockOption = "password"

  constructor(
    private dataService: DataService,
    private statusService: StatusService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      published: this.publishedOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Passwords",
        singular: "Password"
      },
    }
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish"]
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        password: {
          value: ""
        }
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

/*
  newForm = (block: Block): BlockState => {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          password: new FormGroup({
            value: new FormControl(block.fields.password.value)
          }),
        }),
        pathnames: new FormArray(block.pathnames.map(item => new FormControl(item))),
        status: this.statusService.newForm(block.status)
      })
    }
  }
*/

}
