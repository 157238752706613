import { Component, Input, OnInit } from "@angular/core"
import { BatteryQuoterService } from "./battery-quoter.service"
import { AnalyticsEvent, AnalyticsService } from "@aaa-web/app/core/services/analytics.service"
import { environment } from "@aaa-web/environments/environment"

@Component({
  selector: "ava-battery-quoter",
  templateUrl: "./battery-quoter.component.html"
})
export class BatteryQuoterComponent implements OnInit {
  @Input() params: string
  environment = environment

  constructor(
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.bs.params = JSON.parse(this.params)
    this.bs.resetMake()
    if (this.bs.params.endpoint && this.bs.params.userId) {
      this.bs.getMakes()
    }
    this.bs.setComponentState("init", this.bs.ComponentState.FORM)

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: "Custom Element",
      eventAction: "Supported",
      eventLabel: "<ava-battery-quoter>" + window.location.origin + window.location.pathname,
      eventValue: 1
    }
    this.analyticsService.sendEvent(eventCustomElement)
  }

}
