import { Component, Input, OnInit } from "@angular/core"
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage"
import { NzShowUploadList, NzUploadFile, NzUploadListType, NzUploadXHRArgs } from "ng-zorro-antd/upload"
import { Subscription, Observable } from "rxjs"
import { FileManagerService } from "../file-manager.service"
import { RoleService } from "@aaa-web/app/core/services/role.service"

@Component({
  selector: "ava-file-manager-upload",
  templateUrl: "./file-manager-upload.component.html"
})
export class FileManagerUploadComponent implements OnInit{
  @Input() directory: string
  @Input() fileList: NzUploadFile[]
  uploadFileList: NzUploadFile[] = []
  showList: boolean
  showUploadList: boolean | NzShowUploadList = { showRemoveIcon: true, showDownloadIcon: false, showPreviewIcon: true }
  showUploadButton: boolean = true
  ref: AngularFireStorageReference
  task: AngularFireUploadTask

  uploadProgress: { percent: number } = { percent: 0 }
  downloadURL: string
  uploadState: boolean

  listType: NzUploadListType = "text"

  showInstructions: boolean = false

  showFilePreview: boolean = false
  file: NzUploadFile = null

  inputValue?: string
  options: string[] = []
  filteredOptions: string[] = []

  constructor(
    private afStorage: AngularFireStorage,
    private fmService: FileManagerService,
    public roleService: RoleService
  ) {
  }

  ngOnInit():void {
    this.fileList.forEach(file => this.options.push(file.name))
  }

  /*
    upload(event: Event): void {
      console.log(event)
      this.afStorage.upload("/test-path", event.target["files"][0])
      const randomId = Math.random().toString(36).substring(2)
      this.ref = this.afStorage.ref(randomId)
      this.task = this.ref.put(event.target["files"][0])


      this.task.snapshotChanges().pipe(map(snap => {
        this.downloadURL = ""
        console.log(snap)
      }))
    }
  */

  beforeUpload: (file: NzUploadFile, fileList: NzUploadFile[]) => boolean | Observable<boolean> = (file, fileList) => {
    if (this.fileList.find(item => item.name === file.name) || this.uploadFileList.find(item => item.name === file.name)) {
      alert("This filename already exists. We don't allow overwriting existing images. Please rename the image and try again.")
      return false
    }
  }

  handleUpload: (item: NzUploadXHRArgs) => Subscription = (item) => {
    // const exists = this.fileList.find(file => file.name === item.file.name)
    // if (exists) {
    //   alert("This filename already exists. We don't all overwriting existing images. Please rename the image and try again.")
    //   item.onError("", item.file)
    //   return
    // }
    this.task = this.afStorage
      .upload(this.directory + "/" + item.file.name, item.file)

    this.task
      .percentageChanges()
      .subscribe(percent => {
        this.uploadProgress.percent = percent
        item.onProgress(this.uploadProgress, item.file)
      })

    return this.task
      .snapshotChanges()
      .subscribe(snap => {
        if (snap) {
          if (snap.state === "success") {
            item.onSuccess("", item.file, "")
            this.uploadFileList[this.uploadFileList.length - 1].url = this.fmService.domain + "/" + this.directory + "/" + item.file.name
          }
          // console.log(snap)
          // console.log(this.fmService.fileList)
        }
      })
  }

  handleRemove: (file: NzUploadFile) => boolean | Observable<boolean> = (file) => {
    this.afStorage.refFromURL(file.url).delete()
    // console.log(file)
    // alert("delete is not enabled")
    return true
  }

  fileDelete(event: Event, file: NzUploadFile, index: number): void {
    if (confirm("please confirm to delete")) {
      this.afStorage.refFromURL(file.url).delete()
      this.fileList.splice(index, 1)
    }
    event.preventDefault()
    event.stopPropagation()
  }

  handlePreview: (file: NzUploadFile) => void = (file) => {
    // console.log(file)
    this.file = file
    this.showFilePreview = true
  }

  handleInstructions(): void {
    this.showInstructions = false
  }

  handleFilePreview(): void {
    this.showFilePreview = false
    this.file = null
  }

  onChange(value: string): void {
    this.filteredOptions = this.options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    if (this.fileList.find(file => file.name === value)) {
      this.file = this.fileList.find(file => file.name === value)
      this.showFilePreview = true
    }
  }

}
