import { Component, Input } from "@angular/core"

@Component({
  selector: "ava-battery-quoter-custom-element",
  template: `
    <ava-battery-quoter [params]="params"></ava-battery-quoter>
  `
})
export class BatteryQuoterCustomElementComponent {
  @Input() params: string
}
