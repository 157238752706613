import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HiddenTitleCustomElementComponent } from "./component"
import { HiddenTitleModule } from "@aaa-web/app/modules/hidden-title/hidden-title.module"

@NgModule({
  exports: [],
  declarations: [
    HiddenTitleCustomElementComponent,
  ],
  imports: [
    CommonModule,
    HiddenTitleModule,
  ]
})
export class HiddenTitleCustomElementModule {
}
