import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { BatteryQuoterCustomElementComponent } from "@aaa-web/app/custom-elements/battery-quoter/component"
import { BatteryQuoterModule } from "@aaa-web/app/modules/battery-quoter/battery-quoter.module"

@NgModule({
  exports: [],
  declarations: [
    BatteryQuoterCustomElementComponent,
  ],
  imports: [
    CommonModule,
    BatteryQuoterModule,
  ]
})
export class BatteryQuoterCustomElementModule {
}
