import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { CustomOption, QuillEditorComponent, QuillModules, QuillToolbarConfig } from "ngx-quill"
import Quill from "quill"
import { ColorType } from "@aaa-web/app/modules/multiblock/services/color.service"
import { AlignmentType } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { FieldType } from "@aaa-web/app/modules/multiblock/services/field.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"

export interface QuillEditor {
  blockRef: string
  heading: string
  type: FieldType
  value: string
}

export interface QuillEditorOption {
}

export type QuillEditorToolbar = QuillToolbarConfig

export interface QuillEditorOptions {
  newForm: (item: QuillEditor) => AbstractControl
  newItem: QuillEditor
  options: QuillEditorOption[]
  toolbarOptions?: {
    buttons?: string[]
    size?: {
      import: string
      whitelist: string[]
    }
  }
  formArray?: {
    min: number
    max: number
  }
  label: {
    placeholder: string
    plural?: string,
    singular: string
  }
}

/*
export interface ToolbarOptions {
  [key: string]: {
    import: string
    whitelist: (string | boolean)[]
  }
}
*/

export interface QuillEditorStyles {
  color?: ColorType["hex"] | ColorType["rgba"]
  fontFamily?: "proxima_nova, sans-serif"
  fontSize?: {
    px: number
  }
  fontWeight?: number
  gridTemplateColumns?: string
  lineHeight?: number
  marginTop?: { px: number }
  marginRight?: { px: number }
  marginBottom?: { px: number }
  marginLeft?: { px: number }
  paddingTop?: { px: number }
  paddingRight?: { px: number }
  paddingBottom?: { px: number }
  paddingLeft?: { px: number }
  textAlign?: AlignmentType["textAlign"]
}

@Injectable({
  providedIn: "root"
})
export class QuillService {

  constructor(
    private formService: FormService,
  ) {
  }

  get options(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newForm,
      newItem: this.newItem,
      options: [],
      toolbarOptions: {
        buttons: this.buttonOptions
      },
      label: {
        placeholder: "Enter Text Here",
        singular: "Text Area"
      },
      formArray: {
        max: 1,
        min: 0
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({})
    }
    return options
  }

  get headingOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newForm,
      newItem: this.newItem,
      options: [],
      toolbarOptions: {
        buttons: [
          // "undo",
          // "redo",
          "size",
          "header",
          "bold",
          "italic",
          "underline",
        ]
      },
      label: {
        placeholder: "Enter Text Here",
        singular: "Text Area"
      },
      formArray: {
        max: 1,
        min: 0
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({})
    }
    return options
  }

  get newItem(): QuillEditor {
    return {
      blockRef: null,
      heading: null,
      type: "quillEditor",
      value: "",
    }
  }

  newForm = (item: QuillEditor): AbstractControl => {
    return this.formService.newForm(item, this.newItem)
  }

  get customOptions(): CustomOption[] {
    /**
     * TODO: learn why we have to add import and whitelist here when they are also added in modules.toolbar
     */
    return [{
      import: "attributors/style/size",
      whitelist: [false, "13px", "17px", "24px", "32px", "44px", "60px"]
    }]
  }

  modules(options: QuillEditorOptions, component: string): QuillModules {
    const modules = {
      history: {
        userOnly: true
      },
      toolbar: {
        container: this.toolbar(options),
        handlers: {
          "undo": () => undefined,
          "redo": () => undefined,
        }
      }
    }

    if (modules.toolbar.container.find(array => array[0] === "emoji")) {
      modules["emoji-shortname"] = true
      modules["emoji-textarea"] = component === "quill-form-quill-inline"
      modules["emoji-toolbar"] = true
    }
    return modules
  }

  onEditorCreated(quillEditorComponent: QuillEditorComponent, type?: string): void {
    if (type === "heading") {

    }
    else {
      Quill.register(Quill.import("attributors/style/align"), true)
      Quill.register(Quill.import("attributors/style/background"), true)
      Quill.register(Quill.import("attributors/style/color"), true)
      Quill.register(Quill.import("attributors/style/direction"), true)
      Quill.register(Quill.import("attributors/style/font"), true)
      Quill.register(Quill.import("attributors/style/size"), true)

      /**
       * replace default <strong> with <b> for bolded text
       * TODO: replace Bold with a font-weight selector
       */
      const Bold = Quill.import("formats/bold")
      Bold.tagName = "b"
      Quill.register(Bold, true)

      /**
       * add handlers to toolbar placeholders, now that we have the quillEditorComponent reference
       */
      const editor = quillEditorComponent.quillEditor
      const element = quillEditorComponent.elementRef.nativeElement
      const toolbar = editor.getModule("toolbar")
      toolbar.addHandler("undo", function () {
        editor["history"].undo()
      })
      toolbar.addHandler("redo", function () {
        editor["history"].redo()
      })

      /**
       * insert the missing toolbar icons
       */
      for (const undoElement of element.getElementsByClassName("ql-undo")) {
        undoElement.innerHTML = this.undoIcon
      }
      for (const redoElement of element.getElementsByClassName("ql-redo")) {
        redoElement.innerHTML = this.redoIcon
      }
    }
  }

  get redoIcon(): string {
    return `
    <svg height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M64,400h10.3l19.2-31.2c20.5-32.7,44.9-62.8,75.8-76.6c24.4-10.9,46.7-18.9,86.7-20V352l192-128L256,96v80.3   c-63,2.8-108.1,20.7-143.3,56.2c-52.3,52.7-48.7,119-48.7,135.7C64.1,377.1,64,389.9,64,400z"/>
      </g>
    </svg>
    `
  }

  get undoIcon(): string {
    return `
    <svg height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M447.9,368.2c0-16.8,3.6-83.1-48.7-135.7c-35.2-35.4-80.3-53.4-143.3-56.2V96L64,224l192,128v-79.8   c40,1.1,62.4,9.1,86.7,20c30.9,13.8,55.3,44,75.8,76.6l19.2,31.2H448C448,389.9,447.9,377.1,447.9,368.2z" />
      </g>
    </svg>
    `
  }

  /*
    get toolbarOptions(): ToolbarOptions {
      return {
        size: {
          import: "attributors/style/size",
          whitelist: [false, "13px", "17px", "24px", "32px", "44px", "60px"]
        }
      }
    }
  */

  get buttonOptions(): string[] {
    return [
      "undo",
      "redo",
      "size",
      "header",
      "bold",
      "italic",
      "underline",
      "align",
      "ordered-list",
      "bullet-list",
      "blockquote",
      "code-block",
      "subscript",
      "superscript",
      "indent",
      "link",
    ]
  }

  toolbar(options: QuillEditorOptions): QuillToolbarConfig {
    const buttons = options.toolbarOptions?.buttons || this.buttonOptions // catch missing toolbarOptions until all blocks are refactored
    const toolbar = []

    for (const button of buttons) {
      switch (button) {
        case "undo":
        case "redo":
        case "bold":
        case "underline":
        case "italic":
        case "blockquote":
        case "code-block":
        case "link":
          toolbar.push([button])
          break
        case "size":
          toolbar.push([{ "size": [false, "13px", "17px", "24px", "32px", "44px", "60px"] }])
          break
        case "header":
          toolbar.push([{ "header": [false, 2, 3, 4, 5, 6] }])
          break
        case "align":
          toolbar.push([{ "align": [] }])
          break
        case "ordered-list":
          toolbar.push([{ "list": "ordered" }])
          break
        case "bullet-list":
          toolbar.push([{ "list": "bullet" }])
          break
        case "subscript":
          toolbar.push([{ "script": "sub" }])
          break
        case "superscript":
          toolbar.push([{ "script": "super" }])
          break
        case "indent":
          toolbar.push([{ "indent": "-1" }])
          toolbar.push([{ "indent": "+1" }])
          break
      }
    }

    return toolbar


    /*
        return [
          ["undo", "redo"],
          [{ "size": this.toolbarOptions.size.whitelist }],  // custom dropdown
          // [{ 'font': [] }],
          [{ "header": [false, 2, 3, 4, 5, 6] }],

          // ["weight"],
          // ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ["bold", "italic", "underline"],        // toggled buttons
          [{ "align": [] }],
          // [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'checked' }],
          [{ "list": "ordered" }, { "list": "bullet" }],
          ["blockquote", "code-block"],
          [{ "script": "sub" }, { "script": "super" }],
          // [{ "indent": "-1" }, { "indent": "+1" }],
          // ["emoji"],
          // ["padding"],
          // ['clean'],
          // ['image'],
          ["link"],
          // ['video'],
          // ['color'],
          // ['formula'],

          // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          // [{ 'header': 1 }, { 'header': 2 }],
        ]
    */
  }

}
