import * as firebase from "firebase-admin"

/*
    Azure Connector API
    http method -> POST
    send <{foo}Params extends AzureConnectorParams> (and set method = {foo})
    get <{foo}Response extends AzureConnectorResponse> or ErrorResponse
*/
export enum Method {
  AVAILABILITY_REFRESH = "AVAILABILITY_REFRESH",
  APPOINTMENT_ADD = "APPOINTMENT_ADD",
  APPOINTMENT_DELETE = "APPOINTMENT_DELETE",
  AGENT_HOURS_UPDATE = "AGENT_HOURS_UPDATE",
  STATUS_AZURE_APP = "STATUS_AZURE_APP",
  GARBAGE_COLLECT = "GARBAGE_COLLECT",
  TEST = "TEST"
}

export interface AzureConnectorParams { //abstract - don't send
  method: Method;
  clubId: string;
  context?: Context;
  overrideAgentEmail?: string;
}

export interface AzureConnectorResponse { //abstract - won't get
  status: number; //200 = ok
  version: string;
  context?: Context;
}

export interface Context {
  id: string;
  userId?: string;
  clubId?: string;
}

export interface ErrorResponse extends AzureConnectorResponse {
  error: any;
}

// method.AVAILABILITY_REFRESH
export interface AvailabilityRefreshParams extends AzureConnectorParams {
  tenantId: string;
  agentEmails?: string[];
  ignoreRateLimit?: boolean;
  async?: boolean; //future
}

export interface AvailabilityRefreshResponse extends AzureConnectorResponse {
  message: string;
  documentPaths: DocumentPath[];
}

// method.APPOINTMENT_ADD
export interface AppointmentAddParams extends AzureConnectorParams {
  tenantId: string;
  agentEmail: string;
  // details: BookingDetails;
  details: AppointmentDetails;

}

export interface AppointmentDetails {
  timezone: string;
  timeSlot: AgentTimeSlot;
  duration: number;
  agentInfo: {
    firstName: string,
    lastName: string,
    phone: string,
    calEventLink?: string, //only used internally by function
    email?: string //only used internally by function
  };
  agentType: AppMode,
  appURL?: string,
  created?: firebase.firestore.Timestamp,
  canceled?: firebase.firestore.Timestamp,
  topics?: string[],
  region?: string,
  meeting?: string,
  meetingType?: "online" | "phone" | "inPerson",
  userInfo?: {
    firstName?: string,
    lastName?: string,
    email?: string,
    memberNumber?: string,
    phone?: string,
    departure?: string,
    comment?: string,
  },
  location?: {
    name: string,
    addrLine1: string,
    addrLine2: string,
    geoLoc: string,
  },
  shareWith?: string[],
  onlineMeetingLink?: string,
  appointmentId?: string,
  promo?: string
}

export interface AppointmentAddResponse extends AzureConnectorResponse {
  appointmentId: string;
  message: string;
}

// method.APPOINTMENT_DELETE
export interface AppointmentDeleteParams extends AzureConnectorParams {
  tenantId: string;
  appointmentId: string,
}

export interface AppointmentDeleteResponse extends AzureConnectorResponse {
  message: string;
}

// method.AGENT_HOURS_UPDATE
export interface AgentHoursUpdateParams extends AzureConnectorParams {
  agentWorkHours: AgentWorkHours;
}

export interface AgentHoursUpdateResponse extends AzureConnectorResponse {
  message: string;
  documentPath: DocumentPath;
}

// method.STATUS_AZURE_APP
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StatusAzureAppParams extends AzureConnectorParams {
}

/*
  Agent Work Hours
  -> Drupal, future: GCP+dashboard
*/
export interface AgentWorkHours {
  agentEmail: string;
  timezone: string;
  source?: AgentWorkHoursSource;
  hours: {
    weekday: number; // 0 = Sunday
    ranges: {
      startHour: number;
      startMinute: number;
      endHour: number;
      endMinute: number;
    }[];
  }[];
}

export enum AgentWorkHoursSource {
  OUTLOOK = "OUTLOOK",
  GCP = "GCP"
}

/*
  Agent Availability
  -> GCP Document Struct
*/
export interface AgentAvailability {
  lastUpdated: firebase.firestore.Timestamp;
  agentEmail: string;
  timezone: string;
  timeSlots: AgentTimeSlot[];
}

/*
  Shared Interfaces
*/
export interface AgentTimeSlot {
  date: {
    day: number
    month: number
    year: number
  }
  time: {
    hour: number
    minute: number
  }
  bookingInProgress?: BookingInProgress
}

export function agentTimeSlotToDate(agentTimeSlot: AgentTimeSlot): Date {
  if (!agentTimeSlot) return null
  return new Date(
    agentTimeSlot.date.year,
    agentTimeSlot.date.month - 1,
    agentTimeSlot.date.day,
    agentTimeSlot.time.hour,
    agentTimeSlot.time.minute,
    0,
    0
  )
}

export interface IndexEntry {
  docId: string;
  agentEmail?: string;
  id?: string;
  deleted?: boolean
}

export interface DocumentPath {
  agentEmail: string;
  documentPath: string;
  documentId: string;
  documentType: "availability" | "workHours";
  status?: number;
  message?: string;
}

export interface BookingInProgress {
  context: Context;
  duration: number;
  started: FirebaseFirestore.Timestamp;
}

export enum AppMode {
  TRAVEL = "TRAVEL",
  INSURANCE = "INSURANCE"
}

export interface CFMObj {
  selectedTopics: string[]
  region: string
  meeting: string
  timeSlot: string
  appointmentId: string
}

export interface Overrides {
  promo: string
  agentEmail: string
  cfm: boolean
  appointmentId: string
}

export interface BookingDetails {
  timeSlot: AgentTimeSlot
  timezone: string
  duration: number
  agentType: AppMode
  agentInfo: {
    firstName: string
    lastName: string
    phone: string
  },
  topics: string[]
  region: string
  meeting: string
  meetingType: "online" | "phone" | "inPerson"
  userInfo: {
    firstName: string
    lastName: string
    email: string
    memberNumber: string
    phone: string
    departure: string
    comment: string
  },
  location: Office
  shareWith: string[]
  appURL: string
  promo: string
  created?: firebase.firestore.Timestamp
  canceled?: firebase.firestore.Timestamp
}

export interface MetaUser {
  memberNumber?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  token?: string
  userId: string
}

export interface Meta {
  clubId: string
  sessionId: string
  user: MetaUser,
  tenantId: string,
  imagesURL: string,
  userGeoLoc: string,
  timezone: string,
  startAtAgent?: string,
}

export interface Agent {
  fName: string
  lName: string
  email: string
  about: string
  phone: string
  image: string

  officeIds: string[]
  topicIds: string[]
  regionIds: string[]
  meetingIds: string[]
  typeIds: string[]
  shareWithIds: string[]

  fullName?: string
  type?: string
  specialties?: string[]
}

export interface Filter {
  id: string
  default?: boolean
}

export interface Topic extends Filter {
  text: string
  icon: string
}

export interface Region extends Filter {
  response: string
  text: string
}

export interface Meeting extends Filter {
  text: string
  type: "online" | "phone" | "inPerson"
}

export interface Office extends Filter {
  name: string
  addrLine1: string
  addrLine2: string
  geoLoc: string
}

export interface AgentType extends Filter {
  text: string
}

export interface ShareWith extends Filter {
  email: string
}

export interface Params {
  meta: Meta
  agents: Agent[]
  topics: Topic[]
  regions: Region[]
  meetings: Meeting[]
  offices: Office[]
  types: AgentType[]
  shareWith: ShareWith[]
}

export interface FormValues {
  appMode: AppMode
  overrides: Overrides
  meta: Meta
  metaUser: MetaUser
  agents: Agent[]
  topics: Topic[]
  regions: Region[]
  meetings: Meeting[]
  offices: Office[]
  types: AgentType[]
  shareWith: ShareWith[]
  selectedTopicIds: string[]
  selectedRegionId: string
  selectedMeetingId: string
}

export interface Step {
  title: string,
  percent: number,
  step: number,
  states: string[],
  event?: string | "JUMP_TYPE" | "JUMP_AGENT" | "JUMP_TIME" | "RESTART" | "NEXT" | "PREVIOUS" | "TOPIC_SELECT_TOPIC" | "TOPIC_DESELECT_TOPIC" | "AGENTSLIST_PREVIEW" | "AGENTSLIST_PREVIEW_CLOSE" | "LOAD_AGENT_LOADED" | "LOAD_AGENT_ERROR" | "APPOINTMENT_ADD_COMPLETE"
}

export interface AgentOffice {
  office: Office
  agents: Agent[]
}
