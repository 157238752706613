import { Injectable } from "@angular/core"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"

export interface ColumnWidth {
  width: {
    percent: number
    px: number
  }
}

export interface ColumnWidthOptions {
  formArray: FormArrayOptions
  newForm: (item: ColumnWidth) => AbstractControl
  newItem: ColumnWidth
  label: {
    plural: string
    singular: string
    title: string
  }
  triggers: {
    action: string
    field?: (string | number)[]
    label?: string
    quantity?: number
  }[]


}

export interface ColumnWidths {
  column1Width: number
  column2Width: number
  column3Width: number
  column4Width: number
  column5Width: number
  column6Width: number
  column7Width: number
  column8Width: number
  contentWidth: number
}

@Injectable({
  providedIn: "root"
})
export class ColumnWidthService {

  get newItem(): ColumnWidth {
    return {
      width: {
        percent: 100,
        px: null
      }
    }
  }

  newForm = (item: ColumnWidth): FormGroup => {
    return new FormGroup({
      width: new FormGroup({
        percent: new FormControl(item.width.percent),
        px: new FormControl(item.width.px)
      })
    })
  }

  get options(): ColumnWidthOptions {
    return {
      formArray: {
        max: 4,
        min: 1
      },
      label: {
        plural: "Columns",
        singular: "Column",
        title: "Column Widths"
      },
      newForm: this.newForm,
      newItem: this.newItem,
      triggers: []
    }
  }

  /**
   * helper methods
   */

  resetColumnWidths(columnWidths: ColumnWidth[]): ColumnWidth[] {
    const newColumnWidths: ColumnWidth[] = []
    for (const index in columnWidths) {
      newColumnWidths[index] = { width: { percent: Math.floor(100000 / columnWidths.length) / 1000, px: 0 } }
    }
    return newColumnWidths
  }

}
