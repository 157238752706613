import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input,
  OnDestroy, OnInit, ViewChild, ViewContainerRef
} from "@angular/core"
import { Subscription } from "rxjs"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BuildService } from "@aaa-web/app/modules/multiblock/services/build.service"
import { RevisionService } from "@aaa-web/app/modules/multiblock/services/revision.service"
import {
  BlockStateBase, BlockStateBase as BlockState, StateService
} from "@aaa-web/app/modules/multiblock/services/state.service"

@Component({
  selector: "ava-page-view",
  templateUrl: "./view.html"
})
export class PageViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() blockId: string
  block: Multiblock
  @ViewChild("containerElement") containerElementRef: ElementRef
  @ViewChild("blockContainer", { read: ViewContainerRef }) blockContainerRef
  @ViewChild("toolbarContainer", { read: ViewContainerRef }) toolbarContainerRef
  private initializedBlock: boolean
  private initializedListener: boolean
  private initializedToolbar: boolean
  private subscription: { [key: string]: Subscription } = {}
  previewSize: number

  constructor(
    private buildService: BuildService,
    private dataService: DataService,
    private formService: FormService,
    private revisionService: RevisionService,
    private roleService: RoleService,
    private stateService: StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private domWindow: Window,
  ) {
  }

  ngOnInit(): void {
    this.block = this.dataService.blocks[this.blockId]
  }

  ngAfterViewInit(): void {
    this.subscription["blocks"] = this.dataService.blocks$
      .subscribe(blocks => {
        if (blocks) {
          this.block = blocks[this.blockId]
          if (this.block) {
            // console.log(this.block)
            this.stateService.updateState(this.blockId, { showBlock: this.showBlock })
            /**
             * Create block container component when block is initially loaded.
             */
            if (!this.initializedBlock) {
              this.initializedBlock = true
              this.buildService.createBlockView(this.blockContainerRef, this.block)

              this.subscription["roles"] = this.roleService.roles$
                .subscribe(roles => {
                  if (roles.administrator && this.blockId) {
                    /**
                     * Initialize form and revision listeners when user is an admin.
                     */
                    if (this.block && !this.initializedListener) {
                      this.initializedListener = true
                      this.revisionService.setupListeners(this.blockId)
                    }

                    /**
                     * Initialize toolbar first time adminMode is enabled.
                     */
                    this.subscription["adminMode"] = this.stateService.adminMode$
                      .subscribe(adminMode => {
                        this.changeDetectorRef.detectChanges()
                        this.onResize()
                        if (adminMode && !this.initializedToolbar) {
                          this.initializedToolbar = true
                          this.formService.loadToolbar(this.toolbarContainerRef, this.block, this.showEditModal)
                        }
                      })

                    /**
                     * previewMode listener
                     */
                    this.subscription["previewMode"] = this.stateService.previewMode$
                      .subscribe(() => {
                        this.changeDetectorRef.detectChanges()
                        this.onResize()
                      })

                    /**
                     * previewSize listener
                     */
                    this.subscription["previewSize"] = this.stateService.previewSize$
                      .subscribe(size => {
                        this.previewSize = size
                        this.changeDetectorRef.detectChanges()
                        this.onResize()
                      })

                  }
                })
            }
          }
        }
      })
    this.onResize()
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.onResize()
  // }

  ngOnDestroy(): void {
    this.blockContainerRef?.clear()
    for (const key in this.subscription) {
      this.subscription[key].unsubscribe()
    }
    if (this.blockId) {
      this.revisionService.destroyListeners(this.blockId)
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    const width = this.containerElementRef?.nativeElement.offsetWidth
    if (width && width !== this.state?.width) {
      this.stateService.updateState(this.blockId, { width: width })
    }
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  get state(): BlockState {
    return this.stateService.state[this.blockId]
  }

  get adminMode(): boolean {
    return this.stateService.adminMode
  }

  get previewMode(): boolean {
    return this.stateService.previewMode
  }

  get pageWidth(): number {
    return this.stateService.state[this.dataService.pageBlock.id]?.width ?? this.window.screen.width
  }

  blockState = (): BlockStateBase => {
    return {
      deleting: this.state.form?.value.status.deleting,
      form: this.state.form,
      passwordAuth: this.state.passwordAuth,
      personalize: this.block?.status.personalize,
      personalized: this.block?.status.personalized,
      preSaved: this.block?.status.preSaved,
      protected: this.block?.status.protected,
      selected: this.state.selected,
      unpublished: this.block?.status.unpublished,
    }
  }

  get blockStyles(): { margin: string | number, width: { px: number } } {
    return {
      margin: this.adminMode && this.previewMode && this.previewSize && this.pageWidth > this.previewSize ? "0 auto" : 0,
      width: {
        px: this.adminMode && this.previewMode && this.previewSize && this.pageWidth > this.previewSize ? this.previewSize : undefined
      }
    }
  }

  showToolbar(): void {
    if (this.stateService.adminMode && !this.state.selected) {
      this.stateService.updateState(this.blockId, { selected: true })
    }
  }

  hideToolbar(): void {
    if (this.stateService.adminMode && !this.state.modified) {
      this.stateService.updateState(this.blockId, { selected: false })
    }
  }

  showBlock = (): boolean => {
    if (this.block) {
      return this.stateService.showBlock(this.blockState())
    }
    return false
  }

  pointerUp = (event: Event): void => {
    if (this.state.selectedField) {
      event.preventDefault()
    }
  }

  pointerDown = (): void => {
    if (this.state.selectedField) {
      this.stateService.updateState(this.blockId, { selectedField: undefined })
      return
    }
    if (!this.previewMode) {
      this.formService.showEditModal(this.block, this.state.modalRef, null)
    }
  }

  showEditModal = (event?: Event, index?: number): void => {
    if (this.state.selectedField) {
      this.stateService.updateState(this.blockId, { selectedField: undefined })
    }
    this.formService.showEditModal(this.block, this.state.modalRef, index)
  }

}
