import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { FormArrayOptions, FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { TargetOption } from "@aaa-web/app/modules/multiblock/services/style.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"
import { FieldType } from "@aaa-web/app/modules/multiblock/services/field.service"

export interface Image {
  altText: string
  blockRef: string
  filePath: string
  fileType: "image/png" | "image/jpeg" | "image/gif" | ""
  targetOption: TargetOption
  targetPath: string
  type: FieldType
}

export interface ImageOption {
  description: string
  dimensions: string
  minWidth: {
    px: number
  }
  maxWidth: {
    px: number
  }
  minHeight: {
    px: number
  }
  maxHeight: {
    px: number
  }
  target: TargetOption[]
}

interface ThisFormArrayOptions extends FormArrayOptions {
  alt: boolean
  cropper: boolean
}

export interface ImageOptions {
  options: ImageOption[]
  formArray?: ThisFormArrayOptions
  newForm: (item: Image) => AbstractControl
  newItem: Image
  label: {
    placeholder: string
    plural: string,
    singular: string
  }
}

export interface ImageStyles {
  // justifyContent: AlignmentType["justifyContent"]
}


@Injectable({
  providedIn: "root"
})
export class ImageService {

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Image {
    return {
      altText: "image",
      blockRef: null,
      filePath: "",
      fileType: "",
      targetOption: "self",
      targetPath: "",
      type: "image",
    }
  }

  newForm = (item: Image): AbstractControl => {
    return this.formService.newForm(item as unknown as Multiblock, this.newItem as unknown as Multiblock)
  }

  get options(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "",
        plural: "Images",
        singular: "Image"
      },
      formArray: {
        alt: true,
        cropper: true,
        max: 1,
        min: 0,
      },
      newForm: this.newForm,
      newItem: this.newItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        description: "900x600",
        dimensions: "900x600",
        minWidth: {
          px: 750
        },
        maxWidth: {
          px: 750
        },
        minHeight: {
          px: 500
        },
        maxHeight: {
          px: 500
        },
        target: ["self", "modal", "new"]
      })
    }
    return options
  }

}
