import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ReactiveFormsModule } from "@angular/forms"
import { QuillModule } from "ngx-quill";
import { MultiblockComponent } from "./multiblock.component"
import { MultiblockViewModule } from "./multiblock-view.module"

import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzDrawerModule } from "ng-zorro-antd/drawer"

@NgModule({
  declarations: [
    MultiblockComponent,
  ],
  imports: [
    CommonModule,
    MultiblockViewModule,
    NzToolTipModule,
    QuillModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzRadioModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzDrawerModule,
  ],
  exports: [
    MultiblockComponent,
  ]
})
export class MultiblockModule {
}
