import { Injectable } from "@angular/core"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { Block as TitleBlock, TitleService } from "../blocks/title/service"
import {
  Block as PageBlock, PageService, Option as PageOption, Types as PageTypes
} from "../containers/page/service"
import { Block as OneColTextBlock, OneColTextService } from "../blocks/one-col-text/service"
import { Block as UnrestrictedBlock, UnrestrictedService } from "../blocks/unrestricted/service"
import { Block as PasswordBlock, PasswordService } from "../blocks/password/service"
import { Block as LogiformBlock, LogiformService } from "../blocks/logiform/service"
import { Block as OfficeAgentsBlock, OfficeAgentsService } from "../blocks/office-agents/service"
import { Block as BannerBlock, BannerService } from "../blocks/banner/service"
import { BannerLargeService } from "../blocks/banner-large/service"
import { Block as CallToActionBlock, CallToActionService } from "../blocks/call-to-action/service"
import { Block as JoinBlock, JoinService } from "../blocks/join/service"
import { Block as ComparisonChartBlock, ComparisonChartService } from "../blocks/comparison-chart/service"
import { Block as OfficeBlock, OfficeService } from "../blocks/office/service"
import { Personalize } from "../options/personalize/service"
import { FormArrayOptions } from "../services/form.service"
import { FeatureService } from "../blocks/feature/service"
import { Block as Column, ColumnService } from "../containers/column/service"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

export type BlockOption =
  "banner"
  | "bannerLarge"
  | "callToAction"
  | "card"
  | "column"
  | "comparisonChart"
  | "container"
  | "feature"
  | "html"
  | "logiform"
  | "office"
  | "officeAgents"
  | "oneColText"
  | "page"
  | "password"
  | "title"
  | "unrestricted"
  | "join"

export type BlockTypes = Record<BlockOption, BlockType>

export interface BlockType {
  component: {
    path: string
  }
  label: {
    plural: string,
    singular: string
  }
}

export interface BlockOptions {
  label: {
    plural: string,
    singular: string
  }
}

export type Multiblock =
  | BannerBlock
  | CallToActionBlock
  | Column
  | ComparisonChartBlock
  | LogiformBlock
  | OfficeBlock
  | OfficeAgentsBlock
  | OneColTextBlock
  | PageBlock
  | PasswordBlock
  | TitleBlock
  | UnrestrictedBlock
  | JoinBlock

export interface BlockBase {
  blockType: BlockOption
  id?: string
  pathName: string
  status: {
    created: Timestamp
    deleting: boolean
    personalize: Personalize
    personalized: boolean
    preSaved: boolean
    protected: boolean
    revised: Timestamp
    session: string
    unpublished: boolean
  }
}

export interface OptionsBase {
  formArray: FormArrayOptions
  label: {
    placeholder: string
    plural: string,
    singular: string
  }
}

@Injectable({
  providedIn: "root"
})
export class BlockService {

  constructor(
    private bannerService: BannerService,
    private bannerLargeService: BannerLargeService,
    private callToActionService: CallToActionService,
    private columnService: ColumnService,
    private comparisonChartService: ComparisonChartService,
    private featureService: FeatureService,
    private joinService: JoinService,
    private logiformService: LogiformService,
    private officeAgentsService: OfficeAgentsService,
    private officeService: OfficeService,
    private oneColTextService: OneColTextService,
    private pageService: PageService,
    private passwordService: PasswordService,
    private titleService: TitleService,
    private unrestrictedService: UnrestrictedService,
    private domWindow: Window,
  ) {
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  get blockOptions(): BlockOption[] {
    // return Object.keys(this.blockTypes) as BlockOption[]
    // return ["banner", "title", "password", "html", "logiform", "officeAgents", "oneColText"]
    const blockOptions = []
    blockOptions.push("banner")
    blockOptions.push("bannerLarge")
    blockOptions.push("callToAction")
    if (this.window.metaData.user.id === "1") {
      blockOptions.push("column")
    }
    blockOptions.push("comparisonChart")
    blockOptions.push("feature")
    blockOptions.push("join")
    blockOptions.push("logiform")
    blockOptions.push("office")
    blockOptions.push("officeAgents")
    blockOptions.push("oneColText")
    blockOptions.push("password")
    blockOptions.push("title")

    return blockOptions
  }

  get blockTypes(): BlockTypes {
    return {
      banner: {
        component: {
          path: "multiblock/blocks/banner"
        },
        label: {
          plural: this.bannerService.blockOptions.label.singular,
          singular: this.bannerService.blockOptions.label.singular
        }
      },
      bannerLarge: {
        component: {
          path: "multiblock/blocks/banner-large"
        },
        label: {
          plural: this.bannerLargeService.blockOptions.label.singular,
          singular: this.bannerLargeService.blockOptions.label.singular
        }
      },
      callToAction: {
        component: {
          path: "multiblock/blocks/call-to-action"
        },
        label: {
          plural: this.callToActionService.blockOptions.label.singular,
          singular: this.callToActionService.blockOptions.label.singular
        }
      },
      card: {
        component: {
          path: "multiblock/blocks/card"
        },
        label: {
          plural: "",
          singular: ""
        }
      },
      column: {
        component: {
          path: "multiblock/containers/column"
        },
        label: {
          plural: this.columnService.blockOptions.label.singular,
          singular: this.columnService.blockOptions.label.singular
        }
      },
      comparisonChart: {
        component: {
          path: "multiblock/blocks/comparison-chart"
        },
        label: {
          plural: this.comparisonChartService.blockOptions.label.singular,
          singular: this.comparisonChartService.blockOptions.label.singular
        }
      },
      container: {
        component: {
          path: "multiblock/container-blocks/block"
        },
        label: {
          plural: "",
          singular: ""
        }
      },
      feature: {
        component: {
          path: "multiblock/blocks/feature"
        },
        label: {
          plural: this.featureService.blockOptions.label.singular,
          singular: this.featureService.blockOptions.label.singular
        }
      },
      html: {
        component: {
          path: "multiblock/blocks/html"
        },
        label: {
          plural: "",
          singular: ""
        }
      },
      join: {
        component: {
          path: "multiblock/blocks/join"
        },
        label: {
          plural: this.joinService.blockOptions.label.singular,
          singular: this.joinService.blockOptions.label.singular
        }
      },
      logiform: {
        component: {
          path: "multiblock/blocks/logiform"
        },
        label: {
          plural: this.logiformService.blockOptions.label.singular,
          singular: this.logiformService.blockOptions.label.singular
        }
      },
      office: {
        component: {
          path: "multiblock/blocks/office"
        },
        label: {
          plural: this.officeService.blockOptions.label.singular,
          singular: this.officeService.blockOptions.label.singular
        }
      },
      officeAgents: {
        component: {
          path: "multiblock/blocks/office-agents"
        },
        label: {
          plural: this.officeAgentsService.blockOptions.label.singular,
          singular: this.officeAgentsService.blockOptions.label.singular
        }
      },
      oneColText: {
        component: {
          path: "multiblock/blocks/one-col-text"
        },
        label: {
          plural: this.oneColTextService.blockOptions.label.singular,
          singular: this.oneColTextService.blockOptions.label.singular
        }
      },
      page: {
        component: {
          path: "multiblock/container-blocks/page"
        },
        label: {
          plural: "",
          singular: ""
        }
      },
      password: {
        component: {
          path: "multiblock/blocks/password"
        },
        label: {
          plural: this.passwordService.blockOptions.label.singular,
          singular: this.passwordService.blockOptions.label.singular
        }
      },
      title: {
        component: {
          path: "multiblock/blocks/title"
        },
        label: {
          plural: this.titleService.blockOptions.label.singular,
          singular: this.titleService.blockOptions.label.singular
        }
      },
      unrestricted: {
        component: {
          path: "multiblock/blocks/unrestricted"
        },
        label: {
          plural: "",
          singular: ""
        }
      },
    }
  }

  get containerTypes(): PageTypes {
    return this.pageService.types
  }

  get containerBlockTypes(): PageTypes {
    return this.pageService.blockTypes
  }

  newBlock(blockOption: BlockOption, pageOption?: PageOption): Multiblock {
    if (blockOption === "container" && pageOption === "page") {
      return this.pageService.newBlock
    }
    if (blockOption === "banner") {
      return this.bannerService.newBlock
    }
    if (blockOption === "bannerLarge") {
      return this.bannerLargeService.newBlock
    }
    if (blockOption === "callToAction") {
      return this.callToActionService.newBlock
    }
    if (blockOption === "column") {
      return this.columnService.newBlock
    }
    if (blockOption === "comparisonChart") {
      return this.comparisonChartService.newBlock
    }
    if (blockOption === "feature") {
      return this.featureService.newBlock
    }
    if (blockOption === "join") {
      return this.joinService.newBlock
    }
    if (blockOption === "logiform") {
      return this.logiformService.newBlock
    }
    if (blockOption === "officeAgents") {
      return this.officeAgentsService.newBlock
    }
    if (blockOption === "office") {
      return this.officeService.newBlock
    }
    if (blockOption === "oneColText") {
      return this.oneColTextService.newBlock
    }
    if (blockOption === "password") {
      return this.passwordService.newBlock
    }
    if (blockOption === "title") {
      return this.titleService.newBlock
    }
    if (blockOption === "unrestricted") {
      return this.unrestrictedService.newBlock
    }
  }

/*
  /!**
   * TODO: Before trying to build the "newForm" the block input should be validated to match the blockType data requirements
   *!/
  newBlockForm(block: Multiblock): BlockState {
    if (block.blockType === "container" && (block as PageBlock).containerType === "page") {
      return this.pageService.newForm(block as PageBlock)
    }
    if (block.blockType === "banner") {
      return this.bannerService.newForm(block as BannerBlock)
    }
    if (block.blockType === "comparisonChart") {
      return this.comparisonChartService.newForm(block as ComparisonChartBlock)
    }
    if (block.blockType === "html") {
      return this.htmlService.newForm(block as HTMLBlock)
    }
    if (block.blockType === "join") {
      return this.joinService.newForm(block as JoinBlock)
    }
    if (block.blockType === "logiform") {
      return this.logiformService.newForm(block as LogiformBlock)
    }
    if (block.blockType === "officeAgents") {
      return this.officeAgentsService.newForm(block as OfficeAgentsBlock)
    }
    if (block.blockType === "office") {
      return this.officeService.newForm(block as OfficeBlock)
    }
    if (block.blockType === "oneColText") {
      return this.oneColTextService.newForm(block as OneColTextBlock)
    }
    if (block.blockType === "page") {
      return this.pageService.newForm(block as PageBlock)
    }
    if (block.blockType === "password") {
      return this.passwordService.newForm(block as PasswordBlock)
    }
    if (block.blockType === "title") {
      return this.titleService.newForm(block as TitleBlock)
    }
    if (block.blockType === "unrestricted") {
      return this.unrestrictedService.newForm(block as UnrestrictedBlock)
    }
  }
*/

}
