import { Injectable } from "@angular/core"
import { ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { ImageOptions } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { VideoOptions } from "@aaa-web/app/modules/multiblock/fields/video/service"
import { AlignmentOptions } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { BannerService, Block } from "@aaa-web/app/modules/multiblock/blocks/banner/service"
import { JavascriptOptions } from "@aaa-web/app/modules/multiblock/fields/javascript/service"
import { ColumnWidthOptions } from "@aaa-web/app/modules/multiblock/options/column-width/service"
import { BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { QuillEditorOptions } from "@aaa-web/app/modules/multiblock/fields/quill/service"

@Injectable({
  providedIn: "root"
})
export class FeatureService extends BannerService {
  blockType: BlockOption = "feature"

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Features",
        singular: "Feature"
      }
    }
  }

  get bodiesOptions(): QuillEditorOptions {
    return this.quillService.options
  }

  get disclaimersOptions(): QuillEditorOptions {
    const options = this.quillService.options
    options.formArray.max = 1
    options.label = {
      placeholder: "disclaimer text",
      plural: "Disclaimers",
      singular: "Disclaimer"
    }
    options.toolbarOptions.buttons = ["bold", "italic", "underline", "align", "subscript", "superscript", "indent"]
    return options
  }

  get imagesOptions(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "",
        plural: "Images",
        singular: "Image"
      },
      formArray: {
        alt: true,
        cropper: true,
        min: 0,
        max: 1,
      },
      newForm: this.newImageForm,
      newItem: this.newImage,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        description: "900x600",
        dimensions: "900x600",
        minWidth: {
          px: 750
        },
        maxWidth: {
          px: 750
        },
        minHeight: {
          px: 500
        },
        maxHeight: {
          px: 500
        },
        target: ["self", "modal", "new"]
      })
    }
    return options
  }

  get videosOptions(): VideoOptions {
    const options: VideoOptions = {
      formArray: {
        max: 1,
        min: 0
      },
      label: {
        placeholder: "",
        plural: "Videos",
        singular: "Video"
      },
      newForm: this.newVideoForm,
      newItem: this.newVideoItem,
      options: []
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [0, 1],
          rows: []
        },
      })
    }
    return options
  }

  get javascriptsOptions(): JavascriptOptions {
    const options: JavascriptOptions = {
      options: [],
      newForm: this.newJavascriptForm,
      newItem: this.newJavascript,
      label: {
        placeholder: "javascript code",
        plural: "Logiforms",
        singular: "Logiform"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        background: {
          color: false, // changing to true will also need a formControl
          opacity: true
        },
        value: true
      })
    }
    return options
  }

  get columnWidthOptions(): ColumnWidthOptions {
    return {
      formArray: {
        max: 8,
        min: 1
      },
      label: {
        plural: "Columns",
        singular: "Column",
        title: "Column Widths"
      },
      newForm: this.newColumnWidthForm,
      newItem: this.newColumnWidthItem,
      triggers: [
        {
          action: "addItem()",
          field: ["fields", "images"],
          label: "image-addColumn",
          quantity: 1,
        },
        {
          action: "addItem()",
          field: ["fields", "videos"],
          label: "video-addColumn",
          quantity: 1,
        },
        {
          action: "addItem()",
          field: ["fields", "javascripts"],
          label: "javascript-addColumn",
          quantity: 1,
        },
      ]
    }
  }

  get alignmentOptions(): AlignmentOptions {
    return {
      options: ["left", "center"],
      label: {
        singular: "Alignment"
      }
    }
  }

  get themeOptions(): ThemeOptions {
    return {
      label: {
        singular: "Theme Color"
      },
      options: ["feature1", "feature2", "feature3"]
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        bodies: [this.newQuillItem],
        disclaimers: [],
        buttons: [],
        images: [],
        javascripts: [],
        videos: [],
      },
      options: {
        alignment: "left",
        boxModel: this.newBoxModelItem,
        columnWidths: [this.newColumnWidthItem],
        theme: "feature1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

}
