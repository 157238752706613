import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { FormArrayOptions, FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { ColorOption } from "@aaa-web/app/modules/multiblock/services/color.service"
import { FieldType } from "@aaa-web/app/modules/multiblock/services/field.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"

export interface Javascript {
  background: {
    color: ColorOption
    opacity: number
  }
  blockRef: string
  type: FieldType
  value: string
}

export interface JavascriptOption {
  background: {
    color: boolean
    opacity: boolean
  }
  value: true
}

export interface JavascriptOptions {
  options: JavascriptOption[]
  formArray: FormArrayOptions
  newForm: (item: Javascript) => AbstractControl
  newItem: Javascript
  label: {
    placeholder: string
    plural: string,
    singular: string
  }
}

export interface JavascriptStyles {
  backgroundColor: string
}

@Injectable({
  providedIn: "root"
})
export class JavascriptService {

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Javascript {
    return {
      background: {
        color: undefined,
        opacity: undefined
      },
      blockRef: null,
      type: "javascript",
      value: ""
    }
  }

  newForm = (item: Javascript): AbstractControl => {
    return this.formService.newForm(item as unknown as Multiblock, this.newItem as unknown as Multiblock)
  }

  get options(): JavascriptOptions {
    const options: JavascriptOptions = {
      options: [],
      newForm: this.newForm,
      newItem: this.newItem,
      label: {
        placeholder: "javascript code",
        plural: "Logiforms",
        singular: "Logiform"
      },
      formArray: {
        max: 1,
        min: 0,
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        background: {
          color: false, // changing to true will also need a formControl
          opacity: true
        },
        value: true
      })
    }
    return options
  }

}
