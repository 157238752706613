import { Component, Input } from "@angular/core"

@Component({
  selector: "ava-office-agents-custom-element",
  template: `
    <ava-office-agents [officeId]="officeId" [params]="params"></ava-office-agents>
  `
})
export class OfficeAgentsCustomElementComponent {
  @Input() officeId: string
  @Input() params: string
}
