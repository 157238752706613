<div>
  <button
    nz-button
    [nzType]="state.memberNumber ? 'default' : 'primary'"
    (click)="toggleState('memberNumber'); $event.preventDefault()"
    [style]="'margin: 5px'"
    *ngIf="roleService.roles['administrator']"
  >
    Member Number
  </button>
  <button
    nz-button
    [nzType]="state.v3Batch ? 'default' : 'primary'"
    (click)="toggleState('v3Batch'); $event.preventDefault()"
    [style]="'margin: 5px'"
    *ngIf="roleService.roles['administrator']"
  >
    V3 Batch
  </button>
  <button
    nz-button
    [nzType]="state.ememberBatch ? 'default' : 'primary'"
    (click)="toggleState('ememberBatch'); $event.preventDefault()"
    [style]="'margin: 5px'"
    *ngIf="roleService.roles['administrator']"
  >
    Emember Batch
  </button>
  <button
    nz-button
    [nzType]="state.ememberHealth ? 'default' : 'primary'"
    (click)="toggleState('ememberHealth'); $event.preventDefault()"
    [style]="'margin: 5px'"
    *ngIf="roleService.roles['administrator'] || roleService.roles['user-admin']"
  >
    Emember Health
  </button>
</div>

<ava-member-number
  *ngIf="state.memberNumber"
></ava-member-number>
<ava-v3-batch
  *ngIf="state.v3Batch"
></ava-v3-batch>
<ava-emember-batch
  *ngIf="state.ememberBatch"
></ava-emember-batch>
<ava-emember-health
  *ngIf="state.ememberHealth"
></ava-emember-health>

<ng-container *ngIf="roleService.roles['administrator']">
  <button
    nz-button [nzType]="'primary'"
    (click)="createBasicMessage(); $event.preventDefault()"
  >
    Display normal message
  </button>
  <button
    nz-button [nzType]="'primary'"
    (click)="createBasicNotification(); $event.preventDefault()"
  >
    Open the notification box
  </button>
</ng-container>
