import { Injectable } from "@angular/core"
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { Button, ButtonOptions, ButtonService } from "@aaa-web/app/modules/multiblock/fields/button/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import {
  BlockBase, BlockOption, BlockOptions, OptionsBase
} from "@aaa-web/app/modules/multiblock/services/block.service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    columns: Column[]
    comparisons: Comparison[]
    moreLabels: QuillEditor[]
    titles: QuillEditor[]
  }
}

export interface Column {
  ctas: Button[]
  titles: QuillEditor[]
}

export interface ComparisonColumn {
  details: QuillEditor[]
  eligibles: boolean[]
}

export interface Comparison {
  columns: ComparisonColumn[]
  descriptions: QuillEditor[]
  infos: QuillEditor[]
  titles: QuillEditor[]
}

export interface Options {
  block: BlockOptions
  column: ColumnOptions
  columnCta: ButtonOptions
  columnTitle: QuillEditorOptions
  comparison: ComparisonOptions
  comparisonColumn: ComparisonColumnOptions
  comparisonDescription: QuillEditorOptions
  comparisonInfo: QuillEditorOptions
  comparisonTitle: QuillEditorOptions
  comparisonDetail: QuillEditorOptions
  comparisonEligible: BooleanOptions
  moreLabel: QuillEditorOptions
  published: PublishedOptions
  title: QuillEditorOptions
}

interface ColumnOptions extends OptionsBase {
  newForm: (item: Column) => AbstractControl
  newItem: Column
  options: []
}

interface ComparisonColumnOptions extends OptionsBase {
  newForm: (item: ComparisonColumn) => AbstractControl
  newItem: ComparisonColumn
  options: []
}

interface ComparisonOptions extends OptionsBase {
  newForm: (item: Comparison) => AbstractControl
  newItem: Comparison
  options: {
    descriptions: QuillEditorOptions
    infos: QuillEditorOptions
    titles: QuillEditorOptions
    columns: ComparisonColumnOptions
  }[]
}

interface BooleanOptions extends OptionsBase {
  newForm: (item: boolean) => AbstractControl
  newItem: boolean
  options: []
}

@Injectable({
  providedIn: "root"
})
export class ComparisonChartService {
  blockType: BlockOption = "comparisonChart"

  constructor(
    private buttonService: ButtonService,
    private statusService: StatusService,
    private quillService: QuillService,
    private dataService: DataService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      columnCta: this.columnCtaOptions,
      column: this.columnOptions,
      columnTitle: this.columnTitleOptions,
      comparisonColumn: this.comparisonColumnOptions,
      comparisonDescription: this.comparisonDescriptionOptions,
      comparisonDetail: this.comparisonDetailOptions,
      comparisonEligible: this.comparisonEligibleOptions,
      comparisonInfo: this.comparisonInfoOptions,
      comparison: this.comparisonOptions,
      comparisonTitle: this.comparisonTitleOptions,
      moreLabel: this.moreLabelOptions,
      published: this.publishedOptions,
      title: this.titleOptions
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Comparison Charts",
        singular: "Comparison Chart"
      },
    }
  }

  private get titleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "title",
        plural: "Titles",
        singular: "Title"
      },
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get moreLabelOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "more label",
        plural: "more Labels",
        singular: "more Label"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get columnOptions(): ColumnOptions {
    const options: ColumnOptions = {
      formArray: {
        min: 3,
        max: 3
      },
      options: [],
      label: {
        placeholder: "column",
        plural: "Columns",
        singular: "Column"
      },
      newForm: this.newColumnForm,
      newItem: this.newColumn,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push()
    }
    return options
  }

  private get comparisonColumnOptions(): ComparisonColumnOptions {
    const options: ComparisonColumnOptions = {
      formArray: {
        min: this.columnOptions.formArray.min,
        max: this.columnOptions.formArray.max
      },
      options: [],
      label: {
        placeholder: "column",
        plural: "Columns",
        singular: "Column"
      },
      newForm: this.newComparisonColumnForm,
      newItem: this.newComparisonColumn,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push()
    }
    return options
  }

  private get columnCtaOptions(): ButtonOptions {
    const options: ButtonOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "button",
        plural: "Buttons",
        singular: "Button"
      },
      newItem: this.newButton,
      newForm: this.buttonService.newForm,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        color: ["41"],
        targetOption: ["self", "new", "modal"],
      })
    }
    return options
  }

  private get columnTitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        placeholder: "title text",
        plural: "Titles",
        singular: "Title"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get comparisonOptions(): ComparisonOptions {
    const options: ComparisonOptions = {
      formArray: {
        min: 0,
        max: 30
      },
      options: [],
      label: {
        placeholder: "comparison row",
        plural: "Rows",
        singular: "Row"
      },
      newForm: this.newComparisonForm,
      newItem: this.newComparison,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        columns: this.comparisonColumnOptions,
        descriptions: this.comparisonDescriptionOptions,
        infos: this.comparisonInfoOptions,
        titles: this.comparisonTitleOptions,
      })
    }
    return options
  }

  private get comparisonDescriptionOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "description",
        plural: "Descriptions",
        singular: "Description"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get comparisonInfoOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "information text",
        plural: "Information Tooltips",
        singular: "Information Tooltip"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get comparisonTitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        placeholder: "title text",
        plural: "Titles",
        singular: "Title"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get comparisonDetailOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "comparison details",
        plural: "Details",
        singular: "Details"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get comparisonEligibleOptions(): BooleanOptions {
    const options: BooleanOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "",
        plural: "Checkmarks",
        singular: "Checkmark"
      },
      newForm: this.newBooleanForm,
      newItem: this.newBoolean,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      //   options.options.push({})
    }
    return options
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect"]
    }
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newButton(): Button {
    return this.buttonService.newItem
  }

  get newColumn(): Column {
    return {
      ctas: [],
      titles: []
    }
  }

  get newComparison(): Comparison {
    return {
      descriptions: [],
      infos: [],
      titles: [],
      columns: []
    }
  }

  get newComparisonColumn(): ComparisonColumn {
    return {
      details: [],
      eligibles: []
    }
  }

  get newBoolean(): boolean {
    return true
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        titles: [this.newQuillItem],
        moreLabels: [],
        comparisons: [],
        columns: [],
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newQuillForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

  newButtonForm = (item: Button): AbstractControl => {
    return this.buttonService.newForm(item)
  }

  newBooleanForm = (item: boolean): AbstractControl => {
    return new FormControl(item)
  }

  newColumnForm = (item: Column): AbstractControl => {
    return new FormGroup({
      ctas: new FormArray(item.ctas.map(item => this.newButtonForm(item))),
      titles: new FormArray(item.titles.map(item => this.newQuillForm(item))),
    })
  }

  newComparisonColumnForm = (item: ComparisonColumn): AbstractControl => {
    return new FormGroup({
      details: new FormArray(item.details.map(item => this.newQuillForm(item))),
      eligibles: new FormArray(item.eligibles.map(item => this.newBooleanForm(item))),
    })
  }

  newComparisonForm = (item: Comparison): AbstractControl => {
    return new FormGroup({
      columns: new FormArray(item.columns.map(item => this.newComparisonColumnForm(item))),
      descriptions: new FormArray(item.descriptions.map(item => this.newQuillForm(item))),
      infos: new FormArray(item.infos.map(item => this.newQuillForm(item))),
      titles: new FormArray(item.titles.map(item => this.newQuillForm(item))),
    })
  }

}
