import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { OverlayModule } from "@angular/cdk/overlay"
import { RouterModule, Routes } from "@angular/router"

import { environment } from "../environments/environment"
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n"
import { APP_BASE_HREF, registerLocaleData } from "@angular/common"
import en from "@angular/common/locales/en"

import { AngularFireAuthModule } from "@angular/fire/auth"
import { AngularFireFunctionsModule } from "@angular/fire/functions"
import { AngularFireModule } from "@angular/fire"
import { AngularFireStorageModule } from "@angular/fire/storage"
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS, } from "@angular/fire/firestore"
import { AngularFireAnalyticsModule, CONFIG } from "@angular/fire/analytics"
import { ReactiveFormsModule } from "@angular/forms"

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"

import { GoogleChartsModule } from "angular-google-charts"

import { CoreModule } from "./core/core.module"
import { AppComponent } from "@aaa-web/app/app.component"

import { AnalyticsService } from "./core/services/analytics.service"
import { ErrorInterceptor } from "./core/services/error-interceptor.service"

import { NzMessageService } from "ng-zorro-antd/message"
import { NzNotificationService } from "ng-zorro-antd/notification"
import { NzModalService } from "ng-zorro-antd/modal"

import { AgmCoreModule } from "@agm/core"
import { QuillModule } from "ngx-quill"

import { NzButtonModule } from "ng-zorro-antd/button"
import { AppHomeComponent } from "./app-home.component"
import { MultiblockModule } from "@aaa-web/app/modules/multiblock/multiblock.module"
import { NzDrawerService } from "ng-zorro-antd/drawer"
import { NzIconService } from "ng-zorro-antd/icon"

registerLocaleData(en)

const routes: Routes = [
  { path: "", pathMatch: "full", component: AppHomeComponent },
  { path: "agent-scheduler", loadChildren: () => import("@aaa-web/app/modules/agent-scheduler/agent-scheduler.module").then(m => m.AgentSchedulerModule) },
  { path: "join-renew", loadChildren: () => import("@aaa-web/app/modules/join-renew/join-renew.module").then(m => m.JoinRenewModule) },

  // { path: "hidden-title", component: HiddenTitleComponent },
  // { path: "multiblock", component: MultiblockComponent },

  { path: "**", component: AppHomeComponent },
  // { path: "**", component: JoinRenewComponent }

]

@NgModule({
  declarations: [
    AppComponent,
    AppHomeComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey
    }),
    GoogleChartsModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(routes, { initialNavigation: "enabled" }),
    NzButtonModule,
    MultiblockModule,
  ],
  providers: [
    AnalyticsService,
    NzIconService,
    NzMessageService,
    NzModalService,
    NzDrawerService,
    NzNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: window["baseHref"]
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.emulator ? {
        host: "localhost:8080",
        ssl: false
      } : undefined
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
        anonymize_ip: true
      }
    },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
