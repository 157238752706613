import { Injectable } from "@angular/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { ThemeOption, ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { AlignmentOption, AlignmentOptions } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BoxModel, BoxModelService, BoxModelOptions } from "@aaa-web/app/modules/multiblock/options/box-model/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    titles: QuillEditor[]
  }
  options: {
    alignment: AlignmentOption
    boxModel: BoxModel
    theme: ThemeOption
  }
}

export interface Options {
  block: BlockOptions
  title: QuillEditorOptions
  alignment: AlignmentOptions
  boxModel: BoxModelOptions
  published: PublishedOptions
  theme: ThemeOptions
}

@Injectable({
  providedIn: "root"
})
export class TitleService {
  blockType: BlockOption = "title"

  constructor(
    private boxModelService: BoxModelService,
    private dataService: DataService,
    private statusService: StatusService,
    private quillService: QuillService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      title: this.titleOptions,
      alignment: this.alignmentOptions,
      boxModel: this.boxModelOptions,
      published: this.publishedOptions,
      theme: this.themeOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Titles",
        singular: "Title"
      },
    }
  }

  get titleOptions(): QuillEditorOptions {
    return this.quillService.options
  }

  get themeOptions(): ThemeOptions {
    return {
      options: ["title1", "title2"],
      label: {
        singular: "Theme"
      }
    }
  }

  get alignmentOptions(): AlignmentOptions {
    return {
      options: ["left", "center"],
      label: {
        singular: "Alignment"
      }
    }
  }

  get boxModelOptions(): BoxModelOptions {
    return this.boxModelService.options
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect"]
    }
  }

  get newBoxModel(): BoxModel {
    return this.boxModelService.newItem
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        titles: [this.newQuillItem],
      },
      options: {
        alignment: "center",
        boxModel: this.newBoxModel,
        theme: "title1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

}
