import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MultiblockCustomElementComponent } from "./component"
import { MultiblockModule } from "@aaa-web/app/modules/multiblock/multiblock.module"

@NgModule({
  exports: [
  ],
  declarations: [
    MultiblockCustomElementComponent,
  ],
  imports: [
    CommonModule,
    MultiblockModule,
  ]
})
export class MultiblockCustomElementModule {
}
