import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { SimpleLoggerGroupDoc, SimpleLoggerIndividualDoc, SimpleLoggerService } from "../simple-logger.service"
import { map } from "rxjs/operators"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { ChartType, Column, Row } from "angular-google-charts"
import { DocumentChangeAction } from "@angular/fire/firestore"
import { EmemberHealthFlaggedComponent } from "@aaa-web/app/modules/simple-logger/emember-health/emember-health-flagged/emember-health-flagged.component"

@Component({
  selector: "ava-emember-health",
  templateUrl: "./emember-health.component.html"
})
export class EmemberHealthComponent implements OnInit, OnDestroy {
  private docs$: Observable<SimpleLoggerIndividualDoc[]>
  private docsSubscription: Subscription
  filteredMessages: {
    [key: string]: SimpleLoggerIndividualDoc[]
  } = {}
  private filters: {
    [key: string]: boolean
  } = {}
  private count: {
    [key: string]: number
  } = {}
  component: {
    error?: typeof EmemberHealthFlaggedComponent,
    flagged?: typeof EmemberHealthFlaggedComponent
  } = {}

  chart: {
    type: ChartType
    title: string
    data: Row[]
    columns: Column[]
    options: Record<string, unknown>
  }

  constructor(
    private simpleLoggerService: SimpleLoggerService,
    public roleService: RoleService,
  ) {
  }

  private static getAction(orderId: string) {
    const actions = {
      "EJ": "JOIN",
      "EC": "CORPORATE GROUP",
      "ER": "RENEW",
      "EQ": "QUICK RENEW",
      "EM": "MANAGE AR",
      "EE": "AR SIGNUP",
      "EA": "ADD SOMEONE",
      "EG": "GIFT",
      "EU": "CHANGE PLAN"
    }
    return actions[orderId?.substr(0, 2)]
  }

  private static getFlags(classes) {
    const flags = {}
    flags["error"] = true
    if (classes["CreSecureController"]
      && classes["MembershipUtils"]
      && classes["CsSoapService"]
    ) {
      flags["flagged"] = true
    }
    return flags
  }

  ngOnInit(): void {
    this.chart = {
      type: ChartType.ColumnChart,
      title: "Emember Health Check",
      data: [],
      columns: ["Date Stamp", "Connection Errors", "Flagged Members"],
      options: {
        hAxis: {
          direction: -1
        },
        chartArea: {
          width: "75%",
          left: "5%"
        }
      }
    }
    this.filters = {
      "error": true,
      "flagged": true
    }
    this.loadComponents().catch(() => {
      //
    })
    this.docs$ = this.simpleLoggerService.ememberHealthCollectionRef
      .snapshotChanges()
      .pipe(
        map((messages: any[]) => {
          return messages.map(result => {
            if (result) {
              const message = result["payload"].doc.data()
              const id = result["payload"].doc.id
              const labels = this.getAttributes(message, "label")
              const functions = this.getAttributes(message, "function")
              const descriptions = this.getAttributes(message, "description")
              const classes = this.getAttributes(message, "class")
              const flags = EmemberHealthComponent.getFlags(classes)
              const details = this.getDetails(message)
              return { ...message, id, labels, functions, descriptions, classes, flags, details }
            }
          })
        })
      )

    this.docsSubscription = this.docs$
      .subscribe(messages => {
        const prefilteredMessages: SimpleLoggerIndividualDoc[] = messages
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
        Object.keys(this.filters).forEach(filter => {
          if (this.filters[filter]) {
            this.count[filter] = prefilteredMessages.filter(message => message.flags[filter]).length
          }
        })
        this.applyFilters(prefilteredMessages)
      })
  }

  private async loadComponents() {
    const { EmemberHealthFlaggedComponent } = await import("./emember-health-flagged/emember-health-flagged.component")
    const { EmemberHealthErrorComponent } = await import("./emember-health-error/emember-health-error.component")
    this.component = {
      error: EmemberHealthErrorComponent,
      flagged: EmemberHealthFlaggedComponent
    }
  }

  ngOnDestroy(): void {
    this.docsSubscription?.unsubscribe()
  }

  private getAttributes(message: SimpleLoggerIndividualDoc, attribute: string) {
    const attributes = {}
    message.records.forEach(record => {
      if (record[attribute]) {
        attributes[record[attribute]] = true
      }
    })
    return attributes
  }

  private applyFilters(prefilteredMessages: SimpleLoggerIndividualDoc[]) {
    /**
     * Build lists of all messages that match any of the filters.
     */
    const filteredMessages = {}
    Object.keys(this.filters).forEach(filter => {
      filteredMessages[filter] = []
    })
    prefilteredMessages.forEach(message => {
      Object.keys(this.filters).forEach(filter => {
        if (this.filters[filter] && message.flags[filter]) {
          filteredMessages[filter].push(message)
        }
      })
    })

    /**
     * Sort
     */
    Object.keys(filteredMessages).forEach(filter => {
      filteredMessages[filter] = filteredMessages[filter]
        .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
    })

    /**
     * Rebuild the descending index values
     */
    Object.keys(filteredMessages).forEach(filter => {
      filteredMessages[filter] = filteredMessages[filter].map((message, messageArrayIndex) => {
        message.index = filteredMessages[filter].length - messageArrayIndex
        return { ...message } // shallow clone...otherwise the index gets overwritten by-reference in each [filter] array
      })
    })

    /**
     * Prepare date counters for the chart, showing number of messages on each day.
     * Probably better to use original timestamps and a chart with configurable buckets.
     */
    const chartDataErrors = {}
    filteredMessages["error"].forEach((message: SimpleLoggerIndividualDoc) => {
      const date = new Date(message.timestamp.seconds * 1000).toDateString()
      chartDataErrors[date] = chartDataErrors[date] || 0
      chartDataErrors[date]++
    })
    const chartDataFlagged = {}
    filteredMessages["flagged"].forEach((message: SimpleLoggerIndividualDoc) => {
      const date = new Date(message.timestamp.seconds * 1000).toDateString()
      chartDataFlagged[date] = chartDataFlagged[date] || 0
      chartDataFlagged[date]++
    })

    /**
     * This is written to rely on flagged being a subset of errors,
     * otherwise would get an error when using the error key to lookup a flagged item.
     */
    this.chart.data = Object.keys(chartDataErrors).map((key) => {
      return [key, chartDataErrors[key], chartDataFlagged[key]] as Row
    })

    /**
     * Replace filteredMessages lists for the template.
     */
    this.filteredMessages = filteredMessages

  }

  private getDetails(message) {
    const details = []
    message.records.forEach(record => {
      if (record.label === "membershipDetails") {
        const membershipDetails = record.data?.membershipDetails
        if (membershipDetails) {
          const orderId = membershipDetails.Membership?.payment?.transaction?.orderId
          details.push({
            key: "Error Reason",
            value: "CS connection failed"
          })
          details.push({
            key: "Action",
            value: EmemberHealthComponent.getAction(orderId)
          })
          if (membershipDetails.autoRenewalFlag) {
            details.push({
              key: "Auto Renew",
              value: membershipDetails.autoRenewalFlag
            })
          }
          details.push({
            key: "Total Cost",
            value: membershipDetails.totalCost
          })
          details.push({
            key: "Transaction Id",
            value: membershipDetails.transactionId
          })

          const primaryMember = membershipDetails.Membership?.PrimaryMember
          if (primaryMember) {
            details.push({
              key: "Primary Full Name",
              value: primaryMember.firstName + " " + primaryMember.firstName
            })
            if (primaryMember.cellPhone) {
              details.push({
                key: "Primary Cell Phone",
                value: primaryMember.cellPhone
              })
            }
            if (primaryMember.homePhone) {
              details.push({
                key: "Primary Home Phone",
                value: primaryMember.homePhone
              })
            }
            if (primaryMember.businessPhone) {
              details.push({
                key: "Primary Business Phone",
                value: primaryMember.businessPhone + " " + primaryMember.businessPhoneExt
              })
            }
            if (primaryMember.email) {
              details.push({
                key: "Primary Email",
                value: primaryMember.email
              })
            }
            if (primaryMember.membershipNumber) {
              details.push({
                key: "Primary Membership Number",
                value: primaryMember.membershipNumber
              })
            }
            if (primaryMember.membershipType) {
              details.push({
                key: "Primary Membership Type",
                value: primaryMember.membershipType
              })
            }
          }
          const address = membershipDetails.Membership?.Address
          if (address) {
            details.push({
              key: "Membership Address",
              value: address.address1
            })
            if (address.address2) {
              details.push({
                key: "Membership Address",
                value: address.address2
              })
            }
            details.push({
              key: "Membership Address",
              value: address.cityName + ", " + address.StateProv + ", " + address.postalCode
            })
          }
          const associateMember = membershipDetails.Membership?.AssociateMember
          if (associateMember) {
            details.push({
              key: "Associate Full Name",
              value: associateMember.firstName + " " + associateMember.firstName
            })
            if (associateMember.cellPhone) {
              details.push({
                key: "Associate Cell Phone",
                value: associateMember.cellPhone
              })
            }
            if (associateMember.homePhone) {
              details.push({
                key: "Associate Home Phone",
                value: associateMember.homePhone
              })
            }
            if (associateMember.businessPhone) {
              details.push({
                key: "Associate Business Phone",
                value: associateMember.businessPhone + " " + associateMember.businessPhoneExt
              })
            }
            if (associateMember.email) {
              details.push({
                key: "Associate Email",
                value: associateMember.email
              })
            }
            if (associateMember.membershipNumber) {
              details.push({
                key: "Associate Membership Number",
                value: associateMember.membershipNumber
              })
            }
            if (associateMember.membershipType) {
              details.push({
                key: "Associate Membership Type",
                value: associateMember.membershipType
              })
            }
          }
          const payment = membershipDetails.Membership?.payment
          if (payment) {
            const account = payment.account
            if (account) {
              details.push({
                key: "Billing Full Name",
                value: account.accountHolder?.firstName + " " + account.accountHolder?.lastName
              })
              const billingAddress = account.address
              if (billingAddress) {
                details.push({
                  key: "Billing Address",
                  value: billingAddress.address1
                })
                if (billingAddress.address2) {
                  details.push({
                    key: "Billing Address",
                    value: billingAddress.address2
                  })
                }
                details.push({
                  key: "Billing Address",
                  value: billingAddress.cityName + ", " + billingAddress.StateProv + ", " + billingAddress.postalCode
                })
              }
            }
            details.push({
              key: "Billing Amount",
              value: payment.amount
            })
            details.push({
              key: "Billing Auto Renew",
              value: payment.autoRenew
            })
            const transaction = payment.transaction
            if (transaction) {
              details.push({
                key: "Billing Order Id",
                value: transaction.orderId
              })
              details.push({
                key: "Billing Transaction Id",
                value: transaction.txnId
              })
            }
          }
          const costSummary = membershipDetails.Membership?.costSummary
          if (costSummary) {
            details.push({
              key: "costSummary Auto Renew Discount",
              value: costSummary.autoRenewDiscount,
              type: "currency"
            })
            details.push({
              key: "costSummary Discount Amount",
              value: costSummary.discountAmount,
              type: "currency"
            })
            details.push({
              key: "costSummary Solicitation Discount",
              value: costSummary.solicitationDiscount,
              type: "currency"
            })
            details.push({
              key: "costSummary Solicitation Requires Auto Renew",
              value: costSummary.solicitationRequiresAutoRenew
            })
            details.push({
              key: "costSummary Total Cost",
              value: costSummary.totalCost,
              type: "currency"
            })
            details.push({
              key: "costSummary Waive Enroll Discount",
              value: costSummary.waiveEnrollDiscount,
              type: "currency"
            })
          }
        }
      }
    })
    if (details.length === 0) {
      message.records.forEach(record => {
        if (record.description === "CS Payment Object") {
          /**
           "class": "MembershipUtils",
           "function": "convertCresecurePaymentToCs",
           "description": "CS Payment Object",
           "label": "CreSecure",
           "data": {
             "memberNumber": "429002",
             "paymentObject": {
               "transaction": {
                 "cvvMatch": null,
                 "accountVerification": null,
                 "txnId": "468",
                 "approvalCode": "4223",
                 "customerRefNum": null,
                 "code": "000",
                 "profileProcStatus": null,
                 "orderId": "EJ60a390789c",
                 "message": null,
                 "profileProcStatusMsg": null,
                 "avsMatch": "Z"
               },
               "account": {
                 "expMonth": "04",
                 "accountHolder": {
                   "nameSuffix": "",
                   "firstName": "Lie",
                   "middleInitial": "S",
                   "lastName": "Pot"
                 },
                 "type": "Visa",
                 "address": {
                   "stateProv": "CO",
                   "address2": "",
                   "StateProv": "CO",
                   "status": null,
                   "address1": "1 Kite St",
                   "postalCode": "800",
                   "cityName": "Deer",
                   "country": null
                 },
                 "cardNumber": "7015",
                 "cardHolderName": "LEOT",
                 "expYear": "2022"
               },
               "amount": "84.50",
               "autoRenew": "Y",
               "paymentType": "CC",
               "contract": {
                 "customerId": "4e2ca9b32f6",
                 "contractId": "EJ60a0789c",
                 "token": "c0e9617b8260fe83ec2740d"
               },
               "currencyType": "USD",
               "approvalCode": "4223"
             }
           }
           */
          const paymentObject = record.data?.paymentObject
          if (paymentObject) {
            details.push({ key: "Error Reason", value: "CS connection failed" })
            details.push({
              key: "Action", value: EmemberHealthComponent
                .getAction(record.data.paymentObject.transaction?.orderId)
            })

            details.push({
              key: "Billing Full Name",
              value: record.data.paymentObject.account?.cardHolderName
            })
            if (record.data.paymentObject.account?.address) {
              details.push({
                key: "Billing Address",
                value: record.data.paymentObject.account.address.address1
              })
              if (record.data.paymentObject.account.address.address2) {
                details.push({
                  key: "Billing Address",
                  value: record.data.paymentObject.account.address.address2
                })
              }
              details.push({
                key: "Billing Address",
                value: record.data.paymentObject.account.address.cityName +
                  ", " + record.data.paymentObject.account.address.StateProv +
                  ", " + record.data.paymentObject.account.address.postalCode
              })
            }
            details.push({
              key: "Billing Amount",
              value: record.data.paymentObject.amount,
              type: "currency"
            })
            details.push({
              key: "Billing Auto Renew",
              value: record.data.paymentObject.autoRenew
            })
          }
          details.push({
            key: "Membership Number",
            value: record.data?.memberNumber
          })
        }
      })
      message.records.forEach(record => {
        if (record.description === "paymentResult") {
          /**
           "class": "CreSecureController",
           "description": "paymentResult",
           "label": "creSecure",
           "function": "callbackJson,
           "data": {
             "transactionDetails": {
               "rurl": "https://safe.cresecure.net/hpf/1_1/",
               "ccNumber": "XXXXX15",
               "customer_id": "4e220a9b32f6",
               "approvalCode": "423",
               "cardBrandSelected": "Visa",
               "tokenId": "c0e9617b60fe83ec2740d",
               "CTROUTD": "110",
               "paymentType": "CC",
               "transactionEnd": "32",
               "AVSMatch": "Z",
               "transactionStart": "028",
               "sessionId": "EJ60f0789c",
               "appCode": "4283",
               "expYear": "2022",
               "profileId": "vf859030",
               "status": "000",
               "expMonth": "04",
               "merchantPass": "vf85030",
               "customer_email": "lesl@es.com",
               "name": "LES%20S%20ST",
               "ccType": "Visa",
               "transId": "45268",
               "amount": "84.50"
             }
           }
           */
          details.push({
            key: "Order Id",
            value: record.data?.transactionDetails?.sessionId
          })
          details.push({
            key: "Transaction Id",
            value: record.data?.transactionDetails?.transId
          })
          details.push({
            key: "Customer Email",
            value: record.data?.transactionDetails?.customer_email
          })

        }
      })
    }

    return details
  }

}
