import { Injectable } from "@angular/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { Image, ImageOptions, ImageService } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { Video, VideoOptions, VideoService } from "@aaa-web/app/modules/multiblock/fields/video/service"
import {
  Javascript, JavascriptOptions, JavascriptService
} from "@aaa-web/app/modules/multiblock/fields/javascript/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { Button, ButtonOptions, ButtonService, } from "@aaa-web/app/modules/multiblock/fields/button/service"
import { BoxModel, BoxModelService, BoxModelOptions } from "@aaa-web/app/modules/multiblock/options/box-model/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"
import { ThemeOption, ThemeOptions } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { AbstractControl } from "@angular/forms"

export interface Block extends BlockBase {
  columns: Column[]
  fields: {
    [key: string]: Field
  }
  options: {
    boxModel: BoxModel
    columnGap: boolean
    theme: ThemeOption
  }
}

export interface Column {
  accordion: boolean
  fieldRefs: string[]
}

export type Field = QuillEditor | Button | Image | Javascript | Video

export interface Options {
  block: BlockOptions
  columnBlock: BlockOptions
  quillEditor: QuillEditorOptions
  button: ButtonOptions
  image: ImageOptions
  javascript: JavascriptOptions
  video: VideoOptions
  boxModel: BoxModelOptions
  published: PublishedOptions
  theme: ThemeOptions
}

export interface BlockField {
  blockRef: string
  type: string
}

@Injectable({
  providedIn: "root"
})
export class ColumnService {
  blockType: BlockOption = "column"
  highlight: { [key: string]: boolean } = {}

  constructor(
    private boxModelService: BoxModelService,
    private buttonService: ButtonService,
    private imageService: ImageService,
    private javascriptService: JavascriptService,
    private statusService: StatusService,
    private quillService: QuillService,
    private videoService: VideoService,
    private dataService: DataService,
    private formService: FormService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      columnBlock: this.columnBlockOptions,
      quillEditor: this.quillEditorOptions,
      button: this.buttonsOptions,
      image: this.imagesOptions,
      javascript: this.javascriptsOptions,
      video: this.videosOptions,
      boxModel: this.boxModelOptions,
      published: this.publishedOptions,
      theme: this.themeOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Columns",
        singular: "Column"
      }
    }
  }

  get columnBlockOptions(): BlockOptions {
    return {
      label: {
        plural: "Rows",
        singular: "Row"
      }
    }
  }

  get quillEditorOptions(): QuillEditorOptions {
    const options = this.quillService.options
    options.newItem.value = "<h2><b style=\"font-size: 60px;\">Title</b></h2><h3><span style=\"font-size: 32px;\">Subtitle</span></h3>"
    return options
  }

  get buttonsOptions(): ButtonOptions {
    return this.buttonService.options
  }

  get imagesOptions(): ImageOptions {
    return this.imageService.options
  }

  get javascriptsOptions(): JavascriptOptions {
    const options = this.javascriptService.options
    options.newItem.background = {
      color: "20",
      opacity: 80
    }
    return options
  }

  get videosOptions(): VideoOptions {
    return this.videoService.options
  }

  get boxModelOptions(): BoxModelOptions {
    return this.boxModelService.options
  }

  get publishedOptions(): PublishedOptions {
    return this.statusService.options
  }

  get themeOptions(): ThemeOptions {
    return {
      label: {
        singular: "Theme Color"
      },
      options: ["opt1", "opt2", "opt3"]
    }
  }

  newColumn(fieldRef1: string, fieldRef2: string): Column {
    return {
      accordion: null,
      fieldRefs: [fieldRef1, fieldRef2]
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      columns: [],
      fields: {},
      options: {
        boxModel: this.boxModelService.newItem,
        columnGap: true,
        theme: "opt1"
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  get newItem(): BlockField {
    return {
      blockRef: null,
      type: "columnBlock",
    }
  }

  newForm = (item: BlockField): AbstractControl => {
    return this.formService.newForm(item, this.newItem)
  }

  setHighlight(blockId: string, columnsIndex: string, rowsIndex: string): void {
    const highlightIndex = blockId + "-" + columnsIndex + "-" + rowsIndex
    this.highlight[highlightIndex] = true
  }

  unsetHighlight(blockId: string, columnsIndex: string, rowsIndex: string): void {
    const highlightIndex = blockId + "-" + columnsIndex + "-" + rowsIndex
    this.highlight[highlightIndex] = false
  }

  resetHighlight(): void {
    this.highlight = {}
  }

}
