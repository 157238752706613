export const environment = {
  production: true,
  emulator: false,
  ngServe: false,
  cloudFunctionsURL: "https://us-central1-avagate-wss-2.cloudfunctions.net",
  firebaseConfig: {
    apiKey: "AIzaSyDlL8_AHv2F9QiY8mZlbBfsHWymZrqhOKA",
    authDomain: "avagate-wss-2.firebaseapp.com",
    databaseURL: "https://avagate-wss-2.firebaseio.com",
    projectId: "avagate-wss-2",
    storageBucket: "avagate-wss-2.appspot.com",
    messagingSenderId: "86319937491",
    appId: "1:86319937491:web:1109075563d40abe406126"
  }
}
