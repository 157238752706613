import { Component, Input, OnInit } from "@angular/core"
import firebase from "firebase/app"
import "firebase/storage"
import { NzUploadFile } from "ng-zorro-antd/upload"
import { FileManagerService } from "../file-manager.service"
import FirebaseStorageRef = firebase.storage.Reference
import { AngularFireStorage } from "@angular/fire/storage"

@Component({
  selector: "ava-file-manager-list",
  templateUrl: "./file-manager-list.component.html"
})
export class FileManagerListComponent implements OnInit {
  @Input() directory: string
  defaultFileList: NzUploadFile[] = [
    {
      uid: "-1",
      name: "xxx.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      thumbUrl: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
    }
  ]
  fileList: NzUploadFile[] = []

  constructor(
    private fmService: FileManagerService,
    private afStorage: AngularFireStorage,
  ) {
  }

  ngOnInit(): void {
    this.getFileList()
  }

  private getFileList() {
  //   this.fileList = [...this.defaultFileList]
  // }
  //
  // logSubdirectories(path: string): void {
    // console.log(path)
    const storageRef: FirebaseStorageRef = firebase.storage().ref()
    const listRef = storageRef.child(this.directory)
    listRef.listAll()
      .then(result => {
        // console.log(result)
        result.items.forEach(item => {
          this.getMetaData(item, storageRef)
          const file: NzUploadFile = {
            uid: "",
            name: item.name, // this.fmService.domain + "/" + this.directory + "/" + item.name,
            url: this.fmService.domain + "/" + item.fullPath,
            thumbUrl: this.fmService.domain + "/" + item.fullPath,
            status: "done"
          }
          // const fileName = item.name
          // console.log(item)
          // this.list.push(fileName)
          // console.log(this.list)
          this.fileList.push(file)
          // console.log(file)
        })
        // console.log(result.prefixes)
        result.prefixes.forEach(prefix => {
          // this.logSubdirectories(prefix.fullPath)
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getMetaData(item: FirebaseStorageRef, storageRef: FirebaseStorageRef): void {
    const fileRef = storageRef.child(item.fullPath)
    fileRef.getMetadata()
      .then((metadata) => {
        this.fileList.forEach(file => {
          if (file.name === item.name) {
            file.metadata = metadata
            if (file.message === "Upload error") {
              file.message = ""
            }
          }
        })
        // console.log(metadata)
        // Metadata now contains the metadata for 'images/forest.jpg'
      })
      .catch(() => {
        // Uh-oh, an error occurred!
      })
  }

}
