import { Component } from "@angular/core"

@Component({
  selector: "ava-app-home",
  template: `
    <ava-multiblock></ava-multiblock>
  `
})
export class AppHomeComponent {
}
