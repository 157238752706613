import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { Membership, MembershipOptions, MembershipService } from "./membership/service"
import { Image, ImageOptions, ImageService } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Block extends BlockBase {
  fields: {
    images: Image[]
    desktopTitles: QuillEditor[]
    mobileTitles: QuillEditor[]
    subtitles: QuillEditor[]
    quantityLabels: QuillEditor[]
    memberships: Membership[]
  }
}

export interface Options {
  block: BlockOptions
  image: ImageOptions
  desktopTitle: QuillEditorOptions
  mobileTitle: QuillEditorOptions
  subtitle: QuillEditorOptions
  quantityLabel: QuillEditorOptions
  membership: MembershipOptions
  membershipFlag: QuillEditorOptions
  membershipSubtitle: QuillEditorOptions
  membershipTitle: QuillEditorOptions
  published: PublishedOptions
}

@Injectable({
  providedIn: "root"
})
export class JoinService {
  blockType: BlockOption = "join"

  constructor(
    private imageService: ImageService,
    private membershipService: MembershipService,
    private statusService: StatusService,
    private quillService: QuillService,
    private dataService: DataService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      image: this.imageOptions,
      desktopTitle: this.desktopTitleOptions,
      mobileTitle: this.mobileTitleOptions,
      subtitle: this.subtitleOptions,
      quantityLabel: this.quantityLabelOptions,
      membership: this.membershipOptions,
      membershipFlag: this.membershipFlagOptions,
      membershipSubtitle: this.membershipSubtitleOptions,
      membershipTitle: this.membershipTitleOptions,
      published: this.publishedOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Join Pages",
        singular: "Join Page"
      },
    }
  }

  get imageOptions(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "",
        plural: "Background Images",
        singular: "Background Image"
      },
      formArray: {
        alt: false,
        cropper: false,
        min: 1,
        max: 1,
      },
      newForm: this.newImageForm,
      newItem: this.newImage,
    }
    options.options = [
      {
        description: "Desktop 1440x600",
        dimensions: "1440x600",
        minWidth: {
          px: 1440
        },
        maxWidth: {
          px: 1440
        },
        minHeight: {
          px: 600
        },
        maxHeight: {
          px: 600
        },
        target: []
      }
    ]
    return options
  }

  private get desktopTitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "title",
        plural: "Desktop Titles",
        singular: "Desktop Title"
      },
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get mobileTitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "title",
        plural: "Mobile Titles",
        singular: "Mobile Title"
      },
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get subtitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "subtitle",
        plural: "Subtitles",
        singular: "Subtitle"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get quantityLabelOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "text",
        plural: "Member Quantity Selectors",
        singular: "Member Quantity Selector"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get membershipOptions(): MembershipOptions {
    const options: MembershipOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 3
      },
      options: [],
      label: {
        plural: "Membership Levels",
        singular: "Membership Level"
      },
      newFormFunction: this.membershipService.newForm,
      newItemFunction: this.membershipService.newField,
      placeholder: ""
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        flags: this.membershipFlagOptions,
        subtitles: this.membershipSubtitleOptions,
        titles: this.membershipTitleOptions
      })
    }
    return options
  }

  private get membershipFlagOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        placeholder: "text",
        plural: "Flags",
        singular: "Flag"
      },
      newForm: this.membershipService.newTextForm,
      newItem: this.membershipService.newText,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get membershipSubtitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        placeholder: "text",
        plural: "Subtitles",
        singular: "Subtitle"
      },
      newForm: this.membershipService.newTextForm,
      newItem: this.membershipService.newText,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  private get membershipTitleOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        placeholder: "text",
        plural: "Titles",
        singular: "Title"
      },
      newForm: this.membershipService.newTextForm,
      newItem: this.membershipService.newText,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }


  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish"]
    }
  }

  get newImage(): Image {
    return this.imageService.newItem
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        images: [],
        desktopTitles: [this.newQuillItem],
        mobileTitles: [this.newQuillItem],
        subtitles: [],
        quantityLabels: [],
        memberships: [],
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newQuillForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

  newImageForm = (item: Image): AbstractControl => {
    return this.imageService.newForm(item)
  }

/*
  newForm = (block: Block): BlockState => {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          images: new FormArray(block.fields.images.map(item => this.newImageForm(item))),
          desktopTitles: new FormArray(block.fields.desktopTitles.map(item => this.newTextForm(item))),
          mobileTitles: new FormArray(block.fields.mobileTitles.map(item => this.newTextForm(item))),
          subtitles: new FormArray(block.fields.subtitles.map(item => this.newTextForm(item))),
          quantityLabels: new FormArray(block.fields.quantityLabels.map(item => this.newTextForm(item))),
          memberships: new FormArray(block.fields.memberships.map(item => this.membershipService.newForm(item))),
        }),
        pathnames: new FormArray(block.pathnames.map(item => new FormControl(item))),
        status: this.statusService.newForm(block.status)
      })
    }
  }
*/

}
