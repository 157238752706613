import { Component, Inject, Input, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { DOCUMENT } from "@angular/common"
import { AnalyticsEvent, AnalyticsService } from "@aaa-web/app/core/services/analytics.service"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

export interface ParamsObj {
  meta: ParamsMeta,
  options: ParamsOptions
}

export interface ParamsMeta {
  clubId: string,
  user: {
    email: string
    firstName: string
    lastName: string
    memberNumber: string
    token: string
    id: string
  },
  webEnv: string
}

export interface ParamsOptions {
  callBack: string,
  options: {
    value: string,
    token: string
  }[],
  returnPath: string
}

@Component({
  selector: "ava-account-email-sync",
  templateUrl: "./account-email-sync.component.html"
})
export class AccountEmailSyncComponent implements OnInit {
  @Input() params: string
  paramsObj: ParamsObj
  meta: ParamsMeta
  options: ParamsOptions
  form: FormGroup
  submitted: boolean
  window: MetaWindow

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private analyticsService: AnalyticsService,
    private formBuilder: FormBuilder,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.paramsObj = JSON.parse(this.params)
    this.meta = this.paramsObj.meta
    this.options = this.paramsObj.options
    // console.log(this.options)

    this.form = this.formBuilder.group({
      email: ["", Validators.required]
    })

    // // @ts-ignore
    // this.window.gtag('event', 'shown', {
    //   'event_category': 'email_preference',
    //   'event_label': 'multi_email_on_file',
    //   'value': 1,
    //   'send_to': this.window['metaData'].gaId
    // })

    const event: AnalyticsEvent = {
      eventCategory: "email_preference",
      eventAction: "shown",
      eventLabel: "multi_email_on_file",
      eventValue: 1
    }
    this.analyticsService.sendEvent(event)

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: "Custom Element",
      eventAction: "Supported",
      eventLabel: "<ava-account-email-sync>" + window.location.origin,
      eventValue: 1
    }
    this.analyticsService.sendEvent(eventCustomElement)
  }

  async onSubmit(option: string): Promise<void> {
    if (this.submitted) return
    this.submitted = true
    // console.log('OnSubmit() processing')

    const event: AnalyticsEvent = {
      eventCategory: "email_preference",
      eventAction: "selected",
      eventLabel: "multi_email_on_file",
      eventValue: 1
    }
    this.analyticsService.sendEvent(event)

    const headers = new HttpHeaders({})
    try {
      await this.http.request(
        "POST",
        this.options.callBack + option,
        {
          headers: headers
        }
      ).toPromise()
      // console.log('reloading')
      this.document.location.href = this.options.returnPath
      // console.log(result)
    } catch (e) {
      this.submitted = false
      console.log(e)
    }
  }

}
