import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { Subscription } from "rxjs"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { BuildService } from "@aaa-web/app/modules/multiblock/services/build.service"
import { FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { BlockService } from "@aaa-web/app/modules/multiblock/services/block.service"
import { RevisionService } from "@aaa-web/app/modules/multiblock/services/revision.service"
import { BlockStateBase, StateService } from "@aaa-web/app/modules/multiblock/services/state.service"
import { PageService, Block as PageBlock } from "@aaa-web/app/modules/multiblock/containers/page/service"
import { NzIconService } from "ng-zorro-antd/icon"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "ava-multiblock",
  templateUrl: "./multiblock.component.html"
})
export class MultiblockComponent implements OnInit, OnDestroy {
  @ViewChild("blockContainer", { read: ViewContainerRef }) blockContainerRef
  @ViewChild("adminContainer", { read: ViewContainerRef }) adminContainerRef
  blockId: string
  block: PageBlock
  state: BlockStateBase = {}
  childBlockIds: string[]
  private subscription: { [key: string]: Subscription } = {}
  initialized: boolean
  adminModuleLoaded: boolean

  constructor(
    public blockService: BlockService,
    private buildService: BuildService,
    public dataService: DataService,
    public formService: FormService,
    private nzIconService: NzIconService,
    private pageService: PageService,
    private revisionService: RevisionService,
    private roleService: RoleService,
    private stateService: StateService,
    private domWindow: Window,
  ) {
    /**
     * Enables icons as CDN from millisite.com when running in custom element mode on aaa.com domains,
     * CORS configuration for this is in firebase.json configs.
     * TODO: when we are running on same domain remove CORS config and remove webEnv and domain change here.
     */
    if (this.window.metaData.webEnv === "prod" || this.window.metaData.webEnv === "test") {
      const domain = "https://" + this.window.metaData.webEnv + ".millisite.com"
      this.nzIconService.changeAssetsSource(domain)
    }
  }

  ngOnInit(): void {
    /*
        this.subscription["blockRole"] = combineLatest([this.roleService.roles$, this.dataService.blocks$])
          .subscribe(([roles, blocks]) => {
            if (roles.administrator && blocks) {
              const pageBlocks = blocks.filter(block => block.blockType === "container" && (block as PageBlock).containerType === "page") as PageBlock[]

              /!**
               * Create pageBlock if it is missing.
               *!/
              if (!pageBlocks.length) {
                this.loadAdminModules()
                /!*
                            console.log("saveNewBlock")
                            this.saveNewBlock(this.pageService.newBlock, null)
                              .then(() => {
                                // console.log("created new page block")
                              })
                *!/
              }
            }
          })
    */

    this.subscription["blocks"] = this.dataService.blocksArray$
      .subscribe(blocks => {
        const pageContainerBlocks: PageBlock[] = [] = blocks.filter(block => block.blockType === "container" && (block as PageBlock).containerType === "page") as PageBlock[]
        // console.log(pageContainerBlocks)
        for (const pageContainerBlock of pageContainerBlocks) {
          // console.log(pageContainerBlock)
          // if (pageContainerBlocks.length > 1
          //   && !pageContainerBlock.childBlockIds.length
          //   && !pageContainerBlock.pageTitle
          //   && pageContainerBlock.status.preSaved
          // ) {
          //   this.delete(pageContainerBlock.id)
          // }
          this.block = pageContainerBlock
          this.blockId = pageContainerBlock.id
          this.dataService.pageBlock = pageContainerBlock
          if (this.adminMode && this.state && this.state.form) {
            this.childBlockIds = this.state.form.value.childBlockIds
            this.stateService.compareBlockState(this.blockId)
          }
          if (!this.adminMode) {
            this.childBlockIds = pageContainerBlock.childBlockIds
          }
          if (!this.initialized) {
            this.initialized = true
            if (this.roleService.adminModePermission) {
              // this.loadAdminModules()
              // this.stateService.updateState(this.block.id, { newItem: this.pageService.newBlock })
              if (this.block) {
                this.stateService.updateState(this.blockId, { newItem: this.pageService.newBlock })
                this.revisionService.setupListeners(this.blockId)
              }
            }
          }
        }
      })
    this.subscription["roles"] = this.roleService.roles$
      .subscribe(() => {
        if (this.roleService.adminModePermission && this.adminContainerRef) {
          this.loadAdminModules()
          if (this.blockId) {
            // this.revisionService.setupListeners(this.block, this.blockId)
          }
        }
      })

    this.subscription["state"] = this.stateService.state$
      .subscribe(state => {
        this.state = state[this.blockId]
        if (this.adminMode && this.state && this.state.form) {
          this.childBlockIds = this.state.form.value.childBlockIds
        }
        if (!this.adminMode && this.block) {
          this.childBlockIds = this.block.childBlockIds
        }
        if (this.roleService.adminModePermission && this.adminContainerRef) {
          this.loadAdminModules()
        }
      })
  }

  ngOnDestroy(): void {
    for (const key in this.subscription) {
      this.subscription[key].unsubscribe()
    }
    /**
     * TODO: Move formService cleanup to occur when route changes.
     * TODO: this ngOnDestroy will never fire in normal appMode browsing.
     */
    // if (this.blockId) {
    //   this.revisionService.destroyListeners(this.blockId)
    // }
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  /**
   * get forms
   */

  // get childBlockIds(): string[] {
  //   return this.adminMode && this.state?.form
  //     ? this.state.form.value.childBlockIds
  //     : this.block.childBlockIds
  // }

  get pageTitle(): string {
    return this.adminMode && this.state?.form
      ? this.state.form.value.pageTitle
      : this.block.pageTitle
  }

  /**
   * get states
   */

  get session(): string {
    return this.stateService.session
  }

  get adminMode(): boolean {
    return this.stateService.adminMode
  }

  loadAdminModules(): void {
    if (!this.adminModuleLoaded) {
      this.adminModuleLoaded = true
      import("./multiblock-form.module").then(m => m.MultiblockFormModule)
      import("./admin/admin.module").then(m => m.AdminModule)
      this.buildService.loadAdminComponent(this.adminContainerRef)
    }
  }

  delete(blockId: string): void {
    this.formService.deleteBlock(blockId, [])
      .then(() => {
        //
      })
      .catch(() => {
        //
      })
  }
}
