import { Injectable } from "@angular/core"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { MapsAPILoader } from "@agm/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { Image, ImageOptions, ImageService } from "@aaa-web/app/modules/multiblock/fields/image/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import {
  BlockBase, BlockOption, BlockOptions, OptionsBase
} from "@aaa-web/app/modules/multiblock/services/block.service"
import {
  OfficeRegion, OfficeRegionOptions, OfficeRegionService
} from "@aaa-web/app/modules/multiblock/references/office-region/service"
import {
  OfficeServiceField, OfficeServiceOptions, OfficeServiceService
} from "@aaa-web/app/modules/multiblock/references/office-service/service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Address {
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
}

export interface Location {
  lat: number
  lon: number
}

export interface OfficeHour {
  start: number
  end: number
}

export interface Phone {
  label: string
  value: string
}

export interface Block extends BlockBase {
  fields: {
    addresses: Address[]
    announcements: QuillEditor[]
    locations: Location[]
    googlePlusAddresses: QuillEditor[]
    hours: OfficeHour[]
    images?: Image[]
    phones: Phone[]
    regions: OfficeRegion[]
    services: OfficeServiceField[]
    titles: QuillEditor[]
    yelpAddresses: QuillEditor[]
  }
}

export interface Options {
  block: BlockOptions
  addresses: AddressOptions
  announcements: QuillEditorOptions
  locations: LocationOptions
  googlePlusAddresses: QuillEditorOptions
  hours: OfficeHourOptions
  images: ImageOptions
  published: PublishedOptions
  phones: PhoneOptions
  regions: OfficeRegionOptions
  services: OfficeServiceOptions
  titles: QuillEditorOptions
  yelpAddresses: QuillEditorOptions
}

export interface AddressOptions extends OptionsBase {
  newForm: (item: Address) => AbstractControl
  newItem: Address
  options: []
}

export interface LocationOptions extends OptionsBase {
  newForm: (item: Location) => AbstractControl
  newItem: Location
  options: []
}

export interface OfficeHourOptions extends OptionsBase {
  newForm: (item: OfficeHour) => AbstractControl
  newItem: OfficeHour
  options: []
}

export interface PhoneOptions extends OptionsBase {
  newForm: (item: Phone) => AbstractControl
  newItem: Phone
  options: []
}

@Injectable({
  providedIn: "root"
})
export class OfficeService {
  blockType: BlockOption = "office"
  mapsLoaded: boolean

  constructor(
    private imageService: ImageService,
    private officeRegionService: OfficeRegionService,
    private officeServiceService: OfficeServiceService,
    private statusService: StatusService,
    private quillService: QuillService,
    private dataService: DataService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.mapsAPILoader.load().then(() => {
      this.mapsLoaded = true
    })
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      addresses: this.addressesOptions,
      announcements: this.announcementsOptions,
      locations: this.locationsOptions,
      googlePlusAddresses: this.googlePlusAddressesOptions,
      hours: this.officeHoursOptions,
      images: this.imagesOptions,
      published: this.publishedOptions,
      phones: this.phonesOptions,
      regions: this.regionsOptions,
      services: this.servicesOptions,
      titles: this.titlesOptions,
      yelpAddresses: this.yelpAddressesOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Offices",
        singular: "Office"
      },
    }
  }

  private get addressesOptions(): AddressOptions {
    return {
      formArray: {
        min: 0,
        max: 1
      },
      label: {
        placeholder: "office hour",
        plural: "Addresses",
        singular: "Address"
      },
      newForm: this.newAddressForm,
      newItem: this.newAddress,
      options: [],
    }
  }

  private get announcementsOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "announcement",
        plural: "Announcements",
        singular: "Announcement"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  get locationsOptions(): LocationOptions {
    return {
      formArray: {
        min: 0,
        max: 1
      },
      label: {
        placeholder: "location",
        plural: "Locations",
        singular: "Location"
      },
      newForm: this.newLocationForm,
      newItem: this.newLocation,
      options: [],
    }
  }

  get imagesOptions(): ImageOptions {
    const options: ImageOptions = {
      options: [],
      label: {
        placeholder: "image",
        plural: "Images",
        singular: "Image"
      },
      formArray: {
        alt: true,
        cropper: false,
        min: 0,
        max: 1,
      },
      newForm: this.newImageForm,
      newItem: this.newImage,
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        description: "Desktop 734x300",
        dimensions: "734x300",
        minWidth: {
          px: 734
        },
        maxWidth: {
          px: 734
        },
        minHeight: {
          px: 300
        },
        maxHeight: {
          px: 300
        },
        target: ["self", "new", "modal"]
      })
    }
    return options
  }

  private get titlesOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
      label: {
        placeholder: "title",
        plural: "Titles",
        singular: "Title"
      },
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        placement: {
          columns: [],
          rows: []
        },
      })
    }
    return options
  }

  get officeHoursOptions(): OfficeHourOptions {
    return {
      formArray: {
        min: 7,
        max: 7
      },
      label: {
        placeholder: "office hour",
        plural: "Office Hours",
        singular: "Office Hour"
      },
      newForm: this.newOfficeHourForm,
      newItem: this.newOfficeHour,
      options: [],
    }
  }

  get phonesOptions(): PhoneOptions {
    return {
      formArray: {
        min: 0,
        max: 9
      },
      label: {
        placeholder: "phone",
        plural: "Phones",
        singular: "Phone"
      },
      newForm: this.newPhoneForm,
      newItem: this.newPhone,
      options: [],
    }
  }

  get googlePlusAddressesOptions(): QuillEditorOptions {
    return {
      formArray: {
        min: 0,
        max: 1
      },
      label: {
        placeholder: "google plus address",
        plural: "Google Plus Addresses",
        singular: "Gooogle Plus Address"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
    }
  }

  get yelpAddressesOptions(): QuillEditorOptions {
    return {
      formArray: {
        min: 0,
        max: 1
      },
      label: {
        placeholder: "yelp address",
        plural: "Yelp Addresses",
        singular: "Yelp Address"
      },
      newForm: this.newQuillForm,
      newItem: this.newQuillItem,
      options: [],
    }
  }

  get regionsOptions(): OfficeRegionOptions {
    return {
      formArray: {
        min: 0,
        max: 9
      },
      label: {
        placeholder: "region",
        plural: "Regions",
        singular: "Region"
      },
      newForm: this.newRegionForm,
      newItem: this.newRegion,
      options: [],
    }
  }

  get servicesOptions(): OfficeServiceOptions {
    return {
      formArray: {
        min: 0,
        max: 9
      },
      label: {
        placeholder: "service",
        plural: "Services",
        singular: "Service"
      },
      newForm: this.newServiceForm,
      newItem: this.newService,
      options: [],
    }
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish"]
    }
  }

  get newQuillItem(): QuillEditor {
    return this.quillService.newItem
  }

  get newImage(): Image {
    return this.imageService.newItem
  }

  get newLocation(): Location {
    return {
      lat: 0,
      lon: 0,
    }
  }

  get newAddress(): Address {
    return {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    }
  }

  get newOfficeHour(): OfficeHour {
    return {
      start: 0,
      end: 0,
    }
  }

  get newPhone(): Phone {
    return {
      label: "",
      value: "",
    }
  }

  get newRegion(): OfficeRegion {
    return {
      tid: "",
      value: "",
    }
  }

  get newService(): OfficeServiceField {
    return {
      tid: "",
      value: "",
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        addresses: [],
        announcements: [],
        locations: [],
        googlePlusAddresses: [],
        hours: [],
        images: [],
        titles: [this.newQuillItem],
        phones: [],
        regions: [],
        services: [],
        yelpAddresses: [],
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  newQuillForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

  newImageForm = (item: Image): AbstractControl => {
    return this.imageService.newForm(item)
  }

  newAddressForm = (item: Address): AbstractControl => {
    return new FormGroup({
      address1: new FormControl((item.address1)),
      address2: new FormControl((item.address2)),
      city: new FormControl((item.city)),
      state: new FormControl((item.state)),
      zip: new FormControl((item.zip)),

    })
  }

  newLocationForm = (item: Location): AbstractControl => {
    return new FormGroup({
      lat: new FormControl((item.lat)),
      lon: new FormControl((item.lon)),
    })
  }

  newOfficeHourForm = (item: OfficeHour): AbstractControl => {
    return new FormGroup({
      end: new FormControl((item.end)),
      start: new FormControl((item.start)),
    })
  }

  newPhoneForm = (item: Phone): AbstractControl => {
    return new FormGroup({
      label: new FormControl((item.label)),
      value: new FormControl((item.value)),
    })
  }

  newRegionForm = (item: OfficeRegion): AbstractControl => {
    return this.officeRegionService.newForm(item)
  }

  newServiceForm = (item: OfficeServiceField): AbstractControl => {
    return this.officeServiceService.newForm(item)
  }

}
