<button
  nz-button
  [nzType]="filters.http ? 'default' : 'primary'"
  (click)="filterMessagesArray('http'); $event.preventDefault()"
  class="margin-5"
>
  HTTP Faults
</button>
<!--
<button
  (click)="filterMessagesArray('void'); $event.preventDefault()"
  *ngIf="window['metaData'].user.id === '1' || true"
  [ngClass]="{selected: filters.void}"
  class="button-2 margin-5 width-240"
  role="button"
>
  VOID Errors
</button>
-->
<button
  nz-button
  [nzType]="showDetails ? 'default' : 'primary'"
  (click)="showDetails = !showDetails; $event.preventDefault()"
  [style]="'margin: 5px'"
>
  Details
</button>
<div [style]="'max-height: calc(100vh - 200px); overflow: auto'">
  <ava-infinite-scroll (scrolled)="onScroll()">
    <div *ngFor="let message of messagesArray; let messageIndex = index">
      <div *ngIf="messageIndex < items">
        <div
          (click)="showMessage[message.index] = !showMessage[message.index]"
          class="clickable"
          [style]="'padding: 2px 0'"
        >
          {{ message.index }}.
          {{ message.id | date : 'medium' }}
          |
          {{ message.id }}
          {{ message.labels | json }}
        </div>
        <nz-modal
          [(nzVisible)]="showMessage[messageIndex]"
          (nzOnCancel)="showMessage[message.index] = false"
          (nzOnOk)="showMessage[message.index] = false"
          [nzFooter]="null"
        >
          <ava-emember-batch-dialog *nzModalContent [data]="message.records" [index]="message.index"></ava-emember-batch-dialog>
        </nz-modal>
        <ng-container *ngIf="showDetails">
          <div
            *ngFor="let record of message.records; let recordIndex = index"
            [style]="'padding-left: 20px; padding-top: 2px; padding-bottom: 2px;'"
          >
            <div [style]="'display: flex'">

              <div class="padding-right-20">
                {{ recordIndex + 1 }}.
              </div>

              <div class="width-360" style="overflow-y: scroll">
                <div
                  *ngIf="record.label || record.label === ''"
                  style="display: flex"
                >
                  <div class="width-120">
                    label:
                  </div>
                  <div>
                    {{ record.label }}
                  </div>
                </div>
                <div
                  *ngIf="record.description || record.description === ''"
                  style="display: flex"
                >
                  <div class="width-120">
                    description:
                  </div>
                  <div>
                    {{ record.description }}
                  </div>
                </div>
                <div
                  *ngIf="record.class || record.class === ''"
                  style="display: flex"
                >
                  <div class="width-120">
                    class:
                  </div>
                  <div>
                    {{ record.class }}
                  </div>
                </div>
                <div
                  *ngIf="record.function || record.function === ''"
                  style="display: flex"
                >
                  <div class="width-120">
                    function:
                  </div>
                  <div>
                    {{ record.function }}
                  </div>
                </div>
              </div>

              <div *ngIf="record.data" class="padding-left-30">

                <!--membershipDetails-->
                <div
                  *ngIf="record.data.membershipDetails?.autoRenewalFlag"
                  style="display: flex"
                >
                  <div class="width-240">
                    autoRenewalFlag:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.autoRenewalFlag }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.membershipDetails?.totalCost"
                  style="display: flex"
                >
                  <div class="width-240">
                    totalCost:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.totalCost | currency }}
                  </div>
                </div>

                <!--membershipDetails.Membership.PrimaryMember-->
                <div
                  *ngIf="record.data.membershipDetails?.Membership?.PrimaryMember"
                  style="display: flex"
                >
                  <div class="width-240">
                    Primary Member Name:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.Membership?.PrimaryMember?.firstName }}
                    {{ record.data.membershipDetails?.Membership?.PrimaryMember?.lastName }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.membershipDetails?.Membership?.PrimaryMember"
                  style="display: flex"
                >
                  <div class="width-240">
                    Primary Member Number:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.Membership?.PrimaryMember?.membershipNumber }}
                  </div>
                </div>

                <!--membershipDetails.Membership.AssociateMember-->
                <div
                  *ngIf="record.data.membershipDetails?.Membership?.AssociateMember"
                  style="display: flex"
                >
                  <div class="width-240">
                    Associate Member Name:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.Membership?.AssociateMember?.firstName }}
                    {{ record.data.membershipDetails?.Membership?.AssociateMember?.lastName }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.membershipDetails?.Membership?.AssociateMember"
                  style="display: flex"
                >
                  <div class="width-240">
                    Associate Member Number:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.Membership?.AssociateMember?.membershipNumber }}
                  </div>
                </div>

                <!--membershipDetails.Membership.payment-->
                <div
                  *ngIf="record.data.membershipDetails?.Membership?.payment"
                  style="display: flex"
                >
                  <div class="width-240">
                    Payment AccountHolder:
                  </div>
                  <div>
                    {{ record.data.membershipDetails?.Membership?.payment?.account?.accountHolder?.firstName }}
                    {{ record.data.membershipDetails?.Membership?.payment?.account?.accountHolder?.lastName }}
                  </div>
                </div>

                <!--emember-->
                <div
                  *ngIf="record.data.transactionDetails?.sessionId || record.data.transactionDetails?.sessionId === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    sessionId:
                  </div>
                  <div>
                    {{ record.data.transactionDetails?.sessionId }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.transactionDetails?.transId || record.data.transactionDetails?.transId === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    transId:
                  </div>
                  <div>
                    {{ record.data.transactionDetails?.transId }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.transactionDetails?.name || record.data.transactionDetails?.name === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    name:
                  </div>
                  <div>
                    {{ record.data.transactionDetails?.name | stringReplace: ['%20', ' '] }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.transactionDetails?.customer_email || record.data.transactionDetails?.customer_email === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    customer_email:
                  </div>
                  <div>
                    {{ record.data.transactionDetails?.customer_email }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.transactionDetails?.amount || record.data.transactionDetails?.amount === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    amount:
                  </div>
                  <div>
                    {{ record.data.transactionDetails?.amount }}
                  </div>
                </div>


                <div
                  *ngIf="record.data.paymentResult?.sessionId || record.data.paymentResult?.sessionId === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    sessionId:
                  </div>
                  <div>
                    {{ record.data.paymentResult?.sessionId }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentResult?.transId || record.data.paymentResult?.transId === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    transId:
                  </div>
                  <div>
                    {{ record.data.paymentResult?.transId }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentResult?.name || record.data.paymentResult?.name === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    name:
                  </div>
                  <div>
                    {{ record.data.paymentResult?.name | stringReplace: ['%20', ' '] }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentResult?.customer_email || record.data.paymentResult?.customer_email === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    customer_email:
                  </div>
                  <div>
                    {{ record.data.paymentResult?.customer_email }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentResult?.amount || record.data.paymentResult?.amount === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    amount:
                  </div>
                  <div>
                    {{ record.data.paymentResult?.amount }}
                  </div>
                </div>


                <div
                  *ngIf="record.data.result?.message || record.data.result?.message === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    message:
                  </div>
                  <div>
                    {{ record.data.result?.message }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.result?.responseCode || record.data.result?.responseCode === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    responseCode:
                  </div>
                  <div>
                    {{ record.data.result?.responseCode }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.responseCode || record.data.responseCode === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    responseCode:
                  </div>
                  <div>
                    {{ record.data.responseCode }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.result?.responseText || record.data.result?.responseText === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    responseText:
                  </div>
                  <div>
                    {{ record.data.result?.responseText }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.result?.Success || record.data.result?.Success === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    Success:
                  </div>
                  <div>
                    {{ record.data.result?.Success }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.error?.faultcode || record.data.error?.faultcode === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    faultcode:
                  </div>
                  <div>
                    {{ record.data.error?.faultcode }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.error?.faultstring || record.data.error?.faultstring === ''"
                  style="display: flex"
                >
                  <div class="width-240">
                    faultstring:
                  </div>
                  <div>
                    {{ record.data.error?.faultstring }}
                  </div>
                </div>

                <!--ConnectSuite Payment Object-->
                <div
                  *ngIf="record.data.memberNumber"
                  style="display: flex"
                >
                  <div class="width-240">
                    memberNumber:
                  </div>
                  <div>
                    {{ record.data.memberNumber }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentObject?.transaction['orderId']"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    orderId:
                  </div>
                  <div>
                    {{ record.data.paymentObject?.transaction['orderId'] }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentObject?.account['cardHolderName']"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    cardHolderName:
                  </div>
                  <div>
                    {{ record.data.paymentObject?.account['cardHolderName'] }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentObject?.autoRenew"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    autoRenew:
                  </div>
                  <div>
                    {{ record.data.paymentObject?.autoRenew }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.paymentObject?.amount"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    amount:
                  </div>
                  <div>
                    {{ record.data.paymentObject?.amount | currency }}
                  </div>
                </div>

                <!--CybersourcePaymentError-->
                <div
                  *ngIf="record.data.responseBody?.status"
                  style="display: flex"
                >
                  <div class="width-240">
                    status:
                  </div>
                  <div>
                    {{ record.data.responseBody?.status }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.responseBody?.message"
                  style="display: flex"
                >
                  <div class="width-240">
                    message:
                  </div>
                  <div>
                    {{ record.data.responseBody?.message }}
                  </div>
                </div>
                <ng-container *ngFor="let details of record.data.responseBody?.details">
                  <div
                    *ngIf="details['field']"
                    [style]="'display: flex'"
                  >
                    <div class="width-240">
                      field:
                    </div>
                    <div>
                      {{ details['field'] }}
                    </div>
                  </div>
                  <div
                    *ngIf="details['reason']"
                    [style]="'display: flex'"
                  >
                    <div class="width-240">
                      reason:
                    </div>
                    <div>
                      {{ details['reason'] }}
                    </div>
                  </div>
                </ng-container>

                <!--deprecated CybersourcePaymentError-->
                <div
                  *ngIf="record.data.status"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    status:
                  </div>
                  <div>
                    {{ record.data.status }}
                  </div>
                </div>
                <div
                  *ngIf="record.data.message"
                  [style]="'display: flex'"
                >
                  <div class="width-240">
                    message:
                  </div>
                  <div>
                    {{ record.data.message }}
                  </div>
                </div>
                <ng-container *ngFor="let details of record.data.details">
                  <div
                    *ngIf="details['field']"
                    [style]="'display: flex'"
                  >
                    <div class="width-240">
                      field:
                    </div>
                    <div>
                      {{ details["field"] }}
                    </div>
                  </div>
                  <div
                    *ngIf="details['reason']"
                    [style]="'display: flex'"
                  >
                    <div class="width-240">
                      reason:
                    </div>
                    <div>
                      {{ details["reason"] }}
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ava-infinite-scroll>
</div>

<button
  nz-button
  [nzType]="'primary'"
  (click)="loadAllItems(100); $event.preventDefault()"
  [style]="'position: fixed; top: 130px; right: 40px'"
>
  {{ items }} of {{ messagesArray.length }} : Show All
</button>
<button
  nz-button
  [nzType]="'primary'"
  (click)="reset(); $event.preventDefault()"
  [style]="'position: fixed; top: 180px; right: 40px'"
>
  delete all messages
</button>
<button
  nz-button
  [nzType]="'primary'"
  (click)="deleteDocs(1); $event.preventDefault()"
  [style]="'position: fixed; top: 230px; right: 40px'"
>
  delete the oldest {{ oldestMessagesDocLength }} messages
</button>
