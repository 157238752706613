import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinRenewComponent, PrettyJsonPipe } from "@aaa-web/app/modules/join-renew/join-renew.component"
import { RouterModule, Routes } from "@angular/router"
// import { CybersourceModule } from "./cybersource/cybersource.module"
// import { Shift4Module } from "./shift4/shift4.module"

const routes: Routes = [
  {
    path: "",
    component: JoinRenewComponent
  }
]

@NgModule({
  declarations: [
    JoinRenewComponent,
    PrettyJsonPipe
  ],
  exports: [
    JoinRenewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // CybersourceModule,
    // Shift4Module
  ],
  providers: [
    // CybersourceModule,
    // Shift4Module
  ]
})
export class JoinRenewModule { }
