import { Component } from "@angular/core"

@Component({
  selector: "ava-join-renew-custom-element",
  template: `
    <ava-join-renew></ava-join-renew>
  `
})
export class JoinRenewCustomElementComponent {

}
