import { Injectable } from "@angular/core"
import { environment } from "@aaa-web/environments/environment"
import { NavigationEnd, Router } from "@angular/router"
import {
  Action, AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentData, DocumentSnapshot
} from "@angular/fire/firestore"
import firebase from "firebase/app"
import { filter, map, switchMap, tap } from "rxjs/operators"
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { Multiblock } from "./block.service"
import { Block as PageBlock } from "../containers/page/service"

@Injectable({
  providedIn: "root"
})
export class DataService {
  pageBlock: PageBlock
  pathNameFilterBehaviorSubject: BehaviorSubject<string>
  multiblockRef: AngularFirestoreCollection<Multiblock>
  blocksArray$: Observable<Multiblock[]>
  blocksBehaviorSubject: BehaviorSubject<{ [key: string]: Multiblock }>
  blocks$: Observable<{ [key: string]: Multiblock }>
  blocksArray: Multiblock[] = []
  blocks: { [key: string]: Multiblock } = {}
  session: string

  constructor(
    private domWindow: Window,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.session = this.createId
    this.pathNameFilterBehaviorSubject = new BehaviorSubject(null)
    this.blocksBehaviorSubject = new BehaviorSubject<{ [key: string]: Multiblock }>({})
    this.blocks$ = this.blocksBehaviorSubject.asObservable()
    this.blocksArray$ = this.pathNameFilterBehaviorSubject
      .pipe(
        switchMap(pathName => {
          if (pathName) {
            return this.getContentCollection(pathName)
          }
          return of(null)
        }),
        tap(blocks => {
          const blocksObject: { [key: string]: Multiblock } = {}
          if (blocks) {
            for (const block of blocks) {
              /**
               * TODO: we should delete id here, this is the entry-point
               * we need to refactor all components to pass in blockId if they need it, and never rely on block.id for the id
               * ...unless they get the block from the blockArray$ observable, those have their original block.id values
               * We remove the block.id here so that our forms match our stored data (block.id is not stored)
               */
              // const blockObject = JSON.parse(JSON.stringify(block))
              // delete blockObject.id
              blocksObject[block.id] = block
            }
          }
          this.blocksBehaviorSubject.next(blocksObject)
          this.blocks = blocksObject
        })
      )
    // .pipe(
    //   startWith(null),
    //   pairwise(),
    //   map(([prev, active]) => {
    //
    //     return { prev: prev, active: active }
    //   }),
    // )

    this.multiblockRef = this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock")


    if (environment.emulator || environment.ngServe || this.webEnv === "appTest") {
      /**
       * whenever a route change occurs, rebuild the contentCollection with page docs for the new route.
       */
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          const pathName = event.urlAfterRedirects
          // console.log(event.url.split("?")[0])
          // console.log(this.pathName)
          this.pathNameFilterBehaviorSubject.next(event.url.split("?")[0])
        })
    } else {
      this.pathNameFilterBehaviorSubject.next(this.pathName)

      /**
       * This is a firebase analytics event, not the Drupal configured analytics event.
       *
       * Disabled the following event because we turned on global pageView logging (in custom-element.module.ts).
       * Drupal reloads every page anyways, and we should log page_views to represent actual app loads.
       *
       * Will want to add "screen_view" events separately, for example:
       *   with router event changes in appMode
       *   for the agent-scheduler steps
       *   battery quoter results and confirmation
       *   etc, etc
       */
      /*
            const analyticsPageViewEvent: AnalyticsPageViewEvent = {
              eventName: "page_view",
              eventParams: {
                page_title: document.title,
                page_location: window.location.href,
                page_path: this.pathName,
              }
            }
            this.analyticsService.sendFirebasePageViewEvent(analyticsPageViewEvent)
      */
    }

  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  get clubId(): string {
    return this.window.metaData.clubId
  }

  get pathName(): string {
    return this.window.location.pathname.replace("homepage", "")
  }

  get webEnv(): string {
    return this.window.metaData.webEnv
  }

  get createId(): string {
    return this.afs.createId()
  }

  getContentCollection(pathName: string): Observable<Multiblock[]> {
    /**
     * TODO: get nodes with pathname url
     * TODO: map node and term data into a "block"
     * TODO: detect all blocks not in "childBlocks" array, and add them to the "childBlocks" array
     *
     * TODO-REVISED: do not add orphan blocks to childBlocks array, they may belong to a column field blockRef (NEW)
     */
    const resultsA: Observable<DocumentChangeAction<DocumentData>[]> = this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        query = query
          .where("pathName", "==", pathName)
        return query
      })
      .snapshotChanges()
    const resultsB: Observable<DocumentChangeAction<DocumentData>[]> = this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        query = query
          .where("pathnames", "array-contains-any", [pathName])
        return query
      })
      .snapshotChanges()

    return combineLatest([resultsA, resultsB])
      .pipe(
        map(([a, b]) => [...a, ...b])
      )
      .pipe(
        map(documents => {
          this.blocksArray = this.pipeDataTransform(documents)
          // console.log(this.blocks)
          return this.blocksArray
        })
      )
  }

  getBlockEditingDocument(blockId: string): Observable<Action<DocumentSnapshot<DocumentData>>> {
    return this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock",)
      .doc(blockId)
      .collection("revisions")
      .doc("edits")
      .snapshotChanges()
  }

  writeBlockEditingDocument(block: Multiblock, blockId: string): void {
    const blockCopy = JSON.parse(JSON.stringify(block))
    delete blockCopy.id
    this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock",)
      .doc(blockId)
      .collection("revisions")
      .doc("edits")
      .set(blockCopy)
      .then(() => {
        // console.log("writeBlockEditingDocument()")
      })
  }

  deleteBlockEditingDocument(blockId: string): void {
    this.afs
      .collection("wss-aaa-web")
      .doc(this.clubId)
      .collection("multiblock",)
      .doc(blockId)
      .collection("revisions")
      .doc("edits")
      .delete()
      .then(() => {
        //
      })
  }

  pipeDataTransform(documents: (DocumentChangeAction<DocumentData>)[]): Multiblock[] {
    return documents.map(document => {
      const data = document.payload.doc.data()
      const id = document.payload.doc.id
      delete data["id"]

      /**
       * Drupal generated blocks will omit multiblock managed fields.
       * We need to create those fields here, if they don't already exist.
       * These can be removed when we no longer import live data from Drupal
       */
      if (data.blockType === "office") {
        data["fields"].images = data["fields"].images || []
      }


      /**
       * begin data migration scripts
       * these can be removed when all content is known to have been updated
       */

      // delete data["parentBlockId"] // deprecated, removing here to catch any old data

      /**
       * javascript map into javascripts array of javascript maps
       * Only Logiform multiblock is affected.
       */
      // if (data.blockType === "logiform" && data["fields"].javascript) {
      //   data["fields"].javascripts = data["fields"].javascripts || []
      //   if (data["fields"].javascripts.length === 0) {
      //     data["fields"].javascripts.push(data["fields"].javascript)
      //     delete data["fields"].javascript
      //   }
      // }

      /**
       * new javascript data fields
       * background and position data objects
       */
      // if (data.blockType === "banner" && data["fields"].javascripts.length) {
      //   data["fields"].javascripts.forEach((javascript, index) => {
      //     data["fields"].javascripts[index].position = data["fields"].javascripts[index].position || {}
      //     data["fields"].javascripts[index].width = data["fields"].javascripts[index].width || {}
      //     data["fields"].javascripts[index].background = data["fields"].javascripts[index].background || {}
      //   })
      // }

      /**
       * change elementType.file to elementType.element
       * Banner and OneColText and Title are affected.
       */
      // if (data.blockType === "banner" || data.blockType === "oneColText" || data.blockType === "title") {
      //   data["fields"].titles?.forEach((title, index) => {
      //     if (title.file) {
      //       data["fields"].titles[index].element = title.file
      //       delete data["fields"].titles[index].file
      //     }
      //   })
      //   data["fields"].subtitles?.forEach((subtitle, index) => {
      //     if (subtitle.file) {
      //       data["fields"].subtitles[index].element = subtitle.file
      //       delete data["fields"].subtitles[index].file
      //     }
      //   })
      // }
      /**
       * remove images if they don't have a fileType attribute
       * replace path, src, and target with targetPath, filePath, and targetOption
       * Banner and Join and Office are affected.
       */
      // if (data.blockType === "banner" || data.blockType === "join") {
      //   for (const imageIndex in data["fields"].images) {
      //     if (data["fields"].images[imageIndex].fileType === undefined || data["fields"].images[imageIndex].fileType === null) {
      //       data["fields"].images[imageIndex] = {
      //         filePath: "",
      //         fileType: ""
      //       }
      //     }
      //   }
      // }
      // if (data.blockType === "office") {
      //   for (const imageIndex in data["fields"].images) {
      //     if (!data["fields"].images[imageIndex].fileType) {
      //       data["fields"].images[imageIndex] = {
      //         alt: data["fields"].images[imageIndex].alt || "",
      //         filePath: "",
      //         fileType: "",
      //         targetOption: data["fields"].images[imageIndex].target || "self",
      //         targetPath: data["fields"].images[imageIndex].path || ""
      //       }
      //     }
      //   }
      // }
      /**
       * change target from same to self
       * ### affected.
       */
      // if (data.blockType === "banner" || data.blockType === "join" || data.blockType === "office") {
      //   for (const imageIndex in data["fields"].images) {
      //     if (data["fields"].images[imageIndex].targetOption === "same") {
      //       data["fields"].images[imageIndex].targetOption = "self"
      //     }
      //   }
      /**
       * TODO: catch more targets, maybe rename all of them "targetOption" and "targetPath"
       */
      // }
      /**
       * change width.px: number to width: number
       * Banner is affected.
       */
      // if (data.blockType === "banner") {
      //   data["fields"].javascripts?.forEach((element, index) => {
      //     if (element.width.px) {
      //       data["fields"].javascripts[index].width = element.width.px
      //     }
      //   })
      // }

      /**
       * 21.11.18
       * BoxModel replaces padding and margin
       * Banner is affected.
       */
      // if (data.blockType === "banner" && data["options"].boxModel === undefined) {
      //   const boxModel = {
      //     margin: { bottom: { px: 0 }, left: { px: 0 }, right: { px: 0 }, top: { px: 0 } },
      //     padding: { bottom: { px: 0 }, left: { px: 0 }, right: { px: 0 }, top: { px: 0 } }
      //   }
      //   if (data["options"].marginBottom) {
      //     switch (data["options"].marginBottom) {
      //       case "thin":
      //         boxModel.margin.bottom = { px: 16 }
      //         break
      //       case "large":
      //         boxModel.margin.bottom = { px: 32 }
      //         break
      //       default:
      //         boxModel.margin.bottom = { px: 0 }
      //     }
      //     delete data["options"].marginBottom
      //   }
      //   if (data["options"].marginTop) {
      //     switch (data["options"].marginTop) {
      //       case "thin":
      //         boxModel.margin.top = { px: 16 }
      //         break
      //       case "large":
      //         boxModel.margin.top = { px: 32 }
      //         break
      //       default:
      //         boxModel.margin.top = { px: 0 }
      //     }
      //     delete data["options"].marginTop
      //   }
      //   if (data["options"].paddingBottom) {
      //     switch (data["options"].paddingBottom) {
      //       case "thin":
      //         boxModel.padding.bottom = { px: 24 }
      //         break
      //       case "large":
      //         boxModel.padding.bottom = { px: 48 }
      //         break
      //       default:
      //         boxModel.padding.bottom = { px: 0 }
      //     }
      //     delete data["options"].paddingBottom
      //   }
      //   if (data["options"].paddingTop) {
      //     switch (data["options"].paddingTop) {
      //       case "thin":
      //         boxModel.padding.top = { px: 24 }
      //         break
      //       case "large":
      //         boxModel.padding.top = { px: 48 }
      //         break
      //       default:
      //         boxModel.padding.top = { px: 0 }
      //     }
      //     delete data["options"].paddingTop
      //   }
      //   data["options"].boxModel = boxModel
      // }

      /**
       * 21.11.23
       * add personalize object to status
       * All blocks are affected.
       */
      // data.status.personalize = data.status.personalize || {}

      /**
       * 21.11.24
       * convert published option into separate booleans
       * All blocks are affected.
       */
      // data.status.deleting = data.status.deleting || data.status["published"] === "deleting"
      // data.status.personalized = data.status.personalized || data.status["published"] === "personalized"
      // data.status.preSaved = data.status.preSaved || data.status["published"] === "preSaved"
      // data.status.protected = data.status.protected || data.status["published"] === "protected"
      // data.status.unpublished = data.status.unpublished || data.status["published"] === "unpublished"
      // delete data.status["published"]

      /**
       * 21.12.06
       * add columns option
       * Banner, LargeBanner, and Feature blocks are affected.
       */
      // if (data.blockType === "banner" || data.blockType === "bannerLarge" || data.blockType === "feature") {
      //   data["options"].columns = data["options"].columns || []
      // }

      /**
       * 21.12.10
       * refactor columns into columnWidths
       * Banner, Large Banner, and Feature blocks are affected.
       */
      // if (data.blockType === "banner" || data.blockType === "bannerLarge" || data.blockType === "feature") {
      //   data["options"].columnWidths = data["options"].columnWidths || data["options"].columns || []
      //   delete data["options"].columns
      // }

      /**
       * 21.12.10
       * remove javascript position and width
       * Banner, Large Banner, Feature, and Logiform blocks are affected.
       */
      // if (data.blockType === "banner" || data.blockType === "bannerLarge" || data.blockType === "feature" || data.blockType === "logiform") {
      //   for (const index in data["fields"].javascripts) {
      //     if (data["fields"].javascripts[index].position) {
      //       delete data["fields"].javascripts[index].position
      //     }
      //     if (data["fields"].javascripts[index].width) {
      //       delete data["fields"].javascripts[index].width
      //     }
      //   }
      // }

      // if (data["fields"]?.images?.length) {
      //   for (const index in data["fields"].images) {
      //     data["fields"].images[index].targetOption = data["fields"].images[index].targetOption || "self"
      //     data["fields"].images[index].targetPath = data["fields"].images[index].targetPath || ""
      //   }
      // }

      /**
       * 21.12.20
       */
      // if (data.blockType === "container" && data["containerType"] === "page") {
      //   data["pageTitle"] = data["pageTitle"] || ""
      //   data["pageType"] = data["pageType"] || ""
      //   data["breadcrumbTitle"] = data["breadcrumbTitle"] || ""
      //   if (data["breadcrumbPaths"] !== undefined) {
      //     delete data["breadcrumbPaths"]
      //   }
      //   if (data["pageBreadcrumb"] !== undefined) {
      //     delete data["pageBreadcrumb"]
      //   }
      // }
      // if (data.blockType === "feature") {
      //   if (data["fields"].images[0]) {
      //     data["fields"].images[0].altText = data["fields"].images[0].altText || ""
      //   }
      // }
      // data.pathName = data.pathName || data["pathnames"][0]
      // delete data["pathnames"]


      // console.log(data.status.personalized)

      /**
       * end of data migration scripts
       * finalize tne data object
       */

      // console.log(data["options"])

      /**
       * set "status.session" key to local session
       */
      data.status.session = this.session

      /**
       * save id and data to blocks array
       */
      return { id, ...data } as Multiblock
    })
  }
}
