import { Component, Input } from "@angular/core"

@Component({
  selector: "ava-agent-scheduler-custom-element",
  template: `
    <ava-agent-scheduler [params]="params"></ava-agent-scheduler>
  `
})
export class AgentSchedulerCustomElementComponent {
  @Input() params: string
}
