<ng-container *ngIf="block">
  <h1
    *ngIf="pageTitle"
    style="font-size: 0; width: 1px; height: 1px; display: inline-block; overflow: hidden; position: absolute; clip: rect(1px,1px,1px,1px);"
  >
    {{ pageTitle }}
  </h1>
  <ava-page-view
    *ngFor="let blockId of childBlockIds"
    [blockId]="blockId"
  ></ava-page-view>
</ng-container>
<!--Load admin component if user has permissions-->
<ng-container #adminContainer></ng-container>

<!--
<nz-drawer
  [nzClosable]="true"
  [nzVisible]="true"
  [nzPlacement]="'bottom'"
  [nzMask]="false"
  [nzMaskClosable]="true"
  [nzCloseOnNavigation]="false"
  nzTitle="Basic Drawer"
>
  <ng-container *nzDrawerContent>
    <p>Some contents...</p>
    <p>Some contents...</p>
  </ng-container>
</nz-drawer>
-->

<!--create modal/drawer stuff-->
<!--
<ng-container *ngIf="block">
  <nz-drawer
    [(nzVisible)]="formService.showCreateModal"
    [nzPlacement]="'left'"
    [nzContent]="createModalContent"
    [nzFooter]="createModalFooter"
    [nzWidth]="400"
    (nzOnClose)="createModalCancel()"
  >
  </nz-drawer>

  <ng-template #createModalContent>
    <div [style.padding]="'10px 16px'" [style.border-bottom]="'2px solid #f0f0f0'">
      <ng-container [ngTemplateOutlet]="createModalFooter"></ng-container>
    </div>
    <div [style]="'padding: 16px 0; display: grid'">
      <ava-create-new-block [block]="this.dataService.pageBlock" [createNewBlock]="true"></ava-create-new-block>
    </div>
  </ng-template>

  <ng-template #createModalFooter>
    <div [style]="'display: grid; justify-content: center; grid-template-columns: auto auto; gap: 16px'">
      <button
        nz-button nzType="primary"
        [disabled]="!formService.blockOptionSelected"
        (click)="createModalOk()"
      >
        Create {{ blockService.blockTypes[formService.blockOptionSelected]?.label.singular }} Block
      </button>
      <button
        nz-button nzType="default"
        [style.margin.px]="0"
        (click)="createModalCancel()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</ng-container>
-->
