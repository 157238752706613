import { Injectable } from "@angular/core"
import { BlockBase, BlockOption } from "../../services/block.service"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { ThemeOption } from "@aaa-web/app/modules/multiblock/options/theme/service"
import { AlignmentOption } from "@aaa-web/app/modules/multiblock/options/alignment/service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"

export interface Block extends BlockBase {
  fields: {
    wysiwyg: {
      value: string
    }
  }
  options: {
    alignment: AlignmentOption
    theme: ThemeOption
  }
}

@Injectable({
  providedIn: "root"
})
export class UnrestrictedService {
  blockType: BlockOption = "unrestricted"

  constructor(
    private dataService: DataService,
    private statusService: StatusService,
  ) {
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish", "protect"]
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        wysiwyg: {
          value: ""
        }
      },
      options: {
        alignment: "center",
        theme: "title1",
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

/*
  newForm = (block: Block): BlockStateBase => {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          wysiwyg: new FormGroup({
            value: new FormControl(block.fields.wysiwyg.value)
          }),
        }),
        options: new FormGroup({
          theme: new FormControl(block.options.theme),
        }),
        pathnames: new FormArray(block.pathnames.map(item => new FormControl(item))),
        status: this.statusService.newForm(block.status)
      })
    }
  }
*/

}
