import { ComponentFactoryResolver, Injectable, TemplateRef, Type, ViewContainerRef } from "@angular/core"
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import WriteBatch = firebase.firestore.WriteBatch
import { AngularFirestore } from "@angular/fire/firestore"
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs"
import { NzMessageService } from "ng-zorro-antd/message"
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal"
import { DataService } from "./data.service"
import { BlockService, Multiblock } from "./block.service"
import { StateService, State } from "./state.service"
import { Block as PageBlock, PageService } from "../containers/page/service"
import { RoleService } from "@aaa-web/app/core/services/role.service"

export interface FormArrayOptions {
  min: number
  max: number
}

@Injectable({
  providedIn: "root"
})
export class FormService {
  private batch: { [key: string]: WriteBatch } = {}
  private saveBlockBehaviorSubject: BehaviorSubject<string>
  saveBlock$: Observable<string>
  blockRoleSubscription: Subscription
  private modalRef: NzModalRef
  form: FormGroup

  constructor(
    private afs: AngularFirestore,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private blockService: BlockService,
    private modalService: NzModalService,
    private pageService: PageService,
    private roleService: RoleService,
    private stateService: StateService,
    private nzMessageService: NzMessageService,
  ) {
    this.saveBlockBehaviorSubject = new BehaviorSubject<string>("")
    this.saveBlock$ = this.saveBlockBehaviorSubject.asObservable()

    this.blockRoleSubscription = combineLatest([this.roleService.roles$, this.dataService.blocksArray$])
      .subscribe(([roles, blocks]) => {

        if (roles.administrator && blocks) {
          const pageBlocks = blocks.filter(block => block.blockType === "container" && (block as PageBlock).containerType === "page") as PageBlock[]

          /**
           * TODO: move this to a UI trigger, instead of being automatic
           * Create pageBlock if it is missing.
           */
          /*
                    if (!pageBlocks.length) {
                      console.log("saveNewBlock")
                      this.saveNewBlock(this.pageService.newBlock, null)
                        .then(() => {
                          // console.log("created new page block")
                        })
                    }
          */

          for (const pageBlock of pageBlocks) {
            const pageBlockForm = this.stateService.state[pageBlock.id]?.form
            if (pageBlockForm) {
              const formChildBlockIds = pageBlockForm.value.childBlockIds

              for (const childBlockId of formChildBlockIds) {
                if (!blocks.find(block => block.id === childBlockId)) {
                  /**
                   * We found a form childBlockId that references a missing block.
                   * Remove the childBlockId.
                   */
                  const childBlockIdIndex = formChildBlockIds.indexOf(childBlockId)
                  const formArray = pageBlockForm.get("childBlockIds") as FormArray
                  formArray.removeAt(childBlockIdIndex)
                }
              }

              blocks
                .forEach(block => {
                  if (block.id !== pageBlock.id && !formChildBlockIds.find(childBlockId => childBlockId === block.id)) {
                    /**
                     * TODO: remove the following, orphan blocks may belong to a nested column field
                     *
                     * We found a block reference that is missing from both:
                     *   the form childBlockIds array
                     *   the block childBlockIds array
                     *
                     * Add the new blockId to the form array.
                     * Change the new block.status.published to "preSaved", if it is not already.
                     *
                     * This block will now show up in the EditMode as if it was just created.
                     */
                    /*
                                        const formArray = pageBlockForm.get("childBlockIds") as FormArray
                                        if (!formArray.value.find(id => id === block.id) && !pageBlock.childBlockIds.find(id => id === block.id)) {
                                          formArray.insert(formArray.length, new FormControl(block.id))

                                          if (!block.status.preSaved) {
                                            block.status.preSaved = true
                                            this.updateDocs([block], [block.id])
                                              .then(() => {
                                                //
                                              })
                                          }
                                        }
                    */
                  }
                })
            }

          }

          for (const pageBlock of pageBlocks) {
            for (const childBlockId of pageBlock.childBlockIds) {
              if (!blocks.find(block => block.id === childBlockId)) {
                /**
                 * We found a pageBlock childBlockId that references a missing block.
                 * Remove the childBlockId.
                 */
                const childBlockIdIndex = pageBlock.childBlockIds.indexOf(childBlockId)
                pageBlock.childBlockIds.splice(childBlockIdIndex, 1)
                this.updateDocs([pageBlock], [pageBlock.id])
                  .then(() => {
                    //
                  })
              }
            }
          }

        }
      })
  }

  /**
   * get states
   */

  private get state(): State {
    return this.stateService.state
  }

  get adminMode(): boolean {
    return this.stateService.adminMode
  }

  get previewMode(): boolean {
    return this.stateService.previewMode
  }

  /**
   * get forms
   */


  /**
   * helper methods
   */

  get createId(): string {
    return this.afs.createId()
  }

  updateDocs(blocks: Multiblock[], blockIds: string[]): Promise<void> {
    // console.log("formService-updateDocs")
    const batchId = this.afs.createId()
    this.batch[batchId] = this.afs.firestore.batch()
    blocks.forEach((block, index) => {
      block.status.session = this.dataService.session
      this.batch[batchId]
        .set(this.dataService.multiblockRef.doc(blockIds[index]).ref, block)
    })
    return this.batch[batchId].commit()
  }

  deleteBlocks(blocks: Multiblock[], blockIds: string[]): void {
    /**
     * for now we don't use the blocks parameter for anything
     * we'll use blocks.childBlockIds when we are deleting containers with nested blocks
     */
    for (const blockId of blockIds) {
      /**
       * find parentBlockId for each of the blocks
       */
      // const parentBlock = this.dataService.blocks.find(parentBlock => {
      //   if (parentBlock.blockType === "container" && (parentBlock as PageBlock).containerType === "page") {
      //     return (parentBlock as PageBlock).childBlockIds.find(parentBlockId => parentBlockId === blockId)
      //   }
      // })

      // if (parentBlock) {
      this.deleteBlock(blockId, [])
        .then(() => {
          this.stateService.resetState(blockId)
          /*
                      const formArray = this.stateService.state[parentBlock.id].form.get("childBlockIds") as FormArray
                      const index = formArray.value.findIndex(index => index === blockId)
                      // console.log(index)
                      // console.log(formArray)
                      formArray.removeAt(index)
          */
          // this.stateService.compareBlockState(this.dataService.blocks.find(dataServiceBlock => dataServiceBlock.id === parentBlock.id))
        })
      // }
    }
  }

  deleteBlock(blockId: string, childBlockIds: string[] | undefined): Promise<void> {
    this.batch[blockId] = this.afs.firestore.batch()
    /**
     * Remove childBlockId reference from parent block.
     */
    // this.batch[blockId].update(this.dataService.multiblockRef.doc(parentBlockId).ref, {
    //   childBlockIds: FieldValue.arrayRemove(blockId)
    // })
    /**
     * TODO: find any nested column field blockRef, may need to recursively delete it
     *
     * Delete its child block.
     * TODO: consider any other cleanup that may be needed when deleting this content.
     */
    this.batch[blockId].delete(this.dataService.multiblockRef.doc(blockId).ref)
    /**
     * Delete all the child's children if there are any.
     * This will occur when a container is deleted, and it still has child blocks.
     * TODO: consider any other cleanup that may be needed when deleting this content.
     * TODO: convert this to a recursive "this.deleteBlock()" ... or not
     */
    if (childBlockIds.length) {
      childBlockIds.forEach(childBlockId => {
        this.batch[blockId].delete(this.dataService.multiblockRef.doc(childBlockId).ref)
      })
    }
    const block = this.stateService.state[blockId].form.value
    if (block.columns?.length) {
      for (const column of block.columns) {
        if (column.fieldRefs?.length) {
          for (const fieldRef of column.fieldRefs) {
            if (block.fields[fieldRef].type === "columnBlock") {
              this.batch[blockId].delete(this.dataService.multiblockRef.doc(block.fields[fieldRef].blockRef).ref)
            }
          }
        }
      }
    }

    return this.batch[blockId].commit()
  }

  /**
   * Write newBlock to docs with newBlockId
   */
  saveNewBlock(oldBlock: Multiblock, newBlockId?: string): Promise<void> {
    newBlockId = newBlockId || this.createId
    const newBlock = JSON.parse(JSON.stringify(oldBlock))
    // delete newBlock.id

    /**
     * TODO: identify any nested blockRefs and recursive copy them as well
     *
     * replace fieldRefs with new ids, keep columns and fields in sync
     */
    if (newBlock.columns?.length) {
      const columns = []
      const fields = {}
      for (const column in newBlock.columns) {
        columns[column] = columns[column] || {}
        for (const field in newBlock.columns[column]) {
          if (field === "fieldRefs") {
            for (const fieldRef of newBlock.columns[column].fieldRefs) {
              columns[column].fieldRefs = columns[column].fieldRefs || []
              const newFieldRef = this.afs.createId()
              columns[column].fieldRefs.push(newFieldRef)

              /**
               * fields can have references to other blocks
               * we need to recreate the references and copy these nested blocks
               */
              if (newBlock.fields[fieldRef].type === "columnBlock") {
                const nestedNewBlockId = this.createId
                this.saveNewBlock(this.stateService.state[fieldRef].form.value, nestedNewBlockId)
                  .then(() => {
                    newBlock.fields[fieldRef].blockRef = nestedNewBlockId
                  })
              }

              fields[newFieldRef] = newBlock.fields[fieldRef]
            }
          } else {
            columns[column][field] = newBlock.columns[column][field]
          }
        }
      }
      if (columns.length) {
        newBlock.columns = columns
        newBlock.fields = fields
      }
    }

    newBlock.status = {
      created: Timestamp.now(),
      deleting: false,
      personalize: {},
      personalized: false,
      preSaved: true,
      protected: false,
      revised: Timestamp.now(),
      session: this.dataService.session,
      unpublished: false,
    }
    this.stateService.newState(newBlockId)
    this.batch[newBlockId] = this.afs.firestore.batch()
    this.batch[newBlockId]
      .set(this.dataService.multiblockRef
        .doc(newBlockId).ref, newBlock)
    return this.batch[newBlockId].commit()
  }

  setChildrenDraggable(block: PageBlock, value: boolean): void {
    if (!value) {
      this.stateService.resetStateKey("draggable")
      this.stateService.resetStateKey("draggedOverBlockId")
    }
    block.childBlockIds.forEach(childBlockId => {
      this.stateService.updateState(childBlockId, { draggable: value })
    })
  }

  confirmDelete(block: Multiblock, blockId: string): void {
    /**
     * mark form for block deletion
     */
    this.stateService.state[blockId].form.patchValue({ status: { deleting: true } })
  }

  undelete(block: Multiblock, blockId: string): void {
    this.stateService.state[blockId].form.patchValue({ status: { deleting: false } })
  }

  loadToolbar(toolbarContainerRef: ViewContainerRef, block: Multiblock, showEditModal: (event?: Event, index?: number) => void): void {
    import("../admin/toolbar/toolbar.component").then(component => {
      toolbarContainerRef.clear()
      const componentRef = toolbarContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
      componentRef.instance["block"] = block
      componentRef.instance["showEditModal"] = showEditModal
    })
  }

  newForm = (item: any, newItem?: any): AbstractControl => {
    newItem = newItem || item
    item = item || newItem
    // if (item === undefined || item === null) {
    //   return new FormControl(null)
    // }
    if (this.isControl(item)) {
      return new FormControl(item)
    }
    if (this.isGroup(item)) {
      const group = {}
      for (const key in item) {
        group[key] = this.newForm(item[key], newItem[key])
      }
      return new FormGroup(group)
    }
    if (this.isArray(item)) {
      const array = []
      for (const key in item) {
        array[key] = this.newForm(item[key], newItem[key])
      }
      return new FormArray(array)
    }
  }

  isControl(data: any): boolean {
    return typeof data === "boolean" || typeof data === "number" || typeof data === "string" || data === null
  }

  isArray(data: any): boolean {
    return Array.isArray(data)
  }

  isGroup(data: Multiblock): boolean {
    return !this.isControl(data) && !this.isArray(data)
  }

  getFormType(data: never): string {
    if (typeof data === "string") {
      return "control"
    }
    if (typeof data === "number") {
      return "control"
    }
    if (typeof data === "boolean") {
      return "control"
    }
    if (Array.isArray(data)) {
      return "array"
    }
    return "group"
  }

  showEditModal = (block: Multiblock, component: TemplateRef<never>, index?: number): void => {
    if (this.state.selectedField) {
      this.stateService.updateState(block.id, { selectedField: undefined })
      return
    }
    this.stateService.updateState(block.id, { modalActive: true })
    if (this.adminMode) {
      this.modalRef = this.modalService.create({
        nzContent: component,
        // nzBodyStyle: { "padding": "20px" },
        nzFooter: null,
        nzWidth: "600px",
        // nzMaskClosable: false,
        nzStyle: { position: "absolute", top: 0, right: 0 },
        nzComponentParams: {
          blockId: block.id,
          index: index,
        }
      })
      this.modalRef.afterClose.subscribe(() => {
        this.stateService.updateState(block.id, { modalActive: false })
      })
    }
  }

}
