import { Injectable } from "@angular/core"
import { debounceTime, Subscription } from "rxjs"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BlockStateBase, StateService } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormService } from "./form.service"
import { Block } from "@aaa-web/app/modules/multiblock/containers/column/service"

/**
 * All blocks are stored in Firestore as documents.
 * The dataService subscribes to all blocks for the page and overwrites the status.session key with the current "session" value.
 * Each block-component listens to its block, keeping the page synchronized with Firestore.
 * All blacks have an associated "form" which is synchronized with a "revision" (a copy) of that form.
 * The "form" is generated client-side and stored as an Angular FormGroup.
 * The "revision" is stored in Firebase as a document in a sub-collection of its block document.
 * The "session" is a unique key generated when the browser session begins (e.g. each tab is a new session).
 *
 * When an admin loads a page of blocks we do the following for each block:
 * 1. if its revision exists, generate the form from the revision, assign our session to the form.
 * 2. if its revision does not exist, generate the form from the block, then write to the revision.
 * 3. continuously monitor a diff between the form and the block, showing indicators (save and cancel) when not in sync.
 * 4. when the "form-subscription" emits changes (write to revision in Firestore only if our own changes):
 *    if the change is from our session, write to the revision.
 *    if the change is not from our session, assign our session to the form quietly (do not trigger the "form-subscription")
 *    TODO: detect if there are no changes, even if the session is different (is causing infinite loop when 2 sessions are editing the same block)
 * 5. when the "revision-subscription" emits changes (do not write to revision in Firestore):
 *    if the change is from our session, do nothing.
 *    if the change is not from our session, replace our form with the revision using our current session value.
 *
 */

@Injectable({
  providedIn: "root"
})
export class RevisionService {
  revisionState: {
    [key: string]: {
      editsSub?: Subscription
      formSub?: Subscription
      initialized?: boolean
      newForm?: (block: Multiblock) => BlockStateBase
    }
  } = {}

  constructor(
    private dataService: DataService,
    private formService: FormService,
    private stateService: StateService,
  ) {
  }

  setupListeners(blockId: string): void {
    const block = this.dataService.blocks[blockId] as Block
    if (block) {
      if (block.columns) {
        for (const fieldRef in block.fields) {
          if (block.fields[fieldRef].type === "columnBlock") {
            this.setupListeners(block.fields[fieldRef].blockRef)
          }
        }
      }
      this.revisionState[blockId] = this.revisionState[blockId] || {}
      this.revisionState[blockId].editsSub = this.dataService.getBlockEditingDocument(blockId)
        .subscribe(document => {
          const blockRevision = document.payload.data() as Multiblock
          /**
           * initialize editing revision document
           */
          // console.log(block)
          if (!blockRevision) {
            // console.log(block)
            const blockCopy: Multiblock = JSON.parse(JSON.stringify(block))
            blockCopy.status.preSaved = false
            blockCopy.status.session = this.stateService.session
            this.dataService.writeBlockEditingDocument(blockCopy, blockId)
            return
          }
          /**
           * delete block revision if it is out of date
           */
          // if (!blockRevision.status.personalize) {
          //   this.dataService.deleteBlockEditingDocument(blockId)
          //   return
          // }
          // if (blockRevision.blockType === "banner" || blockRevision.blockType === "oneColText" || blockRevision.blockType === "title") {
          //   if (blockRevision["options"].marginTop !== undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          // }
          // if (blockRevision.blockType === "banner" || blockRevision.blockType === "bannerLarge" || blockRevision.blockType === "feature") {
          //   if (blockRevision["options"].columns || !blockRevision["options"].columnWidths) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          // }
          // if (blockRevision.blockType === "banner" || blockRevision.blockType === "bannerLarge" || blockRevision.blockType === "feature" || blockRevision.blockType === "logiform") {
          //   for (const javascript of blockRevision["fields"].javascripts) {
          //     if (javascript.position || javascript.width) {
          //       this.dataService.deleteBlockEditingDocument(blockId)
          //       return
          //     }
          //   }
          // }
          // if (blockRevision["fields"]?.images?.length) {
          //   for (const index in blockRevision["fields"].images) {
          //     if (blockRevision["fields"].images[index].targetOption === undefined) {
          //       this.dataService.deleteBlockEditingDocument(blockId)
          //       return
          //     }
          //     if (blockRevision["fields"].images[index].targetPath === undefined) {
          //       this.dataService.deleteBlockEditingDocument(blockId)
          //       return
          //     }
          //   }
          // }
          // if (blockRevision.blockType === "container" && blockRevision["containerType"] === "page") {
          //   if (blockRevision["pageTitle"] === undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          //   if (blockRevision["pageType"] === undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          //   if (blockRevision["breadcrumbTitle"] === undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          //
          //   if (blockRevision["pageBreadcrumb"] !== undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          //   if (blockRevision["breadcrumbPaths"] !== undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          // }
          // if (blockRevision.blockType === "feature") {
          //   if (blockRevision["fields"].images[0] && blockRevision["fields"].images[0].altText === undefined) {
          //     this.dataService.deleteBlockEditingDocument(blockId)
          //     return
          //   }
          // }

          if (blockRevision["pathnames"] !== undefined) {
            this.dataService.deleteBlockEditingDocument(blockId)
            return
          }

          /**
           * END delete block revision if it is out of date
           */


          blockRevision["status"]["session"] = blockRevision["status"]["session"] || this.stateService.session
          /**
           * load form from the editing revision data
           */
          if (!this.revisionState[blockId].initialized) {
            this.loadForm(block, blockRevision)
            this.revisionState[blockId].initialized = true
          }
          /**
           * reload the form if incoming change is not from this session
           */
          if (this.revisionState[blockId].initialized && blockRevision.status.session !== this.stateService.session) {
            this.reloadForm(block, blockRevision)
          }
        })
    }
  }

  destroyListeners(blockId: string): void {
    this.revisionState[blockId]?.editsSub?.unsubscribe()
    this.revisionState[blockId]?.formSub?.unsubscribe()
    delete this.revisionState[blockId]
    this.stateService.resetState(blockId)
  }

  reloadForm(block: Multiblock, blockRevision: Multiblock): void {
    this.revisionState[block.id].formSub?.unsubscribe()
    delete this.revisionState[block.id].formSub
    this.stateService.resetState(block.id, "form")
    this.loadForm(block, blockRevision)
  }

  loadForm(block: Multiblock, blockRevision: Multiblock): void {
    blockRevision["status"]["session"] = this.stateService.session
    this.stateService.updateState(block.id, { form: this.formService.newForm(blockRevision) })
    this.stateService.compareBlockState(block.id)
    this.formListener(block)
  }

  formListener(block: Multiblock): void {
    this.revisionState[block.id] = this.revisionState[block.id] || {}
    this.revisionState[block.id].formSub = this.stateService.state[block.id].form.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(changes => {
        /**
         * write to editing revision if changes are our own changes
         */
        if (changes.status.session === this.stateService.session) {
          this.dataService.writeBlockEditingDocument(changes, block.id)
        }
        /**
         * quietly replace session id with this.session if changes are from another session
         */
        if (changes.status.session !== this.stateService.session) {
          this.stateService.state[block.id].form.patchValue({ status: { session: this.stateService.session } }, { emitEvent: false })
        }
        /**
         * refresh editing state (save and cancel icons)
         * TODO: find a way to use current "block", the "block" parameter from formListener() is stale.
         */
        this.stateService.compareBlockState(block.id)
      })
  }

}
