import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore"
import firebase from "firebase/app"
import firestore = firebase.firestore
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

export interface SimpleLoggerGroupDoc {
  messages: SimpleLoggerMessages
  writeEnabled: boolean
  id?: string
  timestamp?: string
}

export interface SimpleLoggerIndividualDoc {
  records: Record[]
  timestamp?: firestore.Timestamp
  index?: number
  classes?: {
    [key: string]: boolean
  }
  flags?: {
    [key: string]: boolean
  } | never
  action?: string
  details?: never
}

export interface SimpleLoggerMessages {
  [id: string]: Message
}

export interface Message {
  id: string
  index: number
  records: Record[]
  labels?: string[]
  count?: number
  length?: number
}

export interface Record {
  label: string
  function: string
  description: string
  class: string
  data: {
    [key: string]: string | { [key: string]: never }
    result?: {
      [key: string]: never
    }
    transactionDetails?: {
      [key: string]: never
    }
    paymentResult?: {
      [key: string]: never
    }
    error?: {
      [key: string]: never
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class SimpleLoggerService {
  simpleLoggerRef: AngularFirestoreDocument
  memberNumberCollectionRef: AngularFirestoreCollection<SimpleLoggerGroupDoc>
  v3BatchCollectionRef: AngularFirestoreCollection<SimpleLoggerGroupDoc>
  ememberBatchCollectionRef: AngularFirestoreCollection<SimpleLoggerGroupDoc>
  ememberHealthCollectionRef: AngularFirestoreCollection<SimpleLoggerIndividualDoc>
  window: MetaWindow

  constructor(
    private afs: AngularFirestore,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
    this.simpleLoggerRef = this.afs
      .collection("wss-aaa-web")
      .doc(this.window["metaData"].clubId)
      .collection("data")
      .doc("simple-logger")
    this.memberNumberCollectionRef = this.simpleLoggerRef.collection("member-number")
    this.v3BatchCollectionRef = this.simpleLoggerRef.collection("v3-batch")
    this.ememberBatchCollectionRef = this.simpleLoggerRef.collection("emember-batch")
    this.ememberHealthCollectionRef = this.simpleLoggerRef.collection("emember-health")
  }

  processMessagesDocs(
    messagesDocs: SimpleLoggerGroupDoc[],
    previousMessages: SimpleLoggerMessages,
    messagesArray: Message[]
  ): { messagesArray: Message[]; messages: SimpleLoggerMessages } {
    const messages: SimpleLoggerMessages = {}

    messagesDocs.forEach((messagesDoc) => {
      if (messagesDoc.messages) {
        Object.keys(messagesDoc.messages).forEach(messageId => {
          /**
           * It is possible to have the same messageId across multiple docs.
           * This happens when the message is too large for a single doc and gets split across several docs.
           * We grab the existing message and its records if they exist, merge the new records, and update its count.
           * Otherwise initialize a new message object and its records array.
           */
          messages[messageId] = messages[messageId] || {} as Message
          messages[messageId].records = messages[messageId].records || []
          Object.keys(messagesDoc.messages[messageId]).forEach(record => {
            messages[messageId].records[record] = messagesDoc.messages[messageId][record]
          })
          /**
           * prepare id and count for template, after the message objects are converted to an array
           */
          messages[messageId].id = messageId
          messages[messageId].count = messages[messageId].length
        })
      }
    })

    /**
     * We compare the incoming messages with the previousMessages, and merge only the new, remove only the removed.
     * This keeps the UI happy :)
     *
     * Add and remove messages from previousMessages.
     */

    /**
     * get new messageIds
     */
    const newMessageIds = this.difference(Object.keys(messages), Object.keys(previousMessages))
    /**
     * get removed messageIds
     */
    const removedMessageIds = this.difference(Object.keys(previousMessages), Object.keys(messages))

    /**
     * add new messages in sort order
     */
    const newMessageIdsSorted = newMessageIds.sort((a: never, b: never) => a - b)
    newMessageIdsSorted.forEach(messageId => {
      messages[messageId].index = messagesArray.length + 1
      messagesArray.unshift(messages[messageId])
    })
    /**
     * remove removed messages
     */
    removedMessageIds.forEach(messageId => {
      const index = messagesArray.findIndex(message => message["id"] === messageId)
      messagesArray.splice(index, 1)
      /**
       * rebuild the descending index values
       */
      messagesArray = messagesArray.map((message, messageArrayIndex) => {
        message.index = messagesArray.length - messageArrayIndex
        return message
      })
    })

    return { messages: messages, messagesArray: messagesArray }
  }

  /**
   * Three comparison functions: intersection(), difference(), and symmetricDifference()
   * Inspired by the "Plain Javascript" post in this stackoverflow
   * https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
   * a1 and a2 are arrays
   *
   * records that are in both a1 and a2
   */
  intersection(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2)
    return a1.filter(function (x) {
      return a2Set.has(x)
    })
  }

  /**
   * records that are in a1 but not in a2
   */
  difference(a1: string[], a2: string[]): string[] {
    const a2Set = new Set(a2)
    return a1.filter(function (x) {
      return !a2Set.has(x)
    })
  }

  /**
   * records that are not in both a1 and a2
   * aka
   * records that are in a1 but not in a2 AND ALSO records that are in a2 but not in a1
   */
  symmetricDifference(a1: string[], a2: string[]): string[] {
    return this.difference(a1, a2).concat(this.difference(a2, a1))
  }

}
