import { Injectable } from "@angular/core"
import { FormGroup, FormControl, FormArray } from "@angular/forms"
import anchorme from "anchorme"
import sanitizeHtml from "sanitize-html"
import {
  Agent, AppMode, CFMObj, FormValues, Meeting, Overrides, Params, Region, Topic
} from "@aaa/interface/agent-scheduler"

export interface FormValue {
  topics: Topic[]
  regions: Region[]
  meetings: Meeting[]
  agents: Agent[]
  agentEmail: string
  firstName: string
  lastName: string
  email: string
  phone: string
  departure: []
  comment: string
  date: string
  timeSlot: string
  agent: Agent
  agentTimezone: string
}

@Injectable({
  providedIn: "root"
})
export class FormService {
  form: {
    [key: string]: FormGroup
  } = {}
  formValues: {
    [key: string]: FormValues
  } = {}

  constructor() {
  }

  formSetup(formId: string, params: Params, appMode: AppMode, overrides: Overrides, cfmObj: CFMObj): void {
    // console.log(overrides.agentEmail)
    if (!params.agents.find(agent => agent.email.toLowerCase() === overrides.agentEmail)) {
      overrides.agentEmail = ""
    }
    // console.log(overrides)

    // console.log(formId)
    this.formValues[formId] = {
      appMode: appMode,
      overrides: overrides,
      meta: params.meta,
      metaUser: params.meta?.user,
      agents: params.agents,
      topics: params.topics,
      regions: params.regions,
      meetings: params.meetings,
      offices: params.offices,
      types: params.types,
      shareWith: params.shareWith,
      selectedTopicIds: cfmObj.selectedTopics.map(topicsText => params.topics.find(topic => topic.text === topicsText)?.id) || [],
      selectedRegionId: params.regions.find(region => region.text === cfmObj.region)?.id || "",
      selectedMeetingId: params.meetings.find(meeting => meeting.text === cfmObj.meeting)?.id || "",
    }
    // console.log(this.formValues[formId])

    const formValue: FormValue = {
      topics: params.topics,
      regions: params.regions,
      meetings: params.meetings,
      agents: params.agents,
      agentEmail: overrides.agentEmail,
      firstName: params.meta?.user.firstName,
      lastName: params.meta?.user.lastName,
      email: params.meta?.user.lastName,
      phone: params.meta?.user.phone,
      departure: [],
      comment: "",
      date: null,
      timeSlot: cfmObj.timeSlot,
      agent: params.agents.find(agent => agent.email.toLowerCase() === overrides.agentEmail),
      agentTimezone: ""
    }

    // console.log(cfmObj)
    this.buildForm(formId, formValue)
  }

  buildForm(formId: string, formValue: FormValue): void {
    // console.log(formValue)
    this.form[formId] = new FormGroup({
      topics: new FormArray(formValue.topics.map(() => new FormControl(false))),
      regions: new FormArray(formValue.regions.map(region => new FormControl(!!region.default))),
      meetings: new FormArray(formValue.meetings.map(meeting => new FormControl(!!meeting.default))),
      firstName: new FormControl(formValue.firstName),
      lastName: new FormControl(formValue.lastName),
      email: new FormControl(formValue.email),
      phone: new FormControl(formValue.phone),
      departure: new FormControl(formValue.departure),
      comment: new FormControl(formValue.comment, [
        this.validateComment
      ]),
      date: new FormControl(formValue.date, []),
      timeSlot: new FormControl(formValue.timeSlot || {}, []),
      agent: new FormControl(formValue.agent, []),
      agentTimezone: new FormControl(formValue.agentTimezone, [])
    })

  }

  private validateComment(c: FormControl): null | Record<string, unknown> {
    let dirty: ("links" | "email addresses" | "HTML tags")[] = []
    sanitizeHtml(c.value, {
      exclusiveFilter: function (frame) {
        if (frame.tag) {
          dirty.push("HTML tags")
        }
        return false
      }
    })
    anchorme({
      input: c.value,
      options: {
        specialTransform: [
          {
            test: /[^]*/, //everything
            transform: s => {
              if (anchorme.validate.email(s)) {
                dirty.push("email addresses")
              } else {
                dirty.push("links")
              }
              return s
            }
          }
        ]
      }
    })

    //build english representation of 'dirty' array
    dirty = Array.from(new Set(dirty))
    const err: boolean = dirty.length > 0
    let message: string = ""
    if (dirty.length === 1) {
      message += dirty[0]
    } else if (dirty.length > 1) {
      const anded: string = dirty.pop() + " and " + dirty.pop()
      if (dirty.length > 0) {
        dirty.push(anded as any)
        message += dirty.join(", ")
      } else {
        message += anded
      }
    }
    message += " are not allowed."

    return !err ? null : {
      errorMessage: message
    }
  }

  validateIsEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return regex.test(email)
  }

  validateIsPhone(phone: string): boolean {
    const regex = /([().+ -]*[0-9]){10,11}[().+ -]*?/
    return regex.test(phone)
  }

  validateIsTextString(string: string): boolean {
    const regex = /^\D+$/g
    return regex.test(string)
  }
}
