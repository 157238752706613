<div style="display: flex; gap: 10px; flex-direction: column">
  <div style="display: flex; gap: 10px">
    <button
      nz-button
      [nzType]="'' || false ? 'default' : 'primary'"
      (click)="getRecords(); $event.preventDefault()"
    >
      run export/import
    </button>
    <button
      nz-button
      [nzType]="showRecords ? 'default' : 'primary'"
      (click)="showRecords = !showRecords; $event.preventDefault()"
    >
      toggle records
    </button>
    <button
      nz-button
      [nzType]="'primary'"
      (click)="deleteDocs(); $event.preventDefault()"
    >
      delete {{ messagesDocs?.length ? messagesDocs.length : 0 }} docs
    </button>
  </div>
  <div style="max-height: calc(100vh - 200px); overflow: auto">
    <div *ngFor="let message of messageCounts">
      <div>
        {{ message.id | date : 'medium' }}:
        {{ message.count }}
      </div>
      <div *ngIf="message.count && message.count < 1000 && showRecords" class="padding-left-20">
        user id :: member number
        {{ messages[message.id].length }}
        <div *ngFor="let record of messages[message.id] | keyvalue">
          {{ record.key }}
          <a
            [href]="'/account/' + record.key + '/edit?destination=admin/aaa/manage/accounts&memberNumber=' + record.value"
            target="_blank"
          >
            edit
          </a>
          ::
          {{ record.value }}
          <a
            [href]="'/admin/config/system/aaa/membership-details-lookup?memberNumber=' + record.value"
            target="_blank"
          >
            lookup
          </a>
        </div>
      </div>
      <!--  <div class="button-2" (click)="deleteMessages(message.id)">-->
      <!--    delete-->
      <!--  </div>-->
    </div>
  </div>
</div>
