import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { StateMachineService } from "../../services/state-machine.service"
import { FormService } from "../../services/form.service"
import { FormControl } from "@angular/forms"
import { Subscription } from "rxjs"
import { Agent, FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "ava-appointment-card",
  templateUrl: "./appointment-card.component.html"
})
export class AppointmentCardComponent implements OnInit, OnDestroy {
  @Input() formId: string
  formValues: FormValues
  agent: Agent
  selectedDateTime: Date
  selectedDateTimeEnd: Date
  timeSlotSubscription: Subscription

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]

    if (this.timeSlot.value?.date && this.timeSlot.value.time) {
      this.setSelectedDateTime(this.timeSlot.value)
    }
    this.timeSlotSubscription = this.timeSlot.valueChanges.subscribe(timeSlot => {
      if (timeSlot) {
        this.setSelectedDateTime(timeSlot)
      } else {
        this.selectedDateTime = this.selectedDateTimeEnd = null
      }
    })

  }

  ngOnDestroy(): void {
    this.timeSlotSubscription?.unsubscribe()
  }

  get date(): FormControl {
    return this.formService.form[this.formId].get("date") as FormControl
  }

  get timeSlot(): FormControl {
    return this.formService.form[this.formId].get("timeSlot") as FormControl
  }

  topicText(topicId: string): string {
    return this.formValues.topics.find(topic => topic.id === topicId)?.text || ""
  }

  meetingText(): string {
    return this.formValues.meetings.find(meeting => meeting.id === this.formValues.selectedMeetingId)?.text || ""
  }

  regionText(): string {
    return this.formValues.regions.find(region => region.id === this.formValues.selectedRegionId)?.text || ""
  }

  private setSelectedDateTime(timeSlot): void {
    this.selectedDateTime = new Date(
      timeSlot.date.year,
      timeSlot.date.month - 1,
      timeSlot.date.day,
      timeSlot.time.hour,
      timeSlot.time.minute,
      0,
      0
    )
    this.selectedDateTimeEnd = new Date(
      timeSlot.date.year,
      timeSlot.date.month - 1,
      timeSlot.date.day,
      timeSlot.time.hour + 1,
      timeSlot.time.minute,
      0,
      0
    )

  }

}
