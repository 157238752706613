import { Component, Input } from "@angular/core"

@Component({
  selector: "ava-emember-batch-dialog",
  templateUrl: "./emember-batch-dialog.component.html",
  styleUrls: ["./emember-batch-dialog.component.scss"]
})
export class EmemberBatchDialogComponent {
  @Input() data: any
  @Input() index: number
}
