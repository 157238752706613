import { Injectable } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

export interface BoxModel {
  margin: {
    bottom: {
      px: number
    }
    left: {
      px: number
    }
    right: {
      px: number
    }
    top: {
      px: number
    }
  }
  padding: {
    bottom: {
      px: number
    }
    left: {
      px: number
    }
    right: {
      px: number
    }
    top: {
      px: number
    }
  }
}

export interface Type {
  px: number[]
}

export type Option = "bottom" | "left" | "right" | "top"
export type Types = Record<Option, Type>

export interface BoxModelOptions {
  margin: Types
  marginLabel: string
  padding: Types
  paddingLabel: string
}

@Injectable({
  providedIn: "root"
})
export class BoxModelService {

  get options(): BoxModelOptions {
    return {
      margin: {
        bottom: { px: [0, 16, 32] },
        left: { px: [] },
        right: { px: [] },
        top: { px: [0, 16, 32] },
      },
      marginLabel: "Margin",
      padding: {
        bottom: { px: [0, 24, 48] },
        left: { px: [] },
        right: { px: [] },
        top: { px: [0, 24, 48] },
      },
      paddingLabel: "Padding",
    }
  }

  get newItem(): BoxModel {
    return {
      margin: {
        bottom: { px: 0 },
        left: { px: 0 },
        right: { px: 0 },
        top: { px: 0 }
      },
      padding: {
        bottom: { px: 24 },
        left: { px: 0 },
        right: { px: 0 },
        top: { px: 24 }
      }
    }
  }

  newForm = (item: BoxModel): FormGroup => {
    return new FormGroup({
      margin: new FormGroup({
        bottom: new FormGroup({ px: new FormControl(item.margin.bottom.px) }),
        left: new FormGroup({ px: new FormControl(item.margin.left.px) }),
        right: new FormGroup({ px: new FormControl(item.margin.right.px) }),
        top: new FormGroup({ px: new FormControl(item.margin.top.px) }),
      }),
      padding: new FormGroup({
        bottom: new FormGroup({ px: new FormControl(item.padding.bottom.px) }),
        left: new FormGroup({ px: new FormControl(item.padding.left.px) }),
        right: new FormGroup({ px: new FormControl(item.padding.right.px) }),
        top: new FormGroup({ px: new FormControl(item.padding.top.px) }),
      }),
    })
  }

/*
          icon: {
            theme: "outline",
            type: "column-height",
*/

}
