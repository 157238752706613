import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { lastValueFrom, Subscription } from "rxjs"
import { environment } from "@aaa-web/environments/environment"
import { CybersourceDoc } from "./join-renew.interfaces"
import { Event, EventName, MembershipConnectSuiteEventPayload, Operation, OperationStartPayload, PaymentTesterEventPayload } from "../../../../../../libs/interface/joinRenew/src/index"
import { DomSanitizer } from "@angular/platform-browser"

@Component({
  selector: "ava-join-renew",
  templateUrl: "./join-renew.component.html"
})
export class JoinRenewComponent implements OnInit, OnDestroy {
  cybersourceDocSubscription: Subscription
  cybersourceDoc: CybersourceDoc

  public sessionData: any = { "waitingForSession": true }
  public emu = environment.emulator
  public sessionId: string = String((new Date()).getMilliseconds())
  public clubId: string = '567';

  testingDoc: unknown = 'td';
  activeTestingData: [] = [];

  constructor(
    // private joinRenewService: JoinRenewService,
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    // this.cybersourceDocSubscription = this.joinRenewService.cybersourceDoc$
    //   .subscribe(cybersourceDoc => {
    //     this.cybersourceDoc = cybersourceDoc as any
    //   })

    let activeTestSub;
    this.afs
      .collection("wss-aaa-web")
      .doc('global')
      .collection("apps")
      .doc("join-renew")
      .collection("testing")
      .doc('active-test') //activeTestSessionId
      .valueChanges()
      .subscribe(testing => {
        this.testingDoc = testing;

        const activeRunId = testing.activeRunId;
        if (!activeRunId) return;
        if (activeTestSub) activeTestSub.unsubscribe();
        activeTestSub = this.afs
          .collection("wss-aaa-web")
          .doc('global')
          .collection("apps")
          .doc("join-renew")
          .collection("testing")
          .doc('active-test')
          .collection('tests')
          .doc(activeRunId)
          .valueChanges()
          .subscribe(activeTest => {
            this.activeTestingData = activeTest?.tests;
          });
      });
  }

  ngOnDestroy(): void {
    // this.cybersourceDocSubscription.unsubscribe()
  }

  async startTest() {
    try {
      const reply = await lastValueFrom(this.http.request(
        "POST",
        // "https://us-central1-avagate-wss-4.cloudfunctions.net/joinRenew-testing",
        "http://localhost:5001/avagate-wss-4/us-central1/joinRenew-testing",
        {
          headers: new HttpHeaders({}),
          body: {
            startTest: true
          }
        }
      ));
      console.log(reply);
    } catch (e) {
      console.log(e)
    }
  }

  async cronTick() {
    try {

      const reply = await lastValueFrom(this.http.request(
        "POST",
        // "https://us-central1-avagate-wss-4.cloudfunctions.net/joinRenew-testing",
        "http://localhost:5001/avagate-wss-4/us-central1/joinRenew-testing",

        {
          headers: new HttpHeaders({}),
          body: {
            cronTick: true
          }
        }
      ));
      console.log(reply);
    } catch (e) {
      console.log(e)
    }
  }

  async startOperation(): Promise<void> {
    console.log("1 start operation")

    console.log(this.sessionId)
    try {
      this.afs
        .collection('wss-aaa-web')
        .doc(this.clubId)
        .collection('apps')
        .doc('join-renew')
        .collection('sessions')
        .doc(this.sessionId)
        .valueChanges()
        .subscribe(sesisonData => {
          this.sessionData = sesisonData;
        });

      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-eventCoordinatorReceiver",
        {
          headers: new HttpHeaders({}),
          body: {
            sessionId: this.sessionId,
            clubId: this.clubId,
            created: null, //TODO
            eventName: EventName.OPERATION_START,
            payload: {
              operation: Operation.JOIN
            } as OperationStartPayload
          } as Event
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }

  }

  async memberLookup() {
    console.log("x member lookup")

    try {
      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-eventCoordinatorReceiver",
        {
          headers: new HttpHeaders({}),
          body: {
            sessionId: this.sessionId,
            clubId: this.clubId,
            created: null, //TODO
            eventName: EventName.MEMBERSHIP_QUERY,
            payload: {
              responseKey: '429071123456789',
              method: null,//'memberLookup', //TODO
              memberNumber: '429071123456789'
            } as MembershipConnectSuiteEventPayload
          } as Event
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }
  }


  async testEvent() {
    console.log("test event");

    const memNumber = '4290711901817505';

    try {
      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-eventCoordinatorReceiver",
        {
          headers: new HttpHeaders({}),
          body: {
            sessionId: this.sessionId,
            clubId: this.clubId,
            created: null, //TODO
            eventName: EventName.MEMBERSHIP_QUERY,
            payload: {
              responseKey: memNumber,
              method: null, //'memberLookup', //TODO
              memberNumber: memNumber
            } as MembershipConnectSuiteEventPayload
          } as Event
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }
  }


  // async testDocRW() {
  //   const docRef = this.afs.firestore
  //     .collection('wss-aaa-web')
  //     .doc('102')
  //     .collection('apps')
  //     .doc('joinRenew')
  //     .collection('sessions')
  //     .doc(this.sessionId)

  //   try {
  //     // await docRef.set({ write: true, emu: "wss-4", deployed: false }, { merge: true });
  //     const doc = await docRef.get();
  //     console.log(doc.id);
  //     console.log(doc.data());
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

}


@Pipe({
  name: 'prettyjson',
  pure: true
})
export class PrettyJsonPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: any, args: any[]): any {


    if (args[2] && value.length > 0) {
      // console.log(value)
      value = value.map(e => {
        // console.log(e);
        e.steps = e.steps.map(s => {
          console.log(s);
          s = Object.keys(s)
            .sort((a, b) => {
              if (b == 'executed') return 1;
              if (b == 'ok') return 1;
              console.log(a)
              return 0;
            }).reduce(
              (obj, key) => {
                obj[key] = s[key];
                return obj;
              },
              {}
            );
          return s;
        })
        return e;
      })
      // for (let e of value) {
      //   console.log(e)
      // }
    }

    try {
      /**
       * check and try to parse value if it's not an object
       * if it fails to parse which means it is an invalid JSON
       */
      const res = this.applyColors(
        typeof value === 'object' ? value : JSON.parse(value),
        args[0],
        args[1]
      );
      return this.sanitized.bypassSecurityTrustHtml(res);
    } catch (e) {
      return this.applyColors({ error: 'Invalid JSON' }, args[0], args[1]);
    }
  }

  applyColors(obj: any, showNumebrLine: boolean = false, padding: number = 2) {
    // line number start from 1
    let line = 1;


    if (typeof obj != 'string') {
      obj = JSON.stringify(obj, undefined, 3);
    }

    /**
     * Converts special charaters like &, <, > to equivalent HTML code of it
     */
    obj = obj.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    /* taken from https://stackoverflow.com/a/7220510 */

    /**
     * wraps every datatype, key for e.g
     * numbers from json object to something like
     * <span class="number" > 234 </span>
     * this is why needed custom themeClass which we created in _global.css
     * @return final bunch of span tags after all conversion
     */
    obj = obj.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      (match: any) => {
        // class to be applied inside pre tag
        let color = 'lightblue';//number
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            color = 'white'; // key
            if (match === '"ok":') {
              color = 'yellow'; // ok key
            }
          } else {
            color = 'green'; //string
          }
        } else if (/true|false/.test(match)) {
          color = 'orange'; //bool
        } else if (/null/.test(match)) {
          color = 'grey'; //null
        }
        return '<span style="color:' + color + ';">' + match + '</span>';
      }
    );

    /**
     * Regex for the start of the line, insert a number-line themeClass tag before each line
     */
    return showNumebrLine
      ? obj.replace(
        /^/gm,
        () =>
          `<span style="color: silver;" class="pl-3 select-none" >${String(line++).padEnd(padding)}</span>`
      )
      : obj;
  }
}
