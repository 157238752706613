import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { FormArrayOptions, FormService } from "@aaa-web/app/modules/multiblock/services/form.service"
import { FieldType } from "@aaa-web/app/modules/multiblock/services/field.service"
import { Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"

export interface Video {
  blockRef: string
  type: FieldType
  value: string
}

export interface VideoOption {
}

export interface VideoOptions {
  options: VideoOption[]
  formArray?: FormArrayOptions
  newForm: (item: Video) => AbstractControl
  newItem: Video
  label: {
    placeholder: string
    plural: string
    singular: string
  }
}

export interface VideoStyles {
  height: {
    px: number
  }
  width: {
    px: number
  }
}

@Injectable({
  providedIn: "root"
})
export class VideoService {

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Video {
    return {
      blockRef: null,
      type: "video",
      value: ""
    }
  }

  newForm = (item: Video): AbstractControl => {
    return this.formService.newForm(item as unknown as Multiblock, this.newItem as unknown as Multiblock)
  }

  get options(): VideoOptions {
    const options: VideoOptions = {
      formArray: {
        max: 1,
        min: 0,
      },
      label: {
        placeholder: "",
        plural: "Videos",
        singular: "Video"
      },
      newForm: this.newForm,
      newItem: this.newItem,
      options: []
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({})
    }
    return options

  }

}
