import { Component } from "@angular/core"

@Component({
  selector: "ava-hidden-title-custom-element",
  template: `
    <ava-hidden-title></ava-hidden-title>
  `
})
export class HiddenTitleCustomElementComponent {
}
