import { Injectable } from "@angular/core"
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"
import {
  QuillEditor, QuillEditorOptions, QuillService
} from "@aaa-web/app/modules/multiblock/fields/quill/service"

export interface Membership {
  flags: QuillEditor[]
  subtitles: QuillEditor[]
  titles: QuillEditor[]
}

export interface MembershipOption {
  flags: QuillEditorOptions
  subtitles: QuillEditorOptions
  titles: QuillEditorOptions
}

export interface MembershipOptions {
  newFormFunction: (item: Membership) => FormGroup
  newItemFunction: () => Membership
  options: MembershipOption[]
  blockOptions?: Record<string, unknown> | unknown
  combinedForm: boolean
  formArray: FormArrayOptions
  label: {
    plural: string,
    singular: string
  }
  placeholder: string
}

export interface MembershipStyles {
}

@Injectable({
  providedIn: "root"
})
export class MembershipService {
  counter: number = 1
  price: number[] = [ 0, 1, 2 ]

  constructor(
    private quillService: QuillService,
  ) {
    this.initializePrices()
  }

  get newText(): QuillEditor {
    return this.quillService.newItem
  }

  newTextForm = (item: QuillEditor): AbstractControl => {
    return this.quillService.newForm(item)
  }

  newField = (): Membership => {
    return {
      flags: [],
      subtitles: [this.quillService.newItem],
      titles: [this.quillService.newItem],
    }
  }

  newForm(field: Membership): FormGroup {
    return new FormGroup({
      flags: new FormArray(field.flags.map(item => {
        return new FormGroup({
          value: new FormControl(item.value),
        })
      })),
      subtitles: new FormArray(field.subtitles.map(item => {
        return new FormGroup({
          value: new FormControl(item.value),
        })
      })),
      titles: new FormArray(field.titles.map(item => {
        return new FormGroup({
          value: new FormControl(item.value),
        })
      })),
    })
  }

  /**
   * join counter methods
   */

  decrementCounter(): void{
    this.counter = this.counter - 1
    this.calculatePrices()
  }

  incrementCounter(): void {
    this.counter = this.counter + 1
    this.calculatePrices()
  }

  ngModelChangeCounter(event: number): void {
    if (event > 9) {
      this.counter = 9
    }
    if (!event) {
      this.counter = 1
    }
    this.calculatePrices()
  }

  /**
   * price calculator methods
   */

  initializePrices(): void {
    this.price = [
      128,
      86,
      37.5,
    ]
  }

  calculatePrices(): void {
    this.price = [
      128 - 100 + this.counter * 100,
      86 - 70 + this.counter * 70,
      37.5 - 40 + this.counter * 40,
    ]
  }

}
