import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/auth"
import { BehaviorSubject, Observable } from "rxjs"
import firebase from "firebase/app"
import "firebase/auth"
import { Role } from "@aaa/interface/user"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { environment } from "@aaa-web/environments/environment"

@Injectable({
  providedIn: "root"
})
export class JwtAuthService {
  private rolesSubject: BehaviorSubject<Role[]>
  roles$: Observable<Role[]>
  private memberNumberSubject: BehaviorSubject<string>
  memberNumber$: Observable<string>
  private userSubject: BehaviorSubject<boolean>
  user$: Observable<boolean>
  tokenVerified: boolean
  signedIn: boolean
  authToken: string
  signInWithTokenCount: number = 0

  constructor(
    private afAuth: AngularFireAuth,
    private domWindow: Window,
  ) {
    // console.log("JwtAuthService")
    this.rolesSubject = new BehaviorSubject([])
    this.roles$ = this.rolesSubject.asObservable()
    this.memberNumberSubject = new BehaviorSubject(null)
    this.memberNumber$ = this.memberNumberSubject.asObservable()
    this.userSubject = new BehaviorSubject(false)
    this.user$ = this.userSubject.asObservable()


    this.afAuth.idTokenResult
      .subscribe(idTokenResult => {
        this.tokenVerified = !!idTokenResult
        this.userSubject.next(!!idTokenResult)

        // this.signedIn = false
        this.authToken = ""
        // console.log(idTokenResult)
        if (idTokenResult) {
          this.signedIn = true
          this.authToken = idTokenResult.token
          if (idTokenResult.claims.roles) {
            this.rolesSubject.next(idTokenResult.claims.roles)
          } else {
            this.rolesSubject.next([])
          }
          if (idTokenResult.claims.memberNumber) {
            this.memberNumberSubject.next(idTokenResult.claims.memberNumber)
          } else {
            this.memberNumberSubject.next("")
          }
          if (!environment.emulator && !environment.ngServe && this.window.metaData.webEnv !== "appTest") {
            /**
             * Log out the current firebase user if their roles or member number
             * are different from the data in our metaData object.
             */
            const tokenNumber = idTokenResult.claims.memberNumber || ""
            const metaNumber = this.window.metaData.user?.memberNumber || ""

            const array1 = idTokenResult.claims.roles
            const array2 = this.window.metaData.user?.roles

            let arraysAreEqual = false
            if (array1 && array1.length === array2?.length) {
              const sortedArray1 = array1.sort()
              const sortedArray2 = array2.sort()
              if (sortedArray1.every((value, index) => value === sortedArray2[index])) {
                arraysAreEqual = true
              }
            }

            if ((tokenNumber !== metaNumber) || (!arraysAreEqual)) {
              if (tokenNumber !== metaNumber) {
                console.log(idTokenResult)
                console.log(tokenNumber)
                console.log(metaNumber)
              }
              if (!arraysAreEqual) {
                console.log(array1)
                console.log(array2)
              }
              this.signOutUser()
            }
          }
          /**
           * Do nothing if in emulator mode.
           * User already signed in with popup login and has an idTokenResult.
           */
        } else if (!this.signedIn) {
          // console.log(this.signedIn)
          this.rolesSubject.next([])
          this.memberNumberSubject.next("")
          this.signInUser()
        }
      })
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

  private signOutUser(): void {
    console.log("signOutUser()")
    firebase.auth()
      .signOut()
      .then(() => {
        this.signedIn = false
      })
      .catch(() => {
        // An error happened.
      })
  }

  private signInUser() {
    if (!this.signedIn) {

      if (environment.emulator || environment.ngServe || this.window.metaData.webEnv === "appTest") {
        const provider = new firebase.auth.GoogleAuthProvider()
        this.afAuth
          .signInWithPopup(provider)
          .then(() => {
            // console.log("signed in with popup")
            this.signedIn = true
          })
          .catch(() => {
            // An error happened.
          })
      }

      if (this.signInWithTokenCount > 10) {
        console.log("signInWithTokenCount > 10")
        console.log("throttling infinite logout/login loop, reload browser to try again")
        return
      }

      if (!environment.emulator && !environment.ngServe && this.window.metaData.webEnv !== "appTest" && this.window.metaData?.user?.token) {
        this.afAuth
          .signInWithCustomToken(this.window.metaData.user.token)
          .then((userCredential) => {
            console.log("signed in with token")
            this.signInWithTokenCount++
            this.signedIn = true
          })
          .catch(() => {
            // An error happened.
          })
      }

    }
  }

  /*
    async testPermissions() {
      console.log('test permissions')
      const collectionRef = this.afs.firestore.collection('test')
      const docRef = collectionRef.doc('1')
      const dataDoc = await docRef.get()
      if (dataDoc.exists) {
        console.log('data: ' + JSON.stringify(dataDoc.data()))
      }
    }
  */

}
