<style>
  .selected {
    box-shadow: 0 3px 1px -2px #00529b, 0 2px 2px 0 #00529b, 0 1px 5px 0 #00529b;
  }
</style>
<div style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400">
  <!--  What product would you like a new quote on?-->
  What would you like to talk about?
</div>

<div style="display: flex; flex-wrap: wrap; justify-content: center">
  <nz-card
    *ngFor="let topic of topics.controls; let index = index"
    nzHoverable tabindex="0" role="button"
    [ngClass]="{selected: topic.value}"
    style="width: 140px; height: 120px; color: #036ecd; margin: 10px; display: grid; align-content: center; justify-items: center"
    [nzBodyStyle]="{'display': 'grid', 'justify-items': 'center', 'padding': '0', 'max-width': '120px'}"
    [attr.aria-selected]="topic.value"
    (keydown.space)="toggleTopic(index)"
    (keydown.enter)="toggleTopic(index)"
    (click)="toggleTopic(index)"
  >
    <div
      class="icon"
      [class]="formValues.topics[index].icon"
      style="color: #666666; font-size: 26px"
    ></div>
    <div style="text-align: center; font-size: 20px; font-weight: 400">
      {{ formValues.topics[index].text }}
    </div>
  </nz-card>
</div>

<div style="display: flex; justify-content: center; margin: 10px">
  <button
    nz-button [nzType]="'primary'"
    (click)="sms.sendEvent('NEXT'); $event.preventDefault()"
    [disabled]="noTopicSelected"
  >
    Next
  </button>
</div>
