import { Injectable } from "@angular/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { PublishedOptions, StatusService } from "@aaa-web/app/modules/multiblock/options/status/service"
import { BlockBase, BlockOption, BlockOptions } from "@aaa-web/app/modules/multiblock/services/block.service"

export interface Block extends BlockBase {
  fields: {
    officeId: string
  }
}

export interface Options {
  block: BlockOptions
  published: PublishedOptions
}

@Injectable({
  providedIn: "root"
})
export class OfficeAgentsService {
  blockType: BlockOption = "officeAgents"

  constructor(
    private dataService: DataService,
    private statusService: StatusService,
  ) {
  }

  get options(): Options {
    return {
      block: this.blockOptions,
      published: this.publishedOptions,
    }
  }

  get blockOptions(): BlockOptions {
    return {
      label: {
        plural: "Office Agents",
        singular: "Office Agents"
      },
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.blockType,
      fields: {
        officeId: "8364"
      },
      pathName: this.dataService.pathName,
      status: this.statusService.newItem
    }
  }

  get publishedOptions(): PublishedOptions {
    return {
      label: {
        singular: "Status"
      },
      options: ["unpublish"]
    }
  }
}
